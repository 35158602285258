import { useEffect, useState } from "react";
import CanvaLogo from "../../assets/canvaLogo";
import { GetCanvaAuthLink, KillCanvaIntegration } from "../../actions/mgmt";
import toast from "react-hot-toast";
import { GetUser } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import dayjs from "dayjs";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const ProfileIntegrations = () => {
  const [canvaActive, setCanvaActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      reloadData();
    }
    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    GetUser()
      .then((res) => {
        setData(res.data);
        setCanvaActive(res.data?.integrations?.canva?.active ?? false);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Error loading user data");
        navigate("/profile", { replace: true });
      });
  };

  const initiateCanvaOnboarding = () => {
    GetCanvaAuthLink()
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          toast.error("Error initiating Canva onboarding");
        }
      })
      .catch((err) => {
        toast.error("Error initiating Canva onboarding");
      });
  };

  const renderCanva = () => {
    if (!canvaActive ?? false) {
      return (
        <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
          <div className="flex items-center justify-start gap-1.5">
            <CanvaLogo className="w-auto h-7" />
          </div>
          <button
            onClick={() => initiateCanvaOnboarding()}
            className="bg-gradient-to-r from-[#02c4cd] to-[#7e25e8] hover:from-[#01737d] hover:to-[#4e1587] transition-all duration-200 text-white text-sm px-4 py-2 rounded-md font-bold"
          >
            Link your Canva account
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
          <div className="flex items-center justify-start gap-1.5">
            <CanvaLogo className="w-auto h-7" />
          </div>
          <div className="flex items-center justify-end gap-2">
            <p className="px-4 py-2 text-sm font-medium rounded-md cursor-default select-none text-white/70">
              Integrated
              {data.integrations?.canva?.integratedAt && dayjs(data.integrations?.canva?.integratedAt).isValid()
                ? ` since ${dayjs(data.integrations?.canva?.integratedAt).format("MMMM Do, YYYY")}`
                : ""}
            </p>
            <button
              onClick={() => removeCanvaIntegration()}
              className="ring-[#ce2136] ring-1 cursor-pointer text-[#ce2136] text-sm px-4 py-2 rounded-md font-bold hover:text-[#e54856] hover:border-[#771b26] hover:bg-black transition-all duration-200"
            >
              Remove
            </button>
          </div>
        </div>
      );
    }
  };

  const removeCanvaIntegration = () => {
    // Remove Canva integration
    Modal.confirm({
      title: "Are you sure you want to remove the Canva integration?",
      content: "You will not be able to edit or refresh any of the content you've previously added. This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      type: "error",
      centered: true,
      destroyOnClose: true,
      maskClosable: true,
      onOk() {
        submitCanvaRemove();
      },
    });
  };

  const submitCanvaRemove = () => {
    setLoading(true);
    KillCanvaIntegration()
      .then((res) => {
        toast.success("Canva removed");
        reloadData();
      })
      .catch((err) => {
        toast.error("We encountered an error while removing Canva");
        reloadData();
      });
  };

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-screen gap-6 p-6 overflow-y-auto text-white bg-gray-950">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <p className="animate-pulse">Loading...</p>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-full px-6 pb-5 border-b border-gray-600">
            <h1 className="text-2xl font-semibold">Your Integrations</h1>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 px-6">{renderCanva()}</div>
        </>
      )}
    </div>
  );
};

export default ProfileIntegrations;
