const FEVersion = () => {
  const data = {
    GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA ?? process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
    GIT_COMMIT_MESSAGE: process.env.VERCEL_GIT_COMMIT_MESSAGE ?? process.env.REACT_APP_VERCEL_GIT_COMMIT_MESSAGE,
    GIT_COMMIT_AUTHOR_NAME: process.env.VERCEL_GIT_COMMIT_AUTHOR_NAME ?? process.env.REACT_APP_VERCEL_GIT_COMMIT_AUTHOR_NAME,
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-screen gap-3 text-white">
      <div className="flex flex-col items-center justify-center w-full max-w-xl gap-2 font-light font-body">
        <div className="flex items-center justify-between w-full gap-2">
          <p>Commit Author:</p>
          <p>{data.GIT_COMMIT_AUTHOR_NAME}</p>
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <p>Commit SHA:</p>
          <p>{data.GIT_COMMIT_SHA}</p>
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <p>Commit Message:</p>
          <p>{data.GIT_COMMIT_MESSAGE}</p>
        </div>
      </div>
    </div>
  );
};

export default FEVersion;
