import { useEffect, useState } from "react";
import { GetUserMemberships, GetUserTickets } from "../../actions/auth";
import toast from "react-hot-toast";
import { GenerateQRURI, formatCurrency } from "../../components/utilities";
import dayjs from "dayjs";
import { Button, Modal, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import QRGenerator from "../../components/qrGenerator";

const ProfileMemberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
    GetUserMemberships()
      .then((res) => {
        setMemberships(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message || "Error obtaining memberships"
        );
        setTimeout(() => setLoading(false), 700);
      });
  };

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-screen gap-6 p-6 overflow-y-auto text-white bg-gray-950">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <p className="animate-pulse">Loading...</p>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-full px-6 pb-5 border-b border-gray-600">
            <h1 className="text-2xl font-semibold">Your Memberships</h1>
            <Button onClick={() => navigate("/memberships")}>
              {memberships.length === 0
                ? "Become a Member"
                : "Gift a Membership"}
            </Button>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 px-6">
            {memberships.map((membership) => (
              <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-8 py-4 border border-gray-600 rounded-md">
                <div className="flex flex-col items-start justify-center gap-2">
                  <div className="flex items-center justify-start gap-4">
                    <p className="text-xl font-medium">
                      {membership.membershipLabel}
                    </p>
                    <Tag
                      color={
                        membership.status === "active" ? "success" : "error"
                      }
                    >
                      {membership.status.toUpperCase()}
                    </Tag>
                  </div>
                  <p className="text-sm font-medium tracking-wider text-gray-300 uppercase">
                    Member Since:{" "}
                    {dayjs(membership.dateEstablished).format("MM/DD/YYYY")}
                  </p>
                  <p className="text-sm font-medium tracking-wider text-gray-300 uppercase">
                    Expiration:{" "}
                    {dayjs(membership.expirationDate).format("MM/DD/YYYY")}{" "}
                    {membership.autoRenew ? "(Renews Automatically)" : ""}
                  </p>
                  <Button className="text-xs">Manage Membership</Button>
                </div>
                <QRGenerator
                  link={membership.membershipId}
                  className="w-32 h-auto"
                  size={128}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileMemberships;
