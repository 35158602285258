import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Ticket } from "styled-icons/ionicons-outline";
import { Input, InputNumber, Switch, Table } from "antd";
import { CreateANewTicket, GetAllTickets } from "../../actions/mgmt";
import toast from "react-hot-toast";
import { formatCurrency } from "../../components/utilities";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const ManagementTickets = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [createTicket, setCreateTicket] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [available, setAvailable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      reloadData();
    }

    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    GetAllTickets()
      .then((res) => {
        setTickets(res.data.tickets);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const closeCreateTicket = () => {
    setCreateTicket(false);
    setLoading(true);
    setTitle("");
    setPrice(0);
    setDescription("");
    setAvailable(false);
    reloadData();
  };

  const submitTicket = () => {
    if (title === "") {
      toast.error("Please enter a title for the ticket");
    } else {
      let toSend = {
        title: title,
        price: price,
        description: description,
        available: available,
        event: false,
      };
      setLoading(true);
      CreateANewTicket(toSend)
        .then((res) => {
          toast.success("Ticket created successfully!");
          closeCreateTicket();
          navigate(`/management/tickets/${res.data.ticketId}`);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while creating the ticket, please try again.");
          closeCreateTicket();
          setTimeout(() => setLoading(false), 700);
        });
    }
  };

  const renderNewTicketModal = () => {
    return (
      <Transition.Root show={createTicket} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCreateTicket}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto mt-5 border border-white rounded-full">
                      <Ticket size={28} color="white" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        Create Tickets
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-300">Create a new ticket tier and set up initial pricing for it</p>
                      </div>
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div className="flex flex-col items-start justify-start w-full gap-2">
                          <label htmlFor="title" className="block pl-1 text-sm font-medium text-white/50">
                            Title <span className="text-red-500">*</span>
                          </label>
                          <Input placeholder="Ticket Title" onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="flex flex-col items-start justify-start w-full gap-2">
                          <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                            Price <span className="text-red-500">*</span>
                          </label>
                          <InputNumber placeholder="Ticket Price" className="w-full" step={0.01} min={0} onChange={(e) => setPrice(e)} prefix="$" />
                        </div>
                        <div className="flex flex-col items-start justify-start w-full col-span-2 mt-3">
                          <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
                            Description <span className="text-red-500">*</span>
                          </label>
                          <Input.TextArea placeholder="Ticket Description" onChange={(e) => setDescription(e.target.value)} />
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-between w-full mt-5">
                        <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
                          Make this ticket available for purchasing
                        </label>
                        <Switch className="bg-slate-800" onChange={setAvailable} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => closeCreateTicket()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => submitTicket()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
                    >
                      Create
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-3 p-10 overflow-y-auto text-white overflow-x-clip min-h-[calc(100vh-80px)]">
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <h1 className="text-xl font-medium">Tickets</h1>
        {!loading && (
          <button className="px-4 py-2 duration-150 ease-in-out border border-white hover:bg-white hover:text-black" onClick={() => setCreateTicket(true)}>
            Create Tickets
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="animate-pulse">Retrieving data...</p>
        </div>
      ) : (
        <>
          {tickets.length === 0 ? (
            <div className="flex flex-col items-center justify-center w-full">
              <p className="text-xl font-light">No ticket tiers found.</p>
              <p className="underline cursor-pointer underline-offset-2" onClick={() => setCreateTicket(true)}>
                Create Tickets.
              </p>
            </div>
          ) : (
            <div className="w-full h-full mb-4 overflow-y-auto">
              <Table dataSource={tickets} className="h-full" pagination={false} sticky={true} loading={loading}>
                <Table.Column title="Title" key={uuidv4()} dataIndex="title" render={(title) => <p className="font-semibold">{title}</p>} />
                <Table.Column
                  title="Price"
                  key={uuidv4()}
                  dataIndex="stripePriceId"
                  render={(price) => <p className="font-semibold">{formatCurrency(price.unit_amount)}</p>}
                />
                <Table.Column title="Active" key={uuidv4()} dataIndex="live" render={(val) => <p className="font-semibold">{val ? "Yes" : "No"}</p>} />
                <Table.Column
                  title="Admission Ticket"
                  key={uuidv4()}
                  dataIndex="admission"
                  render={(val) => <p className="font-semibold">{val ? "Yes" : "No"}</p>}
                />
                <Table.Column
                  title=""
                  key={uuidv4()}
                  dataIndex="ticketId"
                  render={(val) => (
                    <div className="flex flex-row items-center justify-end w-full">
                      <button
                        onClick={() => navigate("/management/tickets/" + val)}
                        className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                      >
                        View / Edit
                      </button>
                    </div>
                  )}
                />
              </Table>
            </div>
          )}
          {renderNewTicketModal()}
        </>
      )}
    </div>
  );
};

export default ManagementTickets;
