import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import PageLoader from "./components/pageLoader";
import CoreLayout from "./layouts";
import { ConfigProvider, theme } from "antd";
import { Toaster } from "react-hot-toast";
import { CartProvider } from "react-use-cart";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "./components/navbar";
import Home from "./views/home";
import Tickets from "./views/public/tickets";
import Checkout from "./views/public/checkout";
import CheckoutSuccess from "./views/public/checkoutSuccess";
import ManagementLayout from "./layouts/managementLayout";
import PageNotFound from "./views/404";
import { ExchangeToken, GetUser } from "./actions/auth";
import AuthView from "./views/auth";
import { connect } from "react-redux";
import Memberships from "./views/public/memberships";
import IDMeLoading from "./components/idMeLoading";
import ProfileLayout from "./layouts/profileLayout";
import EventPage from "./views/public/event/eventPage";
import Events from "./views/public/events";
import FEVersion from "./views/version";

const App = ({ authState, loginUser, logoutUser }) => {
  const [loading, setLoading] = useState(false);
  const [dummyLoading, setDummyLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      setTimeout(() => setLoading(false), 350);
    }
    return () => {
      inView = false;
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#1733ad",
          colorInfo: "#1733ad",
          colorLink: "#234e85",
          borderRadius: 5,
          colorTextBase: "#fdfbfd",
          colorError: "#ef233c",
          colorSuccess: "#49a078",
          colorWarning: "#fdca40",
          colorBgBase: "#000",
          colorBgElevated: "#060606",
          colorBgContainer: "#000",
        },
        components: {
          Skeleton: {
            colorFillContent: "rgba(0, 0, 0, 0.06)",
          },
          Checkbox: {
            colorBgContainer: "rgba(0, 0, 0, 0)",
            colorPrimaryBorder: "rgb(23, 51, 173)",
            colorPrimary: "rgb(0, 48, 255)",
            colorPrimaryHover: "#3d4044",
          },
          Collapse: {
            colorBorder: "rgb(217, 217, 217)",
            contentBg: "rgb(255, 255, 255)",
            colorText: "rgba(37, 37, 37, 0.88)",
            colorTextHeading: "rgba(37, 37, 37, 0.88)",
            headerBg: "rgb(255, 255, 255)",
          },
          Select: {
            controlHeight: 46,
            colorBgElevated: "rgb(13, 13, 13)",
          },
        },
      }}
    >
      <CartProvider id={"coracle-" + process.env.REACT_APP_INSTITUTION_SLUG}>
        <Toaster
          // position="top-right"
          position="bottom-right"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            duration: 3000,
            style: {
              background: "#000814",
              color: "#fff",
              borderRadius: "5px",
            },
            className: "maxZIndex",
            success: {
              style: {
                paddingLeft: "15px",
              },
            },
            error: {
              style: {
                paddingLeft: "15px",
              },
            },
          }}
        />
        <HelmetProvider>
          <div className="w-full h-full min-h-screen overflow-x-hidden overflow-y-auto">
            <Routes>
              <Route path="/*" element={<Navbar parentLoading={loading} />}>
                <Route index element={<Home />} />
                <Route path="tickets" element={<Tickets />} />
                <Route path="memberships" element={<Memberships />} />
                <Route path="events" element={<Events />} />
                <Route path="checkout" element={<Checkout />} />
                <Route path="events/:eventId" element={<EventPage />} />
                <Route path="checkout/success/:orderId" element={<CheckoutSuccess />} />
                <Route path="profile/*" element={authState.isAuth ? <ProfileLayout /> : <Navigate to="404" />} />
                <Route path="management/*" element={authState.isAuth ? <ManagementLayout /> : <Navigate to="404" />} />
                <Route path="idme/callback" element={<IDMeLoading />} />
                <Route path="version/info" element={<FEVersion />} />
                <Route path="auth" element={<AuthView />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </div>
        </HelmetProvider>
      </CartProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => ({
  authState: {
    isAuth: state.isAuth,
    email: state.email,
    user: state.user,
    name: state.name,
    role: state.role,
    profilePicture: state.profilePicture,
  },
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (data) => dispatch({ payload: data, type: "LOGIN" }),
  logoutUser: (data) => dispatch({ type: "LOGOUT" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
