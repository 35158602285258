import { useState, useEffect } from "react";
import { GetDashboard } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import landingAsset from "../assets/landingasset1.jpg";
import Footer from "../components/footer";
import dayjs from "dayjs";
import { ArrowRight } from "iconoir-react";
import { Helmet } from "react-helmet-async";
import SEOComponent from "../components/seoComponent";
import { GetAllEvents } from "../actions/data";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "America/New_York";

const Home = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetDashboard()
        .then((res) => {
          setData(res.data);
          loadEvents();
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const loadEvents = () => {
    GetAllEvents()
      .then((res) => {
        setEvents(res.data);
        setTimeout(() => setEventsLoading(false), 700);
      })
      .catch((err) => {
        setEventsLoading(false);
        setEvents([]);
      });
  };

  const renderToday = () => {
    let status = "Closed";
    let now = dayjs();
    const currentTime = now.tz(tz, true);
    let day = currentTime.day();
    let hour = currentTime.hour();
    const openDays = [0, 4, 5, 6, 7];
    if (openDays.includes(day)) {
      if (day === 0) {
        if (hour >= 13 && hour <= 17) {
          status = "Open until 5PM";
        } else if (hour >= 17) {
          status = "Closed at 5PM";
        } else {
          status = "Opens at 1PM";
        }
      } else {
        if (hour >= 10 && hour <= 17) {
          status = "Open until 5PM";
        } else if (hour >= 17) {
          status = "Closed at 5PM";
        } else {
          status = "Opens at 10AM";
        }
      }
    }
    return status;
  };

  const renderEventDate = (eventData) => {
    const { startDate, endDate, eventType, showTime } = eventData;
    const isSingleDay = eventType === "single-day" ?? true;

    let renderTime = "";
    if (showTime && showTime.length > 0) {
      if (showTime.length === 2) {
        renderTime = ` - From ${dayjs(startDate).format("hh:mm A")} to ${dayjs(endDate).format("hh:mm A")}`;
      } else if (showTime.length === 1) {
        if (showTime.includes("startTime")) {
          renderTime = ` - Starts at ${dayjs(startDate).format("hh:mm A")}`;
        } else if (showTime.includes("endTime")) {
          renderTime = ` - Ends at ${dayjs(startDate).format("hh:mm A")}`;
        }
      }
    }

    return isSingleDay
      ? `${dayjs(startDate).format("MMMM Do, YYYY")} ${renderTime}`
      : `${dayjs(startDate).format("MMMM Do, YYYY")} - ${dayjs(endDate).format("MMMM Do, YYYY")}`;
  };

  const renderEvent = (event, index) => {
    return (
      <div
        className="flex items-center justify-between w-full gap-2 px-4 py-2 transition-all duration-300 rounded-md shadow-none cursor-pointer bg-gray-50 hover:bg-gray-200 hover:shadow-sm"
        onClick={() => navigate(`/events/${event.slug && event.slug.length > 0 ? event.slug : event.eventId}`)}
        key={`event-${index}`}
      >
        <div className="flex flex-col items-start justify-start">
          <p className="text-base font-semibold">{event.title.length > 30 ? event.title.substring(0, 30) + "..." : event.title}</p>
          <p className="text-xs">{renderEventDate(event)}</p>
        </div>
        <ArrowRight className="w-6 h-6 text-black" onClick={() => navigate(`/events/${event.slug && event.slug.length > 0 ? event.slug : event.eventId}`)} />
      </div>
    );
  };

  const renderEventList = () => {
    if (eventsLoading) {
      return (
        <div className="flex items-center justify-center flex-1 w-full h-full mt-3 animate-pulse text-black/70">
          <p>Loading Events...</p>
        </div>
      );
    } else if (!eventsLoading && events.length === 0) {
      return (
        <div className="flex items-center justify-center flex-1 w-full h-full mt-12">
          <p className="text-black/60">No events found. Please come back later!</p>
        </div>
      );
    } else {
      let eventsList = events.slice(0, 2);
      return eventsList.map((event, index) => {
        return renderEvent(event, index);
      });
    }
  };

  const navigateToTheInstitution = () => {
    window.open(
      "https://www.google.com/maps/place/Augusta+Museum+of+History/@33.4747658,-81.960556,15z/data=!3m1!5s0x88f9cc7166464adf:0x1bff65d851be2430!4m6!3m5!1s0x88f9cc7163f83a1b:0xfa61806456059657!8m2!3d33.4747658!4d-81.960556!16s%2Fm%2F080czzg?entry=ttu",
      "_blank"
    );
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full h-full overflow-y-auto">
      <SEOComponent title="Home" />
      <div className="w-full h-[70vh] min-h-[650px] flex flex-col justify-end items-center relative amhHero">
        <div className="w-full max-w-content flex flex-col justify-end items-start mb-[10%]">
          <h2 className="text-white font-display text-8xl">Experience</h2>
          <h2 className="text-white font-display text-8xl">The Past</h2>
          <div className="flex flex-row items-center justify-start gap-5 mt-3">
            <button
              className="px-5 py-2 font-medium text-black duration-150 ease-in-out bg-white border border-white hover:bg-black hover:text-white text-md"
              onClick={() => navigate("/tickets")}
            >
              Buy Tickets
            </button>
            <button
              className="px-5 py-2 font-medium text-white duration-150 ease-in-out border border-white hover:bg-white hover:text-black text-md"
              onClick={() => navigate("/events")}
            >
              Explore Events
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#e2e2d3] h-20 w-full flex justify-center items-center text-black">
        <div className="flex flex-row items-center justify-between w-full max-w-content">
          <h3 className="text-2xl font-semibold font-display">Quick Info</h3>
          <div className="flex flex-row items-center justify-end gap-2">
            <p className="font-display">Escape the reality and relive the past with Augusta's Museum of History</p>
            <div className="h-[1px] w-3 bg-black" />
            <button
              className="font-display font-semibold underline decoration-[#e2e2d3] hover:decoration-black duration-150 underline-offset-2"
              onClick={() => navigate("/about")}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-start w-full"
        style={{
          background: `linear-gradient(#e9ecef 0 0) calc(1*100%/6),
            linear-gradient(#e9ecef 0 0) calc(2*100%/6),
            linear-gradient(#e9ecef 0 0) calc(3*100%/6),
            linear-gradient(#e9ecef 0 0) calc(4*100%/6),
            linear-gradient(#e9ecef 0 0) calc(5*100%/6)`,
          backgroundSize: "2px 100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#fafafa",
        }}
      >
        <div className="w-full max-w-content grid grid-cols-3 gap-4 mb-16 mt-20 mx-[10%]">
          <div className="flex flex-col items-start justify-start w-full h-full p-10 bg-white rounded-md shadow-lg shadow-gray-400/80">
            <h3 className="text-4xl font-semibold font-display">Visit AMH</h3>
            <div className="flex flex-col items-start justify-center gap-1 mt-3 mb-5">
              <p className="text-lg font-semibold">Hours:</p>
              <p>Tuesday to Saturday: 10AM - 5PM</p>
              <p>Sunday: 1PM - 5PM</p>
              <p>Monday to Wednesday: Closed</p>
              <p className="text-sm text-gray-500">Today: {renderToday()}</p>
            </div>
            <div className="flex items-center justify-between w-full gap-2">
              <button
                className="w-full px-5 py-2 font-medium text-white transition-all duration-300 ease-in-out bg-black border border-black rounded-md hover:bg-white hover:text-black text-md"
                onClick={() => navigate("/tickets")}
              >
                Buy Tickets
              </button>
              <button
                className="w-full px-5 py-2 font-medium text-black transition-all duration-300 ease-in-out border border-black rounded-md hover:bg-black hover:text-white text-md "
                onClick={() => navigate("/events")}
              >
                Get Directions
              </button>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full h-full p-10 bg-white rounded-md shadow-lg shadow-gray-400/80">
            <h3 className="text-4xl font-semibold font-display">Coming Up</h3>
            <div className="flex flex-col items-start justify-center w-full gap-1 mt-3 mb-5">{renderEventList()}</div>
            {!eventsLoading && events.length > 0 && (
              <div className="flex items-end justify-between flex-grow w-full gap-2">
                <button
                  className="w-full px-5 py-2 font-medium text-black transition-all duration-300 ease-in-out border border-black rounded-md hover:bg-black hover:text-white text-md "
                  onClick={() => navigate("/events")}
                >
                  See All Events
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-full h-full p-10 bg-white rounded-md shadow-lg shadow-gray-400/80">
            <h3 className="text-4xl font-semibold font-display">Getting here</h3>
            <div
              className="flex flex-col items-start justify-center gap-1 mt-3 mb-5 transition-all duration-200 cursor-pointer group"
              onClick={() => navigateToTheInstitution()}
            >
              <p>Address:</p>
              <p className="text-lg font-semibold transition-all duration-200 group-hover:text-blue-600">560 Reynolds St.</p>
              <p className="text-lg font-semibold transition-all duration-200 group-hover:text-blue-600">Augusta, GA 30901</p>
            </div>
            <div className="flex items-end justify-between flex-grow w-full gap-2">
              <button
                className="w-full px-5 py-2 font-medium text-black transition-all duration-300 ease-in-out border border-black rounded-md hover:bg-black hover:text-white text-md "
                onClick={() => navigate("/directions-and-parking")}
              >
                Directions & Parking
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-start w-full"
        style={{
          background: `linear-gradient(#e9ecef 0 0) calc(1*100%/6),
            linear-gradient(#e9ecef 0 0) calc(2*100%/6),
            linear-gradient(#e9ecef 0 0) calc(3*100%/6),
            linear-gradient(#e9ecef 0 0) calc(4*100%/6),
            linear-gradient(#e9ecef 0 0) calc(5*100%/6)`,
          backgroundSize: "2px 100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#fafafa",
        }}
      >
        <div className="w-full max-w-content flex flex-row justify-between items-center my-20 mx-[10%]">
          <div className="flex flex-col items-start justify-center w-2/5 gap-1 ml-10">
            <p className="text-5xl font-display">About us</p>
            <p className="text-2xl text-gray-400 font-display">Our Mission</p>
            <p className="my-5">
              The Augusta Museum of History collects, preserves, and interprets history in relation to the past of Augusta and Central Savannah River region for
              the education and enrichment of present and future generations.
            </p>
            <div className="flex flex-row items-center justify-start gap-2 mt-5">
              <div className="h-[1px] w-3 bg-black" />
              <button
                className="font-display font-semibold underline decoration-[#e2e2d3] hover:decoration-black duration-150 underline-offset-2"
                onClick={() => navigate("/about")}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="w-[550px] bg-[url('./assets/landingasset2.jpg')] h-[550px] bg-cover bg-no-repeat bg-center relative">
            <img src={landingAsset} className="w-3/5 h-3/4 object-cover object-center absolute top-[70px] -left-[140px]" alt="img" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
