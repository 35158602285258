import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const IDMeLoading = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    if (code && code.length > 10) {
      console.log("CODE: ", code);
    } else {
      console.log("NO CODE");
    }
  }, [code]);
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-screen gap-3 bg-white">
      <h1 className="text-3xl font-semibold animate-pulse">
        Confirming your association
      </h1>
      <p>Give us a few moments, do not close or leave this page....</p>
    </div>
  );
};

export default IDMeLoading;
