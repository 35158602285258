import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ManagementCheck } from "../actions/auth";
import PageNotFound from "../views/404";
import ManagementHome from "../views/management";
import ManagementNav from "../components/managementNav";
import ManagementTickets from "../views/management/tickets";
import OpenTicket from "../views/management/openTicket";
import InstitutionSettings from "../views/management/settings";
import PaymentSettings from "../views/management/settings/paymentSettings";
import LinkStripe from "../views/management/settings/linkStripe";
import ManagementMemberships from "../views/management/memberships";
import OpenMembership from "../views/management/memberships/openMembership";
import ManageEvents from "../views/management/events";
import CreateNewEvent from "../views/management/events/newEvent";
import Integrations from "../views/management/settings/integrations";
import OpenEvent from "../views/management/events/openEvent";
// import Explore from "../views/explore";
// import Analytics from "../views/admin/analytics";
// import CreateEvent from "../views/admin/createEvent";

const ManagementLayout = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let present = true;
    if (present) {
      ManagementCheck()
        .then((res) => {
          setData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          navigate("/");
        });
    }
    return () => {
      present = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<ManagementNav authState={authState} />}>
        <Route index element={<ManagementHome authState={authState} data={data} />} />
        <Route path="tickets" element={<ManagementTickets authState={authState} />} />
        <Route path="tickets/:ticketId" element={<OpenTicket authState={authState} />} />
        <Route path="settings/*">
          <Route index element={<InstitutionSettings authState={authState} data={data} />} />
          <Route path="payments" element={<PaymentSettings authState={authState} data={data} />} />
          <Route path="payments/stripe" element={<LinkStripe authState={authState} data={data} />} />
          <Route path="integrations" element={<Integrations authState={authState} data={data} />} />
          <Route path="*" element={<PageNotFound authState={authState} />} />
        </Route>
        <Route path="/memberships/*">
          <Route index element={<ManagementMemberships authState={authState} data={data} />} />
          <Route path=":membershipId" element={<OpenMembership authState={authState} data={data} />} />
          <Route path="*" element={<PageNotFound authState={authState} />} />
        </Route>
        <Route path="/events/*">
          <Route index element={<ManageEvents authState={authState} data={data} />} />
          <Route path="new" element={<CreateNewEvent authState={authState} data={data} />} />
          <Route path=":eventId" element={<OpenEvent authState={authState} data={data} />} />
          <Route path="*" element={<PageNotFound authState={authState} />} />
        </Route>
        <Route path="*" element={<PageNotFound authState={authState} />} />
      </Route>
    </Routes>
  );
};

export default ManagementLayout;
