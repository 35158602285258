import axiosInstance from "./axios";

export const GetUser = () => {
  return axiosInstance.get("/auth/user");
};

// export const ExchangeToken = (token) => {
//   const config = {
//     headers: {
//       authorization: `Bearer ${token}`,
//     },
//   };
//   return axiosInstance.get("/auth/exchangeToken", config);
// };

export const GetDashboard = () => {
  return axiosInstance.get("/auth/dashboard");
};

export const ManagementCheck = () => {
  return axiosInstance.get("/auth/management");
};

export const ExchangeToken = (data) => {
  return axiosInstance.post("/sso/exchangeToken", data);
};

export const GetUserTickets = () => {
  return axiosInstance.get("/auth/user/tickets");
};

export const GetUserMemberships = () => {
  return axiosInstance.get("/auth/user/memberships");
};
