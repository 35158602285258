import { useSelector } from "react-redux";
import { selectAuth } from "../reducers";
import { useEffect, useState } from "react";

const PreFooter = () => {
  const authState = useSelector(selectAuth);

  const skip = authState.isAuth && authState.user?.memberships?.length && authState.user.memberships.some((m) => m.status === "active");

  return (
    !skip && (
      <section className="relative w-full">
        <div className="w-full mx-auto">
          <div className="bg-black md:max-w-content">
            <div className="px-6 mx-auto py-14 max-w-7xl sm:py-10 sm:px-12 md:flex md:items-center md:justify-between md:px-8">
              <div className="flex flex-col items-center gap-4 mt-5 sm:gap-1 sm:items-start sm:mt-0">
                <h2 className="text-3xl font-bold leading-3 tracking-tight text-white font-display sm:text-4xl">Become a Member</h2>
                <p className="text-sm leading-5 tracking-normal text-center font-base text-white/60 sm:text-left">
                  Enjoy unlimited free admission for you and your guest(s) on every visit.
                </p>
              </div>
              <div className="flex items-center justify-center mt-5 sm:justify-start sm:mt-5 md:mt-0 lg:flex-shrink-0 md:justify-end">
                <a
                  className="inline-block 4xl:w-full lg:w-auto py-3 px-8 text-center text-black font-bold hover:bg-[#E2E3D5] bg-white transition-all duration-300"
                  href={"/memberships"}
                >
                  Join Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default PreFooter;
