import { persistor } from "../store/store";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  email: "",
  user: {},
  name: "",
  role: "",
  profilePicture: "",
  token: null,
  checkoutSession: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isAuth = true;
      state.email = action.payload.user.email;
      state.user = action.payload.user;
      state.name = action.payload.user.name;
      state.role = action.payload.user.role;
      state.profilePicture = action.payload.user.profilePicture;
      state.token = action.payload.token;
    },
    logoutUser: (state, action) => {
      localStorage.removeItem("persist:coracleamh");
      state = initialState;
      window.location.reload();
    },
    updateCheckout: (state, action) => {
      state.checkoutSession = action.payload;
    },
    currentState: (state, action) => {
      return state;
    },
  },
});
export const selectAuth = (state) => state;
export const { loginUser, logoutUser, updateCheckout } = authSlice.actions;
export default authSlice.reducer;
