import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import CanvaLogo from "../../../assets/canvaLogo";
import { ExchangeCanvaCode } from "../../../actions/mgmt";

const CanvaAuthRedirect = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    let code = searchParams.get("code");
    let state = searchParams.get("state");
    if (code && state) {
      ExchangeCanvaCode({ code, state })
        .then((res) => {
          toast.success("Canva integrated successfully");
          navigate("/profile/integrations");
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error integrating Canva, please try again");
          navigate("/profile/integrations");
        });
    } else {
      toast.error("Error integrating Canva, please try again");
      navigate("/profile/integrations");
    }
  }, []);

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-[calc(100vh-200px)] overflow-y-auto">
      <div className="flex flex-col items-center justify-center flex-grow w-full h-full gap-4 mx-auto mt-5 text-white max-w-content">
        <CanvaLogo className="w-auto h-12" />
        <p className="text-xl font-extrabold animate-pulse">VERIFYING CONNECTION</p>
        <p className="text-lg font-extrabold text-red-600">DO NOT CLOSE THIS PAGE</p>
      </div>
    </div>
  );
};

export default CanvaAuthRedirect;
