import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { GetUser } from "../actions/auth";
import PageNotFound from "../views/404";
import ProfileNav from "../components/profileNav";
import ProfileHome from "../views/profile/home";
import ProfileTickets from "../views/profile/tickets";
import ProfileMemberships from "../views/profile/memberships";
import ProfileIntegrations from "../views/profile/integrations";
import CanvaAuthRedirect from "../views/management/settings/canvaAuthRedirect";
// import Explore from "../views/explore";
// import Analytics from "../views/admin/analytics";
// import CreateEvent from "../views/admin/createEvent";

const ProfileLayout = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let present = true;
    if (present) {
      GetUser()
        .then((res) => {
          setData(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          navigate("/");
        });
    }
    return () => {
      present = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !loading && (
      <Routes>
        <Route path="/" element={<ProfileNav authState={authState} />}>
          <Route index element={<ProfileHome />} />
          <Route path="tickets" element={<ProfileTickets authState={authState} />} />
          <Route path="memberships" element={<ProfileMemberships authState={authState} />} />
          <Route path="integrations" element={data.role < 4 ? <ProfileIntegrations authState={authState} /> : <Navigate to="/profile" />} />
          <Route path="integrations/canva/redirect" element={<CanvaAuthRedirect />} />
          <Route path="*" element={<PageNotFound authState={authState} />} />
        </Route>
      </Routes>
    )
  );
};

export default ProfileLayout;
