import { Helmet } from "react-helmet-async";

const SEOComponent = (props) => {
  return (
    <Helmet>
      <title>{props.title ? props.title + " | " + process.env.REACT_APP_INSTITUTION_NAME : process.env.REACT_APP_INSTITUTION_NAME}</title>
      <meta property="og:title" content={props.title ? props.title + " | " + process.env.REACT_APP_INSTITUTION_NAME : process.env.REACT_APP_INSTITUTION_NAME} />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={props.description ? props.description + " | " + process.env.REACT_APP_INSTITUTION_DESCRIPTION : process.env.REACT_APP_INSTITUTION_DESCRIPTION}
      />
      <meta property="og:image" content={props.image ? props.image : process.env.REACT_APP_BASE_FEATURED_IMG} />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="augusta.museum" />
      <meta property="twitter:url" content={window.location.href} />
      <meta
        name="twitter:title"
        content={props.title ? props.title + " | " + process.env.REACT_APP_INSTITUTION_NAME : process.env.REACT_APP_INSTITUTION_NAME}
      />
      <meta
        name="twitter:description"
        content={props.description ? props.description + " | " + process.env.REACT_APP_INSTITUTION_DESCRIPTION : process.env.REACT_APP_INSTITUTION_DESCRIPTION}
      />
      <meta name="twitter:image" content={props.image ? props.image : process.env.REACT_APP_BASE_FEATURED_IMG} />
    </Helmet>
  );
};

export default SEOComponent;
