import { useEffect, useState } from "react";
import { GetUserTickets } from "../../actions/auth";
import toast from "react-hot-toast";
import { GenerateQRURI, formatCurrency } from "../../components/utilities";
import dayjs from "dayjs";
import { Button, Modal, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import QRGenerator from "../../components/qrGenerator";
import { PDFDocument, TextAlignment } from "pdf-lib";

const ProfileTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
    GetUserTickets()
      .then((res) => {
        setTickets(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || "Error obtaining tickets");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const openTicket = (ticket) => {
    setTicket(ticket);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setTicket(null);
    setLoading(true);
    reloadData();
  };

  const navigate = useNavigate();

  const blobToDataUrl = async (blob) => {
    return new Promise((r) => {
      let a = new FileReader();
      a.onload = r;
      a.readAsDataURL(blob);
    }).then((e) => e.target.result);
  };

  const printTicket = async (ticket) => {
    let qr = await GenerateQRURI(ticket.ticketId);
    let dataUri = await blobToDataUrl(qr);
    const url = "https://s3.us-east-1.wasabisys.com/hypercdn/ticket.pdf";
    const arrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
    const doc = await PDFDocument.load(arrayBuffer);
    const form = doc.getForm();
    let titleField = form.getTextField("title");
    let titleRightField = form.getTextField("rightTitle");
    let ticketIdField = form.getTextField("ticketId");
    titleField.setText(ticket.title.toUpperCase());
    titleField.setFontSize(10);
    titleField.enableReadOnly();
    titleField.setAlignment(TextAlignment.Center);
    titleRightField.setText(ticket.title.toUpperCase());
    titleRightField.setFontSize(8);
    titleRightField.enableReadOnly();
    titleRightField.setAlignment(TextAlignment.Center);
    ticketIdField.setText(
      "#" +
        ticket.ticketId
          .split("-")
          [ticket.ticketId.split("-").length - 1].toUpperCase()
    );
    ticketIdField.setFontSize(6);
    ticketIdField.enableReadOnly();
    ticketIdField.setAlignment(TextAlignment.Center);
    form.flatten();
    const pngImage = await doc.embedPng(dataUri);
    let page = doc.getPage(0);
    const pngDims = pngImage.scaleToFit(88.8, 88.8);
    const { width, height } = page.getSize();
    page.drawImage(pngImage, {
      x: 442,
      y: height - 185,
      width: pngDims.width,
      height: pngDims.height,
    });
    doc.setAuthor("Coracle | getcoracle.com");
    doc.setTitle("Ticket Printout | " + ticket.title);
    doc.setSubject("Ticket Printout");
    doc.setCreator("Coracle | getcoracle.com");
    doc.setCreationDate(new Date());

    let saved = await doc.save();
    const file = new Blob([saved], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL, "_blank");
  };

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-screen gap-6 p-6 overflow-y-auto text-white bg-gray-950">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <p className="animate-pulse">Loading...</p>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-full px-6 pb-5 border-b border-gray-600">
            <h1 className="text-2xl font-semibold">Your Tickets</h1>
            <Button onClick={() => navigate("/tickets")}>Buy Tickets</Button>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 px-6">
            {tickets.map((ticket) => (
              <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-8 py-4 border border-gray-600 rounded-md">
                <div className="flex flex-col items-start justify-center gap-2">
                  <div className="flex items-center justify-start gap-4">
                    <p className="text-xl font-medium">
                      {ticket.title} Ticket{" "}
                    </p>
                    <Tag color={ticket.valid ? "success" : "default"}>
                      {ticket.valid ? "VALID" : "USED"}
                    </Tag>
                  </div>
                  <p className="text-sm font-medium tracking-wider text-gray-300 uppercase">
                    Price: {formatCurrency(ticket.ticketPrice)}
                  </p>
                  {!ticket.valid && (
                    <p className="text-sm font-medium tracking-wider text-gray-500 uppercase">
                      Used: {dayjs(ticket.usedAt).format("MM/DD/YYYY")}
                    </p>
                  )}
                  <p className="text-xs tracking-wide text-gray-500 uppercase">
                    Purchase Date:{" "}
                    {dayjs(ticket.createdAt).format("MM/DD/YYYY")}
                  </p>
                </div>
                <Button onClick={() => openTicket(ticket)}>View Ticket</Button>
              </div>
            ))}
          </div>
          <Modal
            open={isOpen}
            onCancel={closeModal}
            onClose={closeModal}
            destroyOnClose
            centered
            width={960}
            footer={null}
            height={320}
          >
            <div className="w-full h-full py-4">
              {ticket && (
                <div className="relative transition-all rounded-lg shadow-md ring-1 ring-gray-900/5 sm:mx-auto hover:scale-105 group">
                  <div className="mx-auto flex h-60 w-[768px] max-w-3xl flex-row items-center justify-between gap-0 text-[#0f172a]">
                    <div className="relative w-1/3 h-full bg-black rounded-l-lg">
                      <img
                        src="https://images.unsplash.com/photo-1587648415693-4a5362b2ce41"
                        className="object-cover w-full h-full opacity-50 rounded-l-xl"
                        alt="Ticket Background"
                      />
                      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-between px-1 text-white [writing-mode:vertical-rl]">
                        <p className="text-xs transform rotate-180 opacity-0 mix-blend-soft-light">
                          x
                        </p>
                        <img
                          src="https://i.imgur.com/cMoZjQZ.png"
                          className="top-0 left-0 w-24 h-auto mix-blend-exclusion"
                          alt="AMH Logo"
                        />
                        <p className="text-xs transform rotate-180 mix-blend-soft-light">
                          ADMIT ONE | ADMIT ONE | ADMIT ONE
                        </p>
                      </div>
                      <div className="absolute z-10 flex items-center justify-center w-full h-full"></div>
                    </div>
                    <div className="relative grid w-2/3 h-full grid-cols-5 bg-white rounded-r-lg">
                      <div className="flex flex-col items-center justify-between w-full h-full col-span-3 px-3 py-2">
                        <div className="flex w-full flex-row items-center justify-between border-y border-black px-2 py-0.5">
                          <p className="text-[0.6rem] uppercase leading-4">
                            AMH
                          </p>
                          <p className="text-sm font-bold leading-5 uppercase">
                            {ticket.title ? ticket.title : ticket.name}
                          </p>
                          <p className="text-[0.6rem] uppercase leading-4">
                            AMH
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-around flex-grow w-full px-2">
                          <div className="flex flex-col items-center justify-center w-full">
                            <p className="text-lg font-medium font-display">
                              Augusta Museum of History
                            </p>
                            <p className="text-xs font-heading">
                              Experience Yesterday - Today!
                            </p>
                          </div>
                          <div className="flex flex-row items-center justify-between w-full">
                            <div className="flex flex-col items-start justify-center w-full">
                              <p className="text-[0.6rem]">
                                Tue-Sat - 10AM to 5PM
                              </p>
                              <p className="text-[0.6rem]">Sun - 1PM to 5PM</p>
                              <p className="text-[0.6rem]">Mon-Wed - Closed</p>
                            </div>
                            <div className="flex flex-col items-end justify-center w-full">
                              <p className="text-[0.6rem]">(706) 722-8454</p>
                              <p className="text-[0.6rem]">
                                hello@augusta.museum
                              </p>
                              <p className="text-[0.6rem]">
                                www.augustamuseum.org
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-center w-full px-2 pt-2 border-t border-black">
                          <p className="text-[0.6rem] uppercase leading-4">
                            560 Reynolds St, Augusta, GA 30901
                          </p>
                        </div>
                      </div>
                      <div className="relative w-full h-full col-span-2 border-l border-dashed border-spacing-x-10 border-slate-950">
                        <div className="absolute left-0 top-0 flex h-full flex-row items-center justify-center px-1 text-[#0f172a]/20 [writing-mode:vertical-rl]">
                          <p className="text-xs font-bold transform rotate-180">
                            ADMIT ONE | ADMIT ONE | ADMIT ONE
                          </p>
                        </div>
                        <div className="flex flex-col items-center justify-between w-full h-full py-4 pl-4 pr-2">
                          <p className="text-sm font-bold uppercase">
                            {ticket.title ? ticket.title : ticket.name}
                          </p>
                          <QRGenerator
                            link={ticket.ticketId}
                            className="w-32 h-auto"
                            size={128}
                          />
                          <p className="font-body text-[0.6rem] font-semibold uppercase tracking-wide text-[#0f172a]/70">
                            #
                            {
                              ticket.ticketId.split("-")[
                                ticket.ticketId.split("-").length - 1
                              ]
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute h-60 w-[768px] top-0 left-[96px] rounded-lg bg-black/40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all group-hover:backdrop-blur-sm cursor-pointer">
                    <button
                      className="px-4 py-2 text-xs font-bold text-black uppercase transition-all duration-300 bg-gray-200 border rounded-md border-slate-400 hover:bg-black hover:text-white"
                      onClick={() => printTicket(ticket)}
                    >
                      Print Ticket
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ProfileTickets;
