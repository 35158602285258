import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import MarkdownEditor from "../../../../components/markdownEditor";
import processHtmlForTailwind from "../../../../components/processHtmlForTailwind";
import { UpdateEvent } from "../../../../actions/mgmt";
import toast from "react-hot-toast";

const EventDetails = ({ eventData, reloadData }) => {
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [summary, setSummary] = useState(eventData.summary);
  const [description, setDescription] = useState(eventData.description);
  const [rawDescription, setRawDescription] = useState(eventData.rawDescription);

  useEffect(() => {
    let inView = true;
    if (inView) {
      setTimeout(() => setLoading(false), 700);
    }
    return () => {
      inView = false;
    };
  }, []);

  const updateSummary = (v) => {
    setChanged(true);
    setSummary(v);
  };

  const updateDescription = (v) => {
    setChanged(true);
    setDescription(processHtmlForTailwind(v));
    setRawDescription(v);
  };

  const submitChanges = () => {
    if (summary.length < 10) {
      toast.error("Summary must be at least 10 characters long.");
      return;
    }
    if (description.length < 30 || rawDescription.length < 30) {
      toast.error("Please enter a valid, longer description for the event.");
      return;
    }
    setLoading(true);
    UpdateEvent(eventData.eventId, { summary, description, rawDescription })
      .then((res) => {
        toast.success("Event details updated successfully.");
        reloadData();
        setChanged(false);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating event details, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  return (
    <div className="flex flex-col items-start justify-center w-full max-w-5xl gap-2 mx-auto">
      {!loading && (
        <div className="flex flex-col items-center justify-start w-full gap-4">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <h3 className="text-2xl font-bold">Summary</h3>
            <p className="text-sm">
              Grab people's attention with a short description of your event. Attendees will see this at the top of your event page. (140 characters max)
            </p>
            <div className="w-full p-4">
              <Input.TextArea
                rows={4}
                maxLength={140}
                className="!resize-none"
                placeholder="Provide a brief summary for the event..."
                onChange={({ target: { value } }) => updateSummary(value)}
                defaultValue={eventData.summary}
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <h3 className="text-2xl font-bold">Description</h3>
            <p className="text-sm">
              Add a more detailed description to your event. Tell attendees what to expect, how to prepare, and any other important details.
            </p>
            <MarkdownEditor onChange={(v) => updateDescription(v)} defaultValue={eventData.rawDescription} />
          </div>
          <div className="flex items-center justify-end w-full pt-5 border-t border-white/20">
            <Button type="primary" disabled={!changed} onClick={() => submitChanges()}>
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
