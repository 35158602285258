import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Ticket } from "styled-icons/ionicons-outline";
import { Input, InputNumber, Modal, Select, Switch, Table } from "antd";
import { CreateANewMembership, CreateANewTicket, GetAllMemberships } from "../../../actions/mgmt";
import toast from "react-hot-toast";
import { formatCurrency } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Contact2 } from "lucide-react";

const ManagementMemberships = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);
  const [createMembership, setCreateMembership] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [military, setMilitary] = useState(false);
  const [student, setStudent] = useState(false);
  const [militaryDiscount, setMilitaryDiscount] = useState(0);
  const [studentDiscount, setStudentDiscount] = useState(0);
  const [membershipType, setMembershipType] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      reloadData();
    }

    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    GetAllMemberships()
      .then((res) => {
        setMemberships(res.data.memberships);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const closeCreateMembership = () => {
    setCreateMembership(false);
    setTitle("");
    setPrice(0);
    setDescription("");
    setMilitary(false);
    setStudent(false);
    setMilitaryDiscount(0);
    setStudentDiscount(0);
  };

  const submitMembership = () => {
    if (title === "") {
      toast.error("Please enter a title for this membership");
    } else if (price === 0) {
      toast.error("Please enter a price for this membership");
    } else if (description === "") {
      toast.error("Please enter a description for this membership");
    } else if (military && militaryDiscount === 0) {
      toast.error("Please enter a discount for military members");
    } else if (student && studentDiscount === 0) {
      toast.error("Please enter a discount for student members");
    } else if (!membershipType) {
      toast.error("Please select a membership type");
    } else {
      let toSend = {
        name: title,
        price: price,
        description: description,
        military: military,
        student: student,
        membershipType: membershipType,
      };
      if (military) {
        toSend.militaryDiscount = militaryDiscount;
      }
      if (student) {
        toSend.studentDiscount = studentDiscount;
      }
      setLoading(true);
      CreateANewMembership(toSend)
        .then((res) => {
          toast.success("Membership created successfully!");
          closeCreateMembership();
          navigate(`/management/memberships/${res.data.membershipId}`);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while creating the membership, please try again.");
          closeCreateMembership();
          setTimeout(() => setLoading(false), 700);
        });
    }
  };

  const renderNewMembershipModal = () => {
    return (
      <Modal
        title="Add a new membership tier"
        open={createMembership}
        onCancel={() => closeCreateMembership()}
        centered
        width={600}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeCreateMembership()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitMembership()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Create
            </button>
          </div>,
        ]}
      >
        <div className="mt-2">
          <p className="text-sm text-gray-300">Create a new membership tier and set up initial pricing for it</p>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <label htmlFor="title" className="block pl-1 text-sm font-medium text-white/50">
              Title <span className="text-red-500">*</span>
            </label>
            <Input placeholder="Membership Title" onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-2">
            <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
              Price <span className="text-red-500">*</span>
            </label>
            <InputNumber placeholder="Membership Price (per year)" className="w-full" step={0.01} min={0} onChange={(e) => setPrice(e)} prefix="$" />
          </div>
          <div className="flex flex-col items-start justify-start w-full col-span-2 gap-2">
            <label htmlFor="title" className="block pl-1 text-sm font-medium text-white/50">
              Membership Type <span className="text-red-500">*</span>
            </label>
            <Select
              options={[
                { label: "Individual and Family Membership", value: "Individual and Family" },
                { value: "Corporate", label: "Corporate" },
              ]}
              placeholder="Select the membership type"
              className="w-full"
              onChange={(e) => setMembershipType(e)}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full col-span-2 mt-3">
            <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
              Description <span className="text-red-500">*</span>
            </label>
            <Input.TextArea placeholder="Membership Description" onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full mt-5">
          <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
            Military Discount
          </label>
          <Switch className="bg-slate-800" onChange={setMilitary} />
        </div>
        {military && (
          <>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                $ Amount Discount for Military
              </label>
              <InputNumber placeholder="Military Discount" className="w-full" step={0.01} min={0} onChange={(e) => setMilitaryDiscount(e)} prefix="$" />
            </div>
          </>
        )}
        <div className="flex flex-row items-center justify-between w-full mt-5">
          <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
            Student Discount
          </label>
          <Switch className="bg-slate-800" onChange={setStudent} />
        </div>
        {student && (
          <>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                $ Amount Discount for Students
              </label>
              <InputNumber placeholder="Student Discount" className="w-full" step={0.01} min={0} onChange={(e) => setStudentDiscount(e)} prefix="$" />
            </div>
          </>
        )}
      </Modal>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-3 p-10 overflow-y-auto text-white overflow-x-clip min-h-[calc(100vh-80px)]">
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <h1 className="text-xl font-medium">Memberships</h1>
        {!loading && (
          <button className="px-4 py-2 duration-150 ease-in-out border border-white hover:bg-white hover:text-black" onClick={() => setCreateMembership(true)}>
            Add a Membership Tier
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="animate-pulse">Retrieving data...</p>
        </div>
      ) : (
        <>
          {memberships.length === 0 ? (
            <div className="flex flex-col items-center justify-center w-full">
              <p className="text-base font-light">No membership tiers found.</p>
              <p className="text-xl font-semibold transition-all duration-200 cursor-pointer hover:text-slate-500" onClick={() => setCreateMembership(true)}>
                Create a Membership Tier
              </p>
            </div>
          ) : (
            <div className="w-full h-full mb-4 overflow-y-auto">
              <Table dataSource={memberships} className="h-full" pagination={false} sticky={true} loading={loading}>
                <Table.Column title="Title" key={uuidv4()} dataIndex="name" render={(title) => <p className="font-semibold">{title}</p>} />
                <Table.Column
                  title="Price"
                  key={uuidv4()}
                  dataIndex="stripePriceId"
                  render={(price) => <p className="font-semibold">{formatCurrency(price.unit_amount)}</p>}
                />
                <Table.Column title="Active" key={uuidv4()} dataIndex="live" render={(val) => <p className="font-semibold">{val ? "Yes" : "No"}</p>} />
                <Table.Column title="Type" key={uuidv4()} dataIndex="membershipType" render={(val) => <p className="font-semibold">{val} Membership</p>} />
                <Table.Column
                  title=""
                  key={uuidv4()}
                  dataIndex="membershipId"
                  render={(val) => (
                    <div className="flex flex-row items-center justify-end w-full">
                      <button
                        onClick={() => navigate("/management/memberships/" + val)}
                        className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                      >
                        View / Edit
                      </button>
                    </div>
                  )}
                />
              </Table>
            </div>
          )}
          {renderNewMembershipModal()}
        </>
      )}
    </div>
  );
};

export default ManagementMemberships;
