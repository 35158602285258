import { useEffect, useState } from "react";
import StripeLogo from "../../../assets/stripeLogo";
import { SetupStripePayments } from "../../../actions/mgmt";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

const LinkStripe = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    let code = searchParams.get("code");
    if (code) {
      SetupStripePayments({ code })
        .then((res) => {
          toast.success("Stripe account connected successfully");
          navigate("/management/settings/payments");
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error connecting Stripe account");
          navigate("/management/settings/payments");
        });
    } else {
      toast.error("Error connecting Stripe account");
      navigate("/management/settings/payments");
    }
  }, []);

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-[calc(100vh-200px)] overflow-y-auto">
      <div className="flex flex-col items-center justify-center flex-grow w-full h-full gap-4 mx-auto mt-5 text-white max-w-content">
        <StripeLogo className="w-auto h-12" />
        <p className="text-xl font-extrabold animate-pulse">VERIFYING CONNECTION</p>
        <p className="text-lg font-extrabold text-red-600">DO NOT CLOSE THIS PAGE</p>
      </div>
    </div>
  );
};

export default LinkStripe;
