import dayjs from "dayjs";
import { ReactComponent as Logo } from "../assets/logoLight.svg";

const Footer = ({ authState, authDispatch }) => {
  return (
    <section className="py-12 lg:pt-14 lg:pb-8 bg-black w-full">
      <div className="container mx-auto px-4 mb-8 md:mb-10 max-w-content">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full lg:w-1/3 px-4 mb-6 lg:mb-0">
              <a className="flex flex-row items-center justify-start gap-3 mb-12" href="/">
                <Logo className="h-7" />
                <h1 className="font-display text-lg text-white">Augusta Museum of History</h1>
              </a>
              <div className="flex flex-col mb-8">
                <a className="inline-block text-sm text-white hover:text-white/40 hover:underline mb-3 underline underline-offset-2" href="tel:7067228454">
                  (706) 722-8454
                </a>
                <a
                  className="inline-block text-sm text-white hover:text-white/40 hover:underline underline underline-offset-2"
                  href="mailto:hello@augustamuseum.org"
                >
                  hello@augustamuseum.org
                </a>
              </div>
              <div>
                <a
                  className="inline-block text-white/40 hover:text-white mr-6"
                  href="https://www.facebook.com/AugustaMuseumofHistory"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.6349 20.7272V12.766H16.3583L16.7669 9.66243H13.6349V7.68126C13.6349 6.78299 13.8882 6.17083 15.203 6.17083L16.8771 6.17015V3.39421C16.5876 3.35731 15.5938 3.27271 14.4371 3.27271C12.0217 3.27271 10.3681 4.71878 10.3681 7.37389V9.66243H7.63647V12.766H10.3681V20.7272H13.6349Z"
                      fill="currentColor"
                    ></path>
                    <mask id="mask0_601_10113" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="7" y="3" width="10" height="18">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.6349 20.7272V12.766H16.3583L16.7669 9.66243H13.6349V7.68126C13.6349 6.78299 13.8882 6.17083 15.203 6.17083L16.8771 6.17015V3.39421C16.5876 3.35731 15.5938 3.27271 14.4371 3.27271C12.0217 3.27271 10.3681 4.71878 10.3681 7.37389V9.66243H7.63647V12.766H10.3681V20.7272H13.6349Z"
                        fill="white"
                      ></path>
                    </mask>
                  </svg>
                </a>
                <a className="inline-block text-white/40 hover:text-white mr-6" href="https://twitter.com/augustamuseum" target="_blank" rel="noreferrer">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.8181 6.14598C21.1356 6.44844 20.4032 6.65356 19.6336 6.74513C20.4194 6.27462 21.0208 5.52831 21.3059 4.64177C20.5689 5.0775 19.7553 5.39389 18.8885 5.56541C18.1943 4.82489 17.2069 4.36365 16.1118 4.36365C14.0108 4.36365 12.3072 6.06719 12.3072 8.16707C12.3072 8.46489 12.3408 8.75577 12.4057 9.03392C9.24434 8.87513 6.44104 7.3605 4.56483 5.05895C4.23686 5.61986 4.05028 6.27344 4.05028 6.9711C4.05028 8.29107 4.72243 9.45574 5.74225 10.1371C5.11877 10.1163 4.53237 9.94477 4.01901 9.65968V9.70719C4.01901 11.5498 5.33086 13.0876 7.07031 13.4376C6.75161 13.5234 6.41555 13.5709 6.06789 13.5709C5.82222 13.5709 5.58464 13.5466 5.35171 13.5002C5.8361 15.0125 7.24067 16.1123 8.90483 16.1424C7.6034 17.1623 5.96243 17.7683 4.1801 17.7683C3.87301 17.7683 3.57052 17.7498 3.27271 17.7162C4.95655 18.7974 6.95561 19.4279 9.10416 19.4279C16.1026 19.4279 19.928 13.6312 19.928 8.60398L19.9153 8.11147C20.6627 7.57834 21.3094 6.90853 21.8181 6.14598Z"
                      fill="currentColor"
                    ></path>
                    <mask id="mask0_601_10114" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="4" width="19" height="16">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.8181 6.14598C21.1356 6.44844 20.4032 6.65356 19.6336 6.74513C20.4194 6.27462 21.0208 5.52831 21.3059 4.64177C20.5689 5.0775 19.7553 5.39389 18.8885 5.56541C18.1943 4.82489 17.2069 4.36365 16.1118 4.36365C14.0108 4.36365 12.3072 6.06719 12.3072 8.16707C12.3072 8.46489 12.3408 8.75577 12.4057 9.03392C9.24434 8.87513 6.44104 7.3605 4.56483 5.05895C4.23686 5.61986 4.05028 6.27344 4.05028 6.9711C4.05028 8.29107 4.72243 9.45574 5.74225 10.1371C5.11877 10.1163 4.53237 9.94477 4.01901 9.65968V9.70719C4.01901 11.5498 5.33086 13.0876 7.07031 13.4376C6.75161 13.5234 6.41555 13.5709 6.06789 13.5709C5.82222 13.5709 5.58464 13.5466 5.35171 13.5002C5.8361 15.0125 7.24067 16.1123 8.90483 16.1424C7.6034 17.1623 5.96243 17.7683 4.1801 17.7683C3.87301 17.7683 3.57052 17.7498 3.27271 17.7162C4.95655 18.7974 6.95561 19.4279 9.10416 19.4279C16.1026 19.4279 19.928 13.6312 19.928 8.60398L19.9153 8.11147C20.6627 7.57834 21.3094 6.90853 21.8181 6.14598Z"
                        fill="white"
                      ></path>
                    </mask>
                  </svg>
                </a>
                <a
                  className="inline-block text-white/40 hover:text-white mr-6"
                  href="https://www.instagram.com/augustamuseumofhistory/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.6007 2.18176H16.3992C19.3874 2.18176 21.8184 4.61276 21.8182 7.60069V16.3992C21.8182 19.3871 19.3874 21.8181 16.3992 21.8181H7.6007C4.61276 21.8181 2.18188 19.3872 2.18188 16.3992V7.60069C2.18188 4.61276 4.61276 2.18176 7.6007 2.18176ZM16.3993 20.0759C18.4267 20.0759 20.0761 18.4266 20.0761 16.3992H20.076V7.60069C20.076 5.57343 18.4266 3.924 16.3992 3.924H7.6007C5.57343 3.924 3.92412 5.57343 3.92412 7.60069V16.3992C3.92412 18.4266 5.57343 20.076 7.6007 20.0759H16.3993ZM6.85721 12.0001C6.85721 9.16418 9.16425 6.85709 12.0001 6.85709C14.8359 6.85709 17.1429 9.16418 17.1429 12.0001C17.1429 14.8358 14.8359 17.1428 12.0001 17.1428C9.16425 17.1428 6.85721 14.8358 6.85721 12.0001ZM8.62805 11.9999C8.62805 13.8592 10.1408 15.3718 12.0001 15.3718C13.8593 15.3718 15.3721 13.8592 15.3721 11.9999C15.3721 10.1405 13.8594 8.62784 12.0001 8.62784C10.1407 8.62784 8.62805 10.1405 8.62805 11.9999Z"
                      fill="currentColor"
                    ></path>
                    <mask id="mask0_601_10115" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.6007 2.18176H16.3992C19.3874 2.18176 21.8184 4.61276 21.8182 7.60069V16.3992C21.8182 19.3871 19.3874 21.8181 16.3992 21.8181H7.6007C4.61276 21.8181 2.18188 19.3872 2.18188 16.3992V7.60069C2.18188 4.61276 4.61276 2.18176 7.6007 2.18176ZM16.3993 20.0759C18.4267 20.0759 20.0761 18.4266 20.0761 16.3992H20.076V7.60069C20.076 5.57343 18.4266 3.924 16.3992 3.924H7.6007C5.57343 3.924 3.92412 5.57343 3.92412 7.60069V16.3992C3.92412 18.4266 5.57343 20.076 7.6007 20.0759H16.3993ZM6.85721 12.0001C6.85721 9.16418 9.16425 6.85709 12.0001 6.85709C14.8359 6.85709 17.1429 9.16418 17.1429 12.0001C17.1429 14.8358 14.8359 17.1428 12.0001 17.1428C9.16425 17.1428 6.85721 14.8358 6.85721 12.0001ZM8.62805 11.9999C8.62805 13.8592 10.1408 15.3718 12.0001 15.3718C13.8593 15.3718 15.3721 13.8592 15.3721 11.9999C15.3721 10.1405 13.8594 8.62784 12.0001 8.62784C10.1407 8.62784 8.62805 10.1405 8.62805 11.9999Z"
                        fill="white"
                      ></path>
                    </mask>
                  </svg>
                </a>
                <a
                  className="inline-block text-white/40 hover:text-white"
                  href="https://www.youtube.com/channel/UC8Jv_wszKjtW8eeQd7Idn9w"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg height="24" width="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.001 461.001" xmlSpace="preserve">
                    <g>
                      <path
                        fill="currentColor"
                        d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
		c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
		C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
		c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-2/3 px-4">
              <div className="flex flex-wrap justify-between -mx-4 -mb-8">
                <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                  <ul>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/about">
                        About the Museum
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/mission-and-history">
                        Mission and History
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/staff-and-board">
                        Staff and Board of Trustees
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/job-opportunities">
                        Career Opportunities
                      </a>
                    </li>
                    <li>
                      <a className="text-xs text-white hover:text-white/40" href="/host-an-event">
                        Host an Event
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                  <ul>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/tickets">
                        Visit the Museum
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/donate">
                        Support the Museum
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/membership">
                        Become a Member
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/volunteer">
                        Become a Volunteer
                      </a>
                    </li>
                    <li>
                      <a className="text-xs text-white hover:text-white/40" href="/field-trips">
                        Field Trips
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                  <ul>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/shop">
                        Shop
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/education">
                        Education Center
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/exhibits">
                        Exhibits
                      </a>
                    </li>
                    <li>
                      <a className="text-xs text-white hover:text-white/40" href="/events">
                        Events
                      </a>
                    </li>
                    <li>
                      <a className="text-xs text-white hover:text-white/40" href="/press">
                        Press Room
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
                  <ul>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/contact">
                        Contact
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/accessibility">
                        Accessibility
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/corporate-partnerships">
                        Corporate Partnerships
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/terms-and-conditions">
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li className="mb-1">
                      <a className="text-xs text-white hover:text-white/40" href="/privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6 border-t border-white/10">
        <div className="container px-4 mx-auto">
          <div className="max-w-6xl mx-auto">
            <div className="sm:flex items-center justify-between -mb-5">
              <p className="text-xs text-white mb-5">All rights reserved &copy; Copyright 1937 - {dayjs().format("YYYY")} | Augusta Museum of History.</p>
              <p className="text-xs text-white mb-5">
                Powered by{" "}
                <a href="https://getcoracle.com" target="_blank" rel="noreferrer">
                  Coracle
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
