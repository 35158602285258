import dayjs from "dayjs";
import {
  CalendarCheck,
  Contact2,
  Ticket,
  Home,
  HeartHandshake,
  Store,
  Signpost,
  Pyramid,
  Boxes,
  HelpingHand,
  Bus,
  MessageSquareQuote,
  GraduationCap,
  Megaphone,
  QrCode,
  Users,
  Settings,
  Puzzle,
} from "lucide-react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { selectAuth } from "../reducers";

const ProfileNav = () => {
  const navigate = useNavigate();
  const authState = useSelector(selectAuth);

  let menuItems = [
    {
      name: "Profile Home",
      icon: <Home className="w-5 h-5" strokeWidth={1} />,
      link: "/",
    },
    {
      name: "Tickets",
      icon: <Ticket className="w-5 h-5" strokeWidth={1} />,
      link: "/tickets",
    },
    {
      name: "Memberships",
      icon: <Contact2 className="w-5 h-5" strokeWidth={1} />,
      link: "/memberships",
    },
    // {
    //   name: "Events",
    //   icon: <CalendarCheck className="w-5 h-5" strokeWidth={1} />,
    //   link: "/events",
    // },
    {
      name: "Donations",
      icon: <HelpingHand className="w-5 h-5" strokeWidth={1} />,
      link: "/donations",
    },
    {
      name: "Feedback",
      icon: <MessageSquareQuote className="w-5 h-5" strokeWidth={1} />,
      link: "/feedback",
    },
    {
      name: "Settings",
      icon: <Settings className="w-5 h-5" strokeWidth={1} />,
      link: "/settings",
    },
  ];

  if (authState.user.role < 4) {
    menuItems.push({
      name: "Integrations",
      icon: <Puzzle className="w-5 h-5" strokeWidth={1} />,
      link: "/integrations",
    });
  }

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full overflow-y-auto bg-black">
      <div className="flex items-start justify-start flex-auto flex-shrink-0 w-full h-full min-h-screen antialiased">
        <div className="fixed left-0 z-10 flex flex-col h-full text-white transition-all duration-300 bg-black border-r top-16 lg:top-20 w-14 hover:w-64 md:w-64 border-gray-200/10">
          <div className="flex flex-col justify-between flex-grow overflow-x-hidden overflow-y-auto">
            <ul className="flex flex-col py-4 space-y-1">
              {menuItems.map((item, index) => (
                <li>
                  <div
                    className="relative flex flex-row items-center pr-6 text-gray-400 transition-all duration-200 border-l-4 border-transparent cursor-pointer h-11 focus:outline-none hover:bg-gray-950 hover:text-white hover:border-white"
                    onClick={() => navigate(`/profile${item.link}`)}
                    key={index}
                  >
                    <div className="inline-flex items-center justify-center ml-4">{item.icon}</div>
                    <p className="ml-4 text-sm font-semibold tracking-wide truncate">{item.name}</p>
                  </div>
                </li>
              ))}
              <li>
                <p className="hidden px-5 py-3 text-xs text-center text-gray-700 lg:block mb-14">@ Copyright 2021-{dayjs().format("YYYY")} | Coracle</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-start flex-grow w-full h-full min-h-[(100vh - 5rem)] ml-14 md:ml-64">
          <div className="flex items-center justify-start flex-grow w-full h-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNav;
