import React, { useReducer } from "react";

const AuthContext = React.createContext();

const initAuth = {
  isAuth: false,
  email: "",
  user: {},
  name: "",
  role: "",
  profilePicture: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "valid-login":
      localStorage.setItem("token", action.payload.token);
      return {
        isAuth: true,
        email: action.payload.user.email,
        user: action.payload.user,
        name: action.payload.user.name,
        role: action.payload.user.role,
        profilePicture: action.payload.user.profilePicture,
      };
    case "log-out":
      localStorage.removeItem("token");
      localStorage.removeItem("token0");
      return initAuth;
    default:
      return initAuth;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initAuth);
  return <AuthContext.Provider value={{ ...state, authDispatch: dispatch }}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
