import { Button, Input, InputNumber, Modal, Table } from "antd";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { formatCurrency } from "../../../../components/utilities";
import toast from "react-hot-toast";
import useIsTabActive from "../../../../components/useIsTabActive";
import { AddTicketToEvent } from "../../../../actions/mgmt";

const EventTickets = ({ eventData, reloadData }) => {
  const [loading, setLoading] = useState(true);
  const [ticketModal, setTicketModal] = useState(false);
  const [hasTickets, setHasTickets] = useState(eventData.tickets && eventData.tickets.length > 0);
  const [ticketData, setTicketData] = useState({
    price: null,
    description: "",
    quantity: null,
  });

  const [activeTab, setActiveTab] = useState(true);

  const tabState = useIsTabActive();

  useEffect(() => {
    if (activeTab && !tabState) {
      setActiveTab(false);
    } else if (!activeTab && tabState) {
      setActiveTab(true);
      toast("Checking for ticket updates...");
      reloadData();
    }
  }, [tabState]);

  useEffect(() => {
    let inView = true;
    if (inView) {
      setTimeout(() => setLoading(false), 700);
    }
    return () => {
      inView = false;
    };
  }, []);

  const cancelTicketModal = () => {
    setTicketModal(false);
    setTicketData({
      price: null,
      description: "",
      quantity: null,
    });
    reloadData();
    setLoading(false);
  };

  const submitTicketModal = () => {
    if (ticketData.price === null) {
      toast.error("Please enter a valid admission price");
    } else if (ticketData.quantity === null || ticketData.quantity < 1) {
      toast.error("Please enter a valid quantity");
    } else if (ticketData.description === "" || ticketData.description.length < 10) {
      toast.error("Please enter a valid description");
    } else {
      const newTicket = {
        ...ticketData,
        title: eventData.title + " - Admission",
        ticketId: uuidv4(),
      };
      setLoading(true);
      AddTicketToEvent(eventData.eventId, newTicket)
        .then((res) => {
          toast.success("Ticket added successfully");
          cancelTicketModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while adding ticket, please try again.");
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col items-start justify-center w-full max-w-5xl gap-2 mx-auto">
      {!loading && (
        <div className="flex flex-col items-start justify-start w-full gap-4">
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="text-2xl font-bold">Manage tickets & admission for your event</h3>
            {hasTickets !== null && <Button onClick={() => setTicketModal(true)}>Add Tickets</Button>}
          </div>
          {!hasTickets ? (
            <div className="w-full mx-auto">
              <div className="relative px-6 py-24 overflow-hidden text-center isolate sm:rounded-3xl sm:px-16">
                <h2 className="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-white sm:text-4xl">Does your event have tickets?</h2>
                <p className="max-w-xl mx-auto mt-6 text-lg leading-8 text-gray-300">
                  If you wish to limit availability, or collect an admission fee, you can create tickets for your event.
                </p>
                <div className="flex items-center justify-center max-w-sm mx-auto mt-10 gap-x-6">
                  <Button size="large" block type="primary" onClick={() => setHasTickets(true)}>
                    Add tickets
                  </Button>
                </div>
                <svg
                  viewBox="0 0 1024 1024"
                  aria-hidden="true"
                  className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                >
                  <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
                  <defs>
                    <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                      <stop stopColor="#7775D6" />
                      <stop offset={1} stopColor="#E935C1" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          ) : (
            <div className="flex-col items-center justify-start w-full h-full gap-3 mb-4">
              <div className="w-full h-full overflow-y-auto">
                <Table dataSource={eventData.ticketData} className="h-full" pagination={false} sticky={true}>
                  <Table.Column title="Title" key={uuidv4()} width={"50%"} dataIndex="title" render={(title) => <p className="font-semibold">{title}</p>} />
                  <Table.Column
                    title="Price"
                    align="right"
                    key={uuidv4()}
                    width={"15%"}
                    dataIndex="price"
                    render={(price, r) => <p className="font-semibold">{r.free ? "Free" : formatCurrency(r.stripePriceId.unit_amount, false)}</p>}
                  />
                  <Table.Column
                    title="Quantity"
                    align="right"
                    key={uuidv4()}
                    width={"15%"}
                    dataIndex="quantity"
                    render={(quantity) => <p className="font-semibold">{quantity}</p>}
                  />
                  <Table.Column
                    title="Live"
                    align="right"
                    key={uuidv4()}
                    width={"15%"}
                    dataIndex="live"
                    render={(live) => <p className="font-semibold">{live ? "Yes" : "No"}</p>}
                  />
                  <Table.Column
                    title=""
                    key={uuidv4()}
                    width={"20%"}
                    dataIndex="ticketId"
                    render={(ticketId) => (
                      <div className="flex flex-row items-center justify-end w-full gap-2">
                        <Button onClick={() => window.open(`${process.env.REACT_APP_HOST}/management/tickets/${ticketId}`, "_blank")}>Edit</Button>
                      </div>
                    )}
                  />
                </Table>
                <Modal
                  open={ticketModal}
                  onCancel={() => cancelTicketModal()}
                  onClose={() => cancelTicketModal()}
                  onOk={() => submitTicketModal()}
                  centered
                  destroyOnClose
                  width={750}
                  title="Add a new ticket"
                >
                  <div className="grid grid-cols-2 gap-4 my-6">
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                      <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                        Price <span className="text-red-500">*</span>
                        <span className="text-xs italic font-base text-white/20"> (enter 0 if you just want to limit admissions)</span>
                      </label>
                      <InputNumber
                        placeholder="Admission Price"
                        className="w-full"
                        step={0.01}
                        min={0}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, price: e }))}
                        prefix="$"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                      <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                        Quantity available <span className="text-red-500">*</span>
                      </label>
                      <InputNumber
                        placeholder="Available quantity"
                        className="w-full"
                        step={1}
                        min={1}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, quantity: e }))}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full col-span-2 mt-3">
                      <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
                        Description <span className="text-red-500">*</span>
                      </label>
                      <Input.TextArea
                        placeholder="Ticket Description"
                        className="!resize-none"
                        rows={4}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, description: e.target.value }))}
                      />
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventTickets;
