import React, { useState, useRef } from "react";
import { Upload, X, Star, Loader, RefreshCw, Edit } from "lucide-react";
import { Button, Modal, Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
import { CreateCanvaDesign, ExportCanvaDesign } from "../actions/integrations";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { set } from "react-hook-form";

const MAX_FILES = 10;

const ImageUpload = ({ images, setImages, hasCanva = false, canvaImages, setCanvaImages, eventTitle, setCanvaLoading, userId }) => {
  const [error, setError] = useState(null);
  const [canvaOpen, setCanvaOpen] = useState(false);
  const [canvaStep, setCanvaStep] = useState(0);
  const [canvaData, setCanvaData] = useState(null);

  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    processFiles(files);
  };

  const processFiles = (files) => {
    setError(null);

    if (images.length + files.length + canvaImages.length > MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files. Please remove some files and try again.`);
      return;
    }

    const validFiles = files.filter((file) => {
      const isValid = file.type.match(/^image\/(jpeg|png)$/) && file.size <= 10 * 1024 * 1024;
      if (!isValid) {
        setError("Invalid file. Please upload JPEG or PNG images under 10MB.");
      }
      return isValid;
    });

    const newImages = validFiles.map((file) => ({
      file: file,
      id: uuidv4(),
      previewUrl: URL.createObjectURL(file),
      isPrimary: images.length + canvaImages.length === 0 && validFiles.indexOf(file) === 0,
      isCanva: false,
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const removeImage = (index, image) => {
    if (!image.isCanva) {
      setImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        if (updatedImages.length > 0) {
          if (image.isPrimary) {
            updatedImages[0].isPrimary = true;
          }
        } else {
          if (canvaImages.length > 0) {
            setCanvaImages((prevImages) => {
              const updatedImages = prevImages.map((img, i) => ({
                ...img,
                isPrimary: i === 0,
              }));
              return updatedImages;
            });
          }
        }
        return updatedImages;
      });
    } else {
      setCanvaImages((prevImages) => {
        const updatedImages = prevImages.filter((img) => img.canvaId !== image.canvaId);
        if (updatedImages.length > 0) {
          if (image.isPrimary) {
            updatedImages[0].isPrimary = true;
          }
        } else {
          if (images.length > 0) {
            setImages((prevImages) => {
              const updatedImages = prevImages.map((img, i) => ({
                ...img,
                isPrimary: i === 0,
              }));
              return updatedImages;
            });
          }
        }
        return updatedImages;
      });
    }
  };

  const setPrimaryImage = (index, image) => {
    if (image.isCanva) {
      setImages((prevImages) =>
        prevImages.map((img, i) => ({
          ...img,
          isPrimary: false,
        }))
      );
      setCanvaImages((prevImages) =>
        prevImages.map((img, i) => ({
          ...img,
          isPrimary: img.id === image.id,
        }))
      );
    } else {
      setImages((prevImages) =>
        prevImages.map((img, i) => ({
          ...img,
          isPrimary: i === index,
        }))
      );
      setCanvaImages((prevImages) =>
        prevImages.map((img, i) => ({
          ...img,
          isPrimary: false,
        }))
      );
    }
  };

  const isMaxFiles = canvaImages.length + images.length >= MAX_FILES;

  const closeCanvaModal = () => {
    setCanvaOpen(false);
    setCanvaStep(0);
    setCanvaData(null);
  };

  const renderCanvaStep = () => {
    if (canvaStep === 0) {
      return (
        <>
          <p>Let's create a design in Canva and link it back to {process.env.REACT_APP_INSTITUTION_NAME}.</p>
          <p>Click the button below to be taken to Canva in a new tab where you can build a design from scratch or use one of many existing templates.</p>
          <p>Once you're done return here and you'll be able to retrieve your design.</p>
          <p>If you wish to make additional changes to the design in the future, don't forget to resync it with Coracle.</p>
          <p>Happy designing!</p>
        </>
      );
    } else if (canvaStep === 1) {
      return (
        <>
          <p>Once you're done with your Canva design, click Continue to get the latest version and add it to your event</p>
          <p>If you wish to make additional changes to the design in the future, don't forget to resync it with Coracle.</p>
        </>
      );
    } else if (canvaStep === 2) {
      return (
        <div className="flex items-center justify-center w-full gap-2 py-5">
          <Loader size={24} className="animate-spin-slow" />
          <p>Please Wait</p>
        </div>
      );
    }
  };

  const handleCanvaDesign = () => {
    if (canvaStep === 0) {
      let title = eventTitle.length > 0 ? eventTitle : "Untitled Event";
      title = title + ` | ${dayjs().format("[Created at] hh:mm A[,] MM/DD/YYYY")}`;

      CreateCanvaDesign({ title: eventTitle })
        .then((res) => {
          setCanvaData(res.data);
          window.open(res.data.url, "_blank");
          setCanvaStep(1);
        })
        .catch((err) => {
          toast.error("Error creating Canva design. Please try again.");
        });
    } else if (canvaStep === 1) {
      setCanvaStep(2);
      toast("Please wait while we fetch the design from Canva. This may take up to 30 seconds.");
      setTimeout(() => {
        setCanvaLoading(true);
        ExportCanvaDesign({ id: canvaData.id })
          .then((res) => {
            let tmpCanva = canvaImages;
            tmpCanva.push({
              id: uuidv4(),
              previewUrl: res.data.url,
              editUrl: canvaData.url,
              canvaId: canvaData.id,
              title: canvaData.title,
              isCanva: true,
              isPrimary: canvaImages.length + images.length === 0,
              userId: res.data.userId,
              userName: res.data.userName,
            });
            setCanvaImages(tmpCanva);
            toast.success("Canva design added successfully");
            closeCanvaModal();
            setCanvaLoading(false);
          })
          .catch((err) => {
            toast.error("Something went wrong, please try again");
            setCanvaStep(1);
            setCanvaLoading(false);
          });
      }, 7500);
    }
  };

  const refreshCanvaImage = (image) => {
    toast("Please wait while we fetch the design from Canva. This may take up to 30 seconds.");
    setTimeout(() => {
      setCanvaLoading(true);
      ExportCanvaDesign({ id: image.canvaId })
        .then((res) => {
          let tmpCanva = canvaImages.filter((i) => i.id !== image.id);
          tmpCanva.push({
            id: uuidv4(),
            previewUrl: res.data.url,
            editUrl: image.editUrl,
            canvaId: image.canvaId,
            title: image.title,
            isCanva: true,
            isPrimary: image.isPrimary,
            userId: res.data.userId,
            userName: res.data.userName,
          });
          setCanvaImages(tmpCanva);
          toast.success("Canva design refreshed successfully");
          setCanvaLoading(false);
        })
        .catch((err) => {
          toast.error("Something went wrong, please try again");
          setCanvaStep(1);
          setCanvaLoading(false);
        });
    }, 7500);
  };

  return (
    <div className="w-full p-6 text-gray-200 rounded-lg">
      <div
        className="p-8 text-center transition-colors border-2 border-dashed rounded-lg cursor-pointer border-white/30 hover:border-white/60"
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={() => fileInputRef.current.click()}
      >
        <Upload className="w-12 h-12 mx-auto text-gray-400" />
        <p className="mt-2 text-sm text-gray-400">{isMaxFiles ? `Maximum of ${MAX_FILES} reached` : "Drag and drop an image"}</p>
        <input type="file" ref={fileInputRef} onChange={handleFileSelect} accept="image/jpeg,image/png" className="hidden" multiple disabled={isMaxFiles} />
      </div>

      <div className="flex items-center justify-between w-full gap-2 mt-4">
        <p className="w-full text-xs text-gray-400 max-w-1/2">
          Recommended image size: 2160 x 1080px • Maximum file size: 10MB • Supported image files: JPEG or PNG
        </p>
        {images.length + canvaImages.length < 10 && (
          <div className="flex items-center justify-end w-full gap-2 max-w-1/2">
            <Button onClick={() => fileInputRef.current.click()} type="primary" disabled={isMaxFiles}>
              {images.length + canvaImages.length > 0 ? "Add more images" : "Upload image"}
            </Button>
            {hasCanva && (
              <Button onClick={() => setCanvaOpen(true)} disabled={isMaxFiles}>
                Design with Canva
              </Button>
            )}
          </div>
        )}
      </div>

      {error && (
        <div className="p-4 rounded-md bg-red-50">
          <div className="flex">
            <div className="flex-shrink-0">
              <X size={20} className="text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="grid items-center grid-cols-3 gap-4 mt-6">
        {images.concat(canvaImages).map((image, index) => (
          <div key={index} className="relative group">
            <img src={image.previewUrl} alt={`Uploaded ${index + 1}`} className="object-cover w-full h-auto rounded" />
            <div className="absolute flex space-x-1 top-1 right-1">
              <button onClick={() => removeImage(index, image)} className="p-1 text-white transition-colors bg-red-600 rounded-full hover:bg-red-700">
                <Tooltip title="Remove image" placement="bottom">
                  <X size={16} />
                </Tooltip>
              </button>
              <button
                onClick={() => setPrimaryImage(index, image)}
                className={`${image.isPrimary ? "bg-yellow-500" : "bg-gray-600"} text-white rounded-full p-1 hover:bg-yellow-600 transition-colors`}
              >
                {!image.isPrimary ? (
                  <Tooltip title="Set as primary image" placement="bottom">
                    <Star size={16} />
                  </Tooltip>
                ) : (
                  <Star size={16} />
                )}
              </button>
              {image.isCanva && image.userId === userId && (
                <>
                  <button onClick={() => refreshCanvaImage(image)} className="p-1 text-white transition-colors bg-blue-600 rounded-full hover:bg-blue-700">
                    <Tooltip title="Reload from Canva" placement="bottom">
                      <RefreshCw size={16} />
                    </Tooltip>
                  </button>
                  <button
                    onClick={() => window.open(image.editUrl, "_blank")}
                    className="p-1 text-white transition-colors bg-blue-600 rounded-full hover:bg-blue-700"
                  >
                    <Tooltip title="Edit on Canva" placement="bottom">
                      <Edit size={16} />
                    </Tooltip>
                  </button>
                </>
              )}
            </div>
            {image.isPrimary && <span className="absolute px-2 py-1 text-xs font-bold text-black bg-yellow-500 rounded bottom-1 left-1">PRIMARY</span>}
            {image.isCanva && (
              <span className="absolute px-2 py-1 text-xs text-white bg-gradient-to-r from-[#02c4cd] to-[#7e25e8] rounded bottom-1 right-1 font-bold">
                CANVA
              </span>
            )}
          </div>
        ))}
      </div>
      <Modal
        open={canvaOpen}
        onClose={() => closeCanvaModal()}
        onCancel={() => closeCanvaModal()}
        onOk={() => handleCanvaDesign()}
        centered
        width={750}
        title="Design with Canva"
        okText={canvaStep === 0 ? "Continue to Canva" : "Add to Event"}
        footer={
          canvaStep === 2 ? null : (
            <div className="flex items-center justify-end w-full gap-2">
              <Button onClick={() => closeCanvaModal()}>Cancel</Button>
              <Button type="primary" onClick={() => handleCanvaDesign()} disabled={canvaStep === 2}>
                {canvaStep === 0 ? "Continue to Canva" : "Add to Event"}
              </Button>
            </div>
          )
        }
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 px-2 py-4">{renderCanvaStep()}</div>
      </Modal>
    </div>
  );
};

export default ImageUpload;
