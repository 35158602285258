import { useEffect, useState } from "react";
import { InfoCircle } from "iconoir-react";
import Footer from "../../components/footer";
import { GetAllMemberships } from "../../actions/data";
import toast from "react-hot-toast";
import { formatCurrency } from "../../components/utilities";
import { useCart } from "react-use-cart";
import _ from "lodash";
import { Popover, Skeleton } from "antd";
import { Helmet } from "react-helmet-async";
import LowerLevelImg from "../../assets/amhLowerLevel.jpg";
import MemberCard from "../../assets/memberCard.png";
import SEOComponent from "../../components/seoComponent";

const Memberships = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);

  const { addItem } = useCart();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllMemberships()
        .then((res) => {
          setMemberships(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving memberships, please try again.");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderMembership = (membership, index) => {
    return (
      <div className="flex flex-col items-start justify-center w-full rounded-sm shadow-lg shadow-gray-300" key={index}>
        <div className="flex flex-row items-center justify-between w-full p-6 py-8 bg-[#fafafa] mt-2">
          <div className="flex flex-col items-start justify-between">
            <p className="text-xl font-bold">{membership.name}</p>
            <p className="font-semibold text-md text-gray-900/60">{membership.description}</p>
            <p className="text-lg font-black text-black">{formatCurrency(membership.price)} per year</p>
            <button
              className="inline-block w-full md:w-auto py-3 px-8 text-center text-white font-bold rounded-sm mt-4 bg-[#061538] bg-gradient-to-r from-[#061538] via-[#1E2E52] to-[#2A3F71] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all ease-in-out duration-200"
              onClick={() => membershipJoin(membership)}
            >
              Join Now
            </button>
          </div>
          <img src={MemberCard} alt="Membership Card" className="object-cover w-auto h-16" />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2 p-6 bg-gray-200">
          {membership.benefits.map((benefit, index) => renderBenefit(benefit, index))}
          {renderDiscounts(membership)}
        </div>
      </div>
    );
  };

  const renderDiscounts = (membership) => {
    let discounts = [];
    if (membership.studentDiscount && membership.studentDiscount.eligible) {
      discounts.push("Student");
    }
    if (membership.militaryDiscount && membership.militaryDiscount.eligible) {
      discounts.push("Military");
    }
    if (discounts.length > 0) {
      return <p className="mt-3 text-xs font-semibold text-gray-600/80">* {discounts.join(" and ")} Discount Available</p>;
    }
  };

  const renderBenefit = (benefit, index) => {
    if (benefit.description && benefit.description.length > 0) {
      return (
        <Popover content={benefit.description} title={benefit.benefit} key={index} trigger="hover" placement="right">
          <div className="flex flex-row items-center justify-start gap-2 cursor-pointer">
            <p key={index} className="font-medium">
              -{benefit.benefit}
            </p>
            <InfoCircle className="w-auto h-5" />
          </div>
        </Popover>
      );
    } else {
      return (
        <p key={index} className="font-medium">
          -{benefit.benefit}
        </p>
      );
    }
  };

  const membershipJoin = (membership) => {
    const item = {
      id: membership.membershipId,
      name: membership.name,
      price: membership.price,
      description: membership.description,
      type: "membership",
      studentDiscount: membership.studentDiscount,
      militaryDiscount: membership.militaryDiscount,
    };
    addItem(item, 1);
    setLoading(true);
    setTimeout(() => {
      window.location.href = "/checkout";
    }, 1100);
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full overflow-y-auto bg-white">
      <SEOComponent title="Memberships" description="Enrich your experience with exclusive benefits" />
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-16 mt-6 mb-10 max-w-content">
        <section className="flex flex-col items-start justify-start w-full">
          <h3 className="text-5xl font-medium tracking-wide font-display">MEMBERSHIPS</h3>
          <h3 className="text-sm font-bold text-[#002AA8] uppercase">Enrich your experience with exclusive benefits</h3>
          <div className="grid items-center justify-between w-full grid-cols-2 gap-4 mt-10 h-fit">
            <div className="flex flex-col items-start justify-center w-full h-full gap-3">
              <div className="flex flex-row items-center justify-start gap-3 mb-4">
                <h3 className="text-4xl font-bold">Join today and experience more as a AMH Member!</h3>
              </div>
              <p className="text-sm tracking-wide">
                Discover something new on every visit and stay connected year-round. Enjoy unlimited free admission, priority access to exhibitions, and
                invitations to Members-only events.
              </p>
              <p className="text-sm font-semibold tracking-wide">
                Join today and become part of our global community of supporters dedicated to helping Augusta Museum of History educate and inspire millions
                each year through the power of history.
              </p>
            </div>
            <div className="flex flex-col items-end justify-start w-full h-full">
              <img src={LowerLevelImg} alt="Lower Level" className="object-cover w-full h-full" />
            </div>
          </div>
        </section>
        <section className="grid w-full grid-cols-1 divide-y divide-gray-600/40">
          {loading ? (
            <Skeleton paragraph={{ rows: 4, width: "100%" }} title={false} active />
          ) : (
            <div className="grid items-center grid-cols-3 gap-4 py-2 my-2">{memberships.map((membership, index) => renderMembership(membership, index))}</div>
          )}
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Memberships;
