import { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Ticket } from "styled-icons/ionicons-outline";
import { Input, InputNumber, Modal, Select, Switch, Table } from "antd";
import { CreateANewMembership, CreateANewTicket, GetAllMemberships, GetEvents } from "../../../actions/mgmt";
import toast from "react-hot-toast";
import { formatCurrency } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Contact2 } from "lucide-react";
import { Helmet } from "react-helmet-async";
import { EVENT_CATEGORIES, EVENT_VISIBILITY } from "../../../lib/eventEnums";

const ManageEvents = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [memberships, setMemberships] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      reloadData();
    }

    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    GetEvents()
      .then((res) => {
        setMemberships(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
        setTimeout(() => setLoading(false), 700);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-3 p-10 overflow-y-auto text-white overflow-x-clip min-h-[calc(100vh-80px)]">
      <Helmet>
        <title>Events | {process.env.REACT_APP_INSTITUTION_NAME}</title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <h1 className="text-xl font-medium">Events</h1>
        {!loading && (
          <button
            className="px-4 py-2 duration-150 ease-in-out border border-white hover:bg-white hover:text-black"
            onClick={() => navigate("/management/events/new")}
          >
            New Event
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="animate-pulse">Retrieving data...</p>
        </div>
      ) : (
        <>
          {memberships.length === 0 ? (
            <div className="flex flex-col items-center justify-center w-full min-h-[50vh] gap-4">
              <p className="text-3xl font-medium font-display">No events found</p>
              <button
                className="px-12 py-1.5 text-xs duration-150 ease-in-out border border-white hover:bg-white hover:text-black uppercase font-semibold tracking-wide"
                onClick={() => navigate("/management/events/new")}
              >
                New Event
              </button>
            </div>
          ) : (
            <div className="w-full h-full mb-4 overflow-y-auto">
              <Table dataSource={memberships} className="h-full" pagination={false} sticky={true} loading={loading}>
                <Table.Column title="Title" key={uuidv4()} dataIndex="title" render={(title) => <p className="font-semibold">{title}</p>} />
                <Table.Column
                  title="Category"
                  key={uuidv4()}
                  dataIndex="category"
                  render={(val) => <p className="font-semibold">{EVENT_CATEGORIES.find((c) => c.value === val).label}</p>}
                />
                <Table.Column
                  title="Visibility"
                  key={uuidv4()}
                  dataIndex="visibility"
                  render={(val) => <p className="font-semibold">{EVENT_VISIBILITY.find((c) => c.value === val).label}</p>}
                />
                <Table.Column title="Status" key={uuidv4()} dataIndex="status" render={(val) => <p className="font-semibold capitalize">{val}</p>} />
                <Table.Column
                  title=""
                  key={uuidv4()}
                  dataIndex="eventId"
                  render={(val) => (
                    <div className="flex flex-row items-center justify-end w-full">
                      <button
                        onClick={() => navigate("/management/events/" + val)}
                        className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                      >
                        View / Edit
                      </button>
                    </div>
                  )}
                />
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ManageEvents;
