import axiosInstance from "./axios";

export const ExchangeIdMeCode = (data) => {
  return axiosInstance.post("/integrations/idme/code", data);
};

export const CreateCanvaDesign = (data) => {
  return axiosInstance.post("/integrations/canva/designs/new", data);
};

export const ExportCanvaDesign = (data) => {
  return axiosInstance.post("/integrations/canva/getFile", data);
};

export const GetCanvaDesigns = (data) => {
  return axiosInstance.get("/integrations/canva/designs", data);
};
