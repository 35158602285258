import { useEffect, useState, useRef } from "react";
import { Clock, InfoCircle, Minus, Plus } from "iconoir-react";
import Footer from "../../components/footer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GetAllTickets } from "../../actions/data";
import toast from "react-hot-toast";
import { formatCurrency } from "../../components/utilities";
import { useCart } from "react-use-cart";
import _ from "lodash";
import { Skeleton } from "antd";
import { Helmet } from "react-helmet-async";
import PreFooter from "../../components/preFooter";
import SEOComponent from "../../components/seoComponent";

const Tickets = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [cart, setCart] = useState([]);
  const [dummyLoad, setDummyLoad] = useState(false);

  const navigate = useNavigate();
  const [setOpenCart] = useOutletContext();

  const { items, addItem, inCart, updateItemQuantity } = useCart();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllTickets()
        .then((res) => {
          setTickets(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket status, please try again.");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const renderTicket = (ticket) => {
    return (
      <div className="flex flex-row items-center justify-between w-full h-20 px-6">
        <div className="flex flex-col items-start justify-center gap-1">
          <p className="font-medium">{ticket.title}</p>
          {ticket.description ? <p className="text-xs text-gray-900/70">{ticket.description}</p> : null}
        </div>
        <div className="flex flex-row items-center justify-end gap-4">
          <p className="pr-6 text-sm font-semibold">{formatCurrency(ticket.price)}</p>
          {renderSpinner(ticket)}
        </div>
      </div>
    );
  };

  const addToCart = () => {
    if (cart.length === 0) {
      toast.error("Please select at least one ticket to add to your cart.", { position: "bottom-right" });
      return;
    }
    setLoading(true);
    let tmp = cart;
    for (let i = 0; i < tmp.length; i++) {
      const el = tmp[i];
      addItem(el, el.quantity);
    }
    setCart([]);
    setLoading(false);
    setOpenCart(true);
  };

  const increment = (itemData) => {
    setDummyLoad(true);
    let tmp = cart;
    let inCart = false;
    let failed = false;
    for (let i = 0; i < tmp.length; i++) {
      const el = tmp[i];
      if (el.id === itemData.id) {
        el.quantity = el.quantity + 1;
        if (el.quantity > 10) {
          failed = true;
        }
        inCart = true;
      }
    }
    if (!inCart) {
      let toAdd = itemData;
      toAdd.quantity = 1;
      tmp.push(toAdd);
    }
    if (failed) {
      toast.error("Maximum number of tickets reached");
    } else {
      setCart(tmp);
    }
    setTimeout(() => setDummyLoad(false), 700);
  };

  const decrement = (itemData) => {
    setDummyLoad(true);
    let tmp = cart;
    for (let i = 0; i < tmp.length; i++) {
      const el = tmp[i];
      if (el.id === itemData.id) {
        el.quantity = el.quantity - 1;
        if (el.quantity === 0) {
          tmp.splice(i, 1);
          break;
        }
      }
    }
    setCart(tmp);
    setTimeout(() => setDummyLoad(false), 700);
  };

  const renderSpinner = (ticket) => {
    let itemData = {
      id: ticket.ticketId,
      name: ticket.title,
      price: ticket.price,
      description: ticket.description,
      type: ticket.type,
    };
    let value = 0;
    let existing = _.find(cart, (el) => el.id === itemData.id);
    if (existing) {
      value = existing.quantity;
    }
    return (
      <div className="w-[120px] h-[40px] flex flex-row justify-center items-center gap-1 border border-slate-950">
        <button className="inline-block w-[30px] h-full bg-transparent" onClick={() => decrement(itemData)}>
          &minus;
        </button>
        <span className="block h-full w-[40px] rounded-md text-center leading-10 text-xs text-black">{value}</span>
        <button className="inline-block w-[30px] h-full bg-transparent" onClick={() => increment(itemData)}>
          &#43;
        </button>
      </div>
    );
  };

  const renderCartTotal = () => {
    let total = 0;
    for (let i = 0; i < cart.length; i++) {
      const el = cart[i];
      total += el.price * el.quantity;
    }
    return formatCurrency(total);
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full overflow-y-auto bg-white">
      <SEOComponent title="Tickets" description="Visit the Augusta Museum of History" />
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-16 mt-6 mb-10 max-w-content">
        <section className="flex flex-col items-start justify-start w-full">
          <h3 className="text-5xl font-medium tracking-wide font-display">TICKETS</h3>
          <h3 className="text-sm font-bold text-[#002AA8]">VISIT THE AUGUSTA MUSEUM OF HISTORY</h3>
          <div className="grid items-center justify-between w-full grid-cols-2 gap-4 mt-10 h-fit">
            <div className="flex flex-col items-start justify-center w-full h-full gap-3">
              <div className="flex flex-row items-center justify-start gap-3 mb-4">
                <InfoCircle className="w-12 h-12" />
                <h3 className="text-3xl font-semibold">HOW TO GET HERE</h3>
              </div>
              <p className="text-sm tracking-wide">
                Visit the Augusta Museum of History, Augusta's oldest historical agency devoted to the preservation and presentation of local and regional
                history.
              </p>
              <p className="text-sm font-semibold tracking-wide">
                Main parking is at the "back" of the building on the corner of 6th and Broad Street. Overflow parking on the Reynolds Street side.
              </p>
            </div>
            <div className="flex flex-col items-end justify-start w-full h-full">
              <div className="flex flex-row items-center justify-start gap-3 mb-4">
                <Clock className="w-12 h-12" />
                <h3 className="text-3xl font-semibold">HOURS</h3>
              </div>
              <table className="w-3/5 divide-y divide-gray-300">
                <tbody className="bg-white">
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Monday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">Closed</td>
                  </tr>
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Tuesday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">Closed</td>
                  </tr>
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Wednesday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">Closed</td>
                  </tr>
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Tuesday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">10 AM - 5 PM</td>
                  </tr>
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Tuesday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">10 AM - 5 PM</td>
                  </tr>
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Saturday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">10 AM - 5 PM</td>
                  </tr>
                  <tr className="odd:bg-gray-50">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium tracking-wider text-black uppercase whitespace-nowrap sm:pl-3">Sunday</td>
                    <td className="px-3 py-4 text-sm font-bold text-right text-black whitespace-nowrap">1 PM - 5 PM</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section className="grid w-full grid-cols-1 divide-y divide-gray-600/40">
          {loading ? (
            <Skeleton paragraph={{ rows: 4, width: "100%" }} title={false} active />
          ) : (
            <div className="grid items-center grid-cols-4 gap-10">
              <div className="flex flex-col items-start justify-start col-span-3 gap-3 divide-y divide-gray-500/20">
                {tickets.map((ticket, index) => renderTicket(ticket))}
              </div>
              <div className="flex flex-col items-center justify-start col-span-1 gap-3 px-4 py-10 border border-gray-700/20">
                <div className="flex flex-row items-center justify-between w-5/6 text-gray-900/80">
                  <p className="font-serif text-lg font-semibold tracking-wider">Subtotal</p>
                  <p className="font-serif text-lg font-semibold tracking-wider">{renderCartTotal()}</p>
                </div>
                <button
                  className="w-5/6 px-8 py-3 tracking-wider text-black uppercase transition-all bg-transparent border border-black hover:text-white hover:bg-black"
                  onClick={() => addToCart()}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          )}
        </section>
        <PreFooter />
      </div>
      <Footer />
    </div>
  );
};

export default Tickets;
