import { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DatePicker, Input, InputNumber, Switch, Drawer } from "antd";
import { AddPriceToATicket, GetOneTicket, UpdateTicketInfo, UpdateTicketPrice } from "../../actions/mgmt";
import toast from "react-hot-toast";
import { formatCurrency } from "../../components/utilities";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { ArrowRight, Copy, Edit, Link, QrCode } from "iconoir-react";
import QRCodeViewer from "../../components/QRCodeViewer";
const HyprTK = require("hyprtk")(process.env.REACT_APP_HYPRTK_API_KEY);

const OpenTicket = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [ticketData, setTicketData] = useState([]);
  const [openPrice, setOpenPrice] = useState(false);
  const [openNewPrice, setOpenNewPrice] = useState(false);
  const [priceData, setPriceData] = useState({
    stripePriceId: "",
    active: false,
    currency: "usd",
    unitCost: 0,
    activeFrom: "",
    activeTo: "",
    changedFrom: "",
    changedTo: "",
    addedBy: "",
    removedBy: "",
  });
  const [activeVal, setActiveVal] = useState(false);
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [dateVal, setDateVal] = useState(null);
  const [priceVal, setPriceVal] = useState(0);

  const [editTitle, setEditTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const [linkSettings, setLinkSettings] = useState(false);
  const [qrSettings, setQrSettings] = useState(false);

  const [shortSlug, setShortSlug] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    let inView = true;

    if (inView) {
      if (params && params.ticketId && params.ticketId !== "") {
        reloadData();
      } else {
        toast.error("Invalid Link");
        goBack();
      }
    }

    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    GetOneTicket(params.ticketId)
      .then((res) => {
        setTicketData(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
        goBack();
      });
  };

  const goBack = () => {
    const thereIsAPrevPage = location.key !== "default";
    if (thereIsAPrevPage) {
      navigate(-1);
    } else {
      navigate("/management/tickets");
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const closeNewPrice = () => {
    setOpenNewPrice(false);
    setPriceData({
      stripePriceId: "",
      active: false,
      currency: "usd",
      unitCost: 0,
      activeFrom: "",
      activeTo: "",
      changedFrom: "",
      changedTo: "",
      addedBy: "",
      removedBy: "",
    });
    setActiveVal(false);
    setDateVal(null);
    setPriceVal(0);
  };

  const submitNewPrice = () => {
    if (!activeVal && !dateVal) {
      toast.error("Please select an active date for this price");
    } else {
      let data = {
        active: activeVal,
        unitCost: priceVal,
        activeFrom: dateVal,
      };
      if (activeVal) {
        data.activeFrom = null;
      }
      setLoading(true);
      AddPriceToATicket(params.ticketId, data)
        .then((res) => {
          toast.success("Successfully added a new price to the ticket!");
          closeNewPrice();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
          goBack();
        });
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, "day").endOf("day");
  };

  const dateChange = (date, dateString) => {
    if (date) {
      setDateVal(date.toJSON());
    } else {
      setDateVal(null);
    }
  };

  const renderNewPriceModal = () => {
    return (
      <Transition.Root show={openNewPrice} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeNewPrice}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        Add a new price
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-300">Add a price to the {ticketData.title} ticket</p>
                      </div>
                      <div className="flex flex-col items-start justify-start w-full gap-2">
                        <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                          Price <span className="text-red-500">*</span>
                        </label>
                        <InputNumber placeholder="Ticket Price" className="w-full" step={0.01} min={0.01} onChange={(e) => setPriceVal(e)} prefix="$" />
                        <div className="flex flex-row items-center justify-between w-full mt-5">
                          <label htmlFor="description" className="block pl-1 text-sm text-white/90">
                            Replace the current price with this one?
                          </label>
                          <Switch className="bg-slate-800" onChange={setActiveVal} />
                        </div>
                        <div className="flex flex-row items-center justify-between w-full mt-5">
                          <label htmlFor="description" className="pl-1 text-sm text-white/90">
                            When should this price be active?
                          </label>
                          <DatePicker
                            disabledDate={disabledDate}
                            className="px-6 py-2"
                            value={dateVal ? dayjs(dateVal) : null}
                            disabled={activeVal}
                            onChange={dateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => closeNewPrice()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => submitNewPrice()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
                    >
                      Create
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const openEditPrice = (price) => {
    setPriceData(price);
    setTimeout(() => setOpenPrice(true), 700);
  };

  const closeEditPrice = () => {
    setOpenPrice(false);
    setPriceData({
      stripePriceId: "",
      active: false,
      currency: "usd",
      unitCost: 0,
      activeFrom: "",
      activeTo: "",
      changedFrom: "",
      changedTo: "",
      addedBy: "",
      removedBy: "",
    });
    setActiveVal(false);
    setDateVal(null);
    setPriceVal(0);
  };

  const isPickerDisabled = (data) => {
    let activeFrom = data.activeFrom ? dayjs(data.activeFrom).isValid() && dayjs(data.activeFrom).isAfter(dayjs()) : false;

    let activeTo = data.activeTo ? dayjs(data.activeTo).isValid() : false;

    let priceActive = data.active;

    return !activeFrom + activeTo + priceActive;
  };

  const savePriceChanges = () => {
    if (!activeVal && !dateVal) {
      toast.success("No changes were made to the price");
      closeEditPrice();
    } else {
      setLoading(true);
      UpdateTicketPrice(params.ticketId, priceData.stripePriceId.id, { active: false, activeFrom: dateVal })
        .then((res) => {
          toast.success("Successfully updated the price!");
          closeEditPrice();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the price, please try again.");
          setLoading(false);
        });
    }
  };

  const activatePriceNow = () => {
    let toSend = {
      stripePriceId: priceData.stripePriceId,
      active: true,
    };
    setLoading(true);
    UpdateTicketPrice(params.ticketId, priceData.stripePriceId.id, toSend)
      .then((res) => {
        toast.success("Successfully activated the price!");
        closeEditPrice();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the price, please try again.");
        setLoading(false);
      });
  };

  const renderEditPriceDrawer = () => {
    return (
      <Drawer title="View / Edit Price" placement="right" closable={true} onClose={closeEditPrice} open={openPrice} width={500} className="text-white">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <dl className="w-full divide-y divide-white/10">
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Price Active</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">{priceData.active ? "Yes" : "No"}</dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Price</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">{formatCurrency(priceData.unitCost)}</dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Changed From</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.changedFrom && priceData.changedFrom.length > 10 ? priceData.changedFrom : "N/A"}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Replaced by</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.changedTo && priceData.changedTo.length > 10 ? priceData.changedTo : "N/A"}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Active From</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.activeFrom && dayjs(priceData.activeFrom).isValid() ? dayjs(priceData.activeFrom).format("MM/DD/YYYY") : "N/A"}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Active To</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.activeTo && dayjs(priceData.activeTo).isValid() ? dayjs(priceData.activeTo).format("MM/DD/YYYY") : "N/A"}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Added By</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.addedBy && priceData.addedBy.length > 5 ? priceData.addedBy.split(" | ")[0] : "N/A"}
              </dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Removed By</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.removedBy && priceData.removedBy.length > 5 ? priceData.removedBy.split(" | ")[0] : "N/A"}
              </dd>
            </div>
          </dl>
          <p className="w-full py-4 text-lg font-medium border-y border-white/10">Make Changes to This Price</p>
          <div className="flex flex-col items-start justify-start flex-grow w-full gap-2 mt-4">
            <div className="grid items-center justify-between w-full grid-cols-2 gap-y-5 gap-x-5">
              {!priceData.active && (
                <>
                  <p>Active</p>
                  <button className="w-full py-2.5 border border-white/30 text-white rounded-md bg-black text-sm" onClick={() => activatePriceNow()}>
                    Activate Now
                  </button>
                </>
              )}
              <p>Active From</p>
              <DatePicker
                disabledDate={disabledDate}
                className="px-6 py-2"
                defaultValue={priceData.activeFrom ? dayjs(priceData.activeFrom) : null}
                disabled={isPickerDisabled(priceData)}
                onChange={dateChange}
              />
              {isPickerDisabled(priceData) ? (
                <p className="w-full col-span-2 text-xs text-right text-white/20">
                  Since this price is active already or has already been active, you cannot make changes to it. Create a new price or activate a different one
                  in order to update the ticket price or schedule a price change.
                </p>
              ) : null}
            </div>
          </div>
          <button className="w-full py-2.5 border border-white/30 text-white rounded-md bg-black text-sm" onClick={() => savePriceChanges()}>
            Save Changes
          </button>
        </div>
      </Drawer>
    );
  };

  const closeEditTitle = () => {
    setEditTitle(false);
    setNewTitle("");
  };

  const submitNewTitle = () => {
    if (newTitle.length < 3) {
      toast.error("Please enter a valid title for the ticket");
    } else {
      setLoading(true);
      UpdateTicketInfo(params.ticketId, { title: newTitle })
        .then((res) => {
          toast.success("Successfully updated the ticket title!");
          closeEditTitle();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket title, please try again.");
          setLoading(false);
        });
    }
  };

  const renderEditTitleModal = () => {
    return (
      <Transition.Root show={editTitle} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditTitle}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        Update Ticket Title
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
                        <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                          New Title <span className="text-red-500">*</span>
                        </label>
                        <Input placeholder="Title" className="w-full" defaultValue={ticketData.title} onChange={(e) => setNewTitle(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => closeEditTitle()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => submitNewTitle()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
                    >
                      Update
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeEditDescription = () => {
    setEditDescription(false);
    setNewDescription("");
  };

  const submitNewDescription = () => {
    if (newDescription.length < 3) {
      toast.error("Please enter a valid description for the ticket");
    } else {
      setLoading(true);
      UpdateTicketInfo(params.ticketId, { description: newDescription })
        .then((res) => {
          toast.success("Successfully updated the ticket description!");
          closeEditDescription();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket description, please try again.");
          setLoading(false);
        });
    }
  };

  const renderEditDescriptionModal = () => {
    return (
      <Transition.Root show={editDescription} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeEditDescription}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        Update Ticket Description
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
                        <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                          New Description <span className="text-red-500">*</span>
                        </label>
                        <Input
                          placeholder="Description"
                          defaultValue={ticketData.description}
                          className="w-full"
                          onChange={(e) => setNewDescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => closeEditDescription()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => submitNewDescription()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
                    >
                      Create
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const changeActiveVal = (val) => {
    setActiveVal(val);
    setActiveConfirm(true);
  };

  const closeActiveConfirm = () => {
    setActiveVal(ticketData.live);
    setActiveConfirm(false);
  };

  const submitChangeActiveStatus = () => {
    setLoading(true);
    UpdateTicketInfo(params.ticketId, { live: activeVal })
      .then((res) => {
        toast.success("Successfully updated the ticket status!");
        closeActiveConfirm();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket status, please try again.");
        setLoading(false);
      });
  };

  const renderEditLiveStatusModal = () => {
    return (
      <Transition.Root show={activeConfirm} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeActiveConfirm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        {!activeVal ? "Disable Ticket" : "Enable Ticket"}
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
                        <p className="block text-sm text-white/90">
                          {!activeVal
                            ? "Are you sure you want to disable this ticket. This will immediately make it unavailable for purchasing and prevent checkouts for users that already have this ticket in their shopping cart."
                            : "Enabling this ticket will make it immediately available for purchase and checkout on all sales channels."}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => closeActiveConfirm()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => submitChangeActiveStatus()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
                    >
                      {!activeVal ? "Disable Ticket" : "Enable Ticket"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeLinkSettings = () => {
    setLinkSettings(false);
    setShortSlug("");
  };

  const submitLinkSettings = async () => {
    if (shortSlug.length > 3) {
      setLoading(true);
      HyprTK.checkSlug(shortSlug)
        .then((res) => {
          HyprTK.createLink({ slug: shortSlug, url: `${process.env.REACT_APP_HOST}/tickets/${ticketData.ticketId}` })
            .then((res) => {
              UpdateTicketInfo(params.ticketId, { shortSlug: shortSlug })
                .then((res) => {
                  toast.success("Successfully updated the ticket link!");
                  closeLinkSettings();
                  reloadData();
                })
                .catch((err) => {
                  toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket link, please try again.");
                  setLoading(false);
                });
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket link, please try again.");
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the ticket link, please try again.");
          setLoading(false);
        });
    } else {
      toast.error("Please enter a valid slug for the ticket");
    }
  };

  const renderLinkSettings = () => {
    return (
      <Transition.Root show={linkSettings} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeLinkSettings}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        Ticket Short Link Settings
                      </Dialog.Title>
                      <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
                        <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                          Edit the slug in the short link to the ticket
                        </label>
                        <Input
                          prefix={"https://hyprtk.co/"}
                          placeholder="Short Ticket URL Slug"
                          defaultValue={ticketData.shortSlug}
                          className="w-full"
                          onChange={(e) => setShortSlug(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => closeLinkSettings()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => submitLinkSettings()}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
                    >
                      Update
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const copyLinkToTicket = () => {
    if (ticketData.shortSlug && ticketData.shortSlug.length > 3) {
      navigator.clipboard.writeText("https://hyprtk.co/" + ticketData.shortSlug);
    } else {
      navigator.clipboard.writeText(process.env.REACT_APP_HOST + "/tickets/" + ticketData.ticketId);
    }
    toast.success("Successfully copied the link to the ticket!");
  };

  const renderQrSettings = () => {
    return (
      <Transition.Root show={qrSettings} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setQrSettings(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left text-white transition-all transform bg-black border border-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6">
                        QR Code Settings
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-center w-full gap-2 mt-4">
                        <div className="flex items-center justify-center w-full mt-3">
                          <QRCodeViewer
                            url={
                              ticketData.shortSlug && ticketData.shortSlug.length > 3
                                ? "https://hyprtk.co/" + ticketData.shortSlug
                                : process.env.REACT_APP_HOST + "/tickets/" + ticketData.ticketId
                            }
                            title={ticketData.title}
                            type="Ticket"
                          />
                        </div>
                        <p className="text-sm text-white/60">Click on the QR code in order to download it</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
                    <button
                      onClick={() => setQrSettings(false)}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full min-h-screen gap-3 px-4 pt-6 overflow-y-auto text-white bg-black overflow-x-clip">
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <h1 className="text-xl font-medium">Ticket Information {!loading && " | " + ticketData.title}</h1>
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="animate-pulse">Retrieving data...</p>
        </div>
      ) : (
        <>
          <div className="grid w-full grid-cols-2 gap-4 pb-5 h-fit gap-x-10 max-w-content">
            <dl className="h-full divide-y divide-white/10">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Title</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>{ticketData.title}</p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setEditTitle(true)}
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Description</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>{ticketData.description}</p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setEditDescription(true)}
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Active</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <Switch checked={ticketData.live} className="bg-slate-800" onClick={(e) => changeActiveVal(e)} />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Admission Ticket</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {ticketData.live ? "Yes" : "No"}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Ticket Price</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {formatCurrency(ticketData.stripePriceId.unit_amount)}
                </dd>
              </div>
              <div className="flex flex-row items-center justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Ticket Link</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => copyLinkToTicket()}
                  >
                    <Copy className="w-4 h-4" /> Copy Link
                  </button>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setLinkSettings(true)}
                  >
                    <Link className="w-4 h-4" /> Link Settings
                  </button>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setQrSettings(true)}
                  >
                    <QrCode className="w-4 h-4" /> QR Code
                  </button>
                </dd>
              </div>
            </dl>
            <div className="flex flex-col items-start justify-start w-full h-full">
              <p className="w-full pb-3 text-lg font-medium border-b border-white/10">Price History</p>
              <ul className="flex-grow w-full overflow-y-auto divide-y divide-white/5">
                {ticketData.stripePriceHistory.map((price) => (
                  <li key={uuidv4()} className="relative flex items-center py-4 space-x-4">
                    <div className="flex-auto min-w-0">
                      <div className="flex items-center gap-x-3">
                        <div
                          className={classNames(
                            price.stripePriceId.id === ticketData.stripePriceId.id
                              ? "text-green-400 bg-green-400/10 ring-green-600"
                              : "text-gray-500 bg-gray-100/10 ring-gray-500",
                            "flex-none rounded-full p-1"
                          )}
                        >
                          <div className="w-2 h-2 bg-current rounded-full" />
                        </div>
                        <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                          <p className="flex gap-x-2">
                            <span className="truncate">{formatCurrency(price.unitCost)}</span>
                          </p>
                        </h2>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-row justify-start items-center gap-x-1.5">
                            <span className="text-gray-400">Active from</span>
                            <span className="text-gray-200 truncate">{dayjs(price.activeFrom).format("MM/DD/YYYY")}</span>
                            {price.activeTo && <span className="text-gray-400">to</span>}
                            {price.activeTo && <span className="text-gray-200">{dayjs(price.activeTo).format("MM/DD/YYYY")}</span>}
                          </div>
                          <div className="flex flex-row justify-start items-center gap-x-2.5">
                            <p className="whitespace-nowrap">Added By {price.addedBy.split(" | ")[0]}</p>
                            {price.removedBy && price.removedBy.length > 2 && (
                              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                            )}
                            {price.removedBy && price.removedBy.length > 2 && <p className="whitespace-nowrap">Removed By {price.removedBy.split(" | ")[0]}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        price.stripePriceId.id === ticketData.stripePriceId.id
                          ? "text-green-400 bg-green-500/10 ring-green-600/20"
                          : "text-gray-500 bg-gray-600/10 ring-gray-500/20",
                        "rounded-md flex-none py-1.5 px-4 text-xs ring-1 ring-inset"
                      )}
                    >
                      {price.stripePriceId.id === ticketData.stripePriceId.id ? "Current" : "Inactive"}
                    </div>
                    <ArrowRight
                      className="w-8 h-8 text-black cursor-pointer bg-gray-400/20 hover:bg-white transition-all rounded-full p-1.5"
                      onClick={() => openEditPrice(price)}
                    />
                  </li>
                ))}
              </ul>
              <div className="flex flex-row items-center justify-end w-full">
                <button
                  onClick={() => setOpenNewPrice(true)}
                  className="inline-flex items-center justify-center gap-2 px-4 py-2 text-xs text-gray-300 transition-all bg-transparent border rounded-md hover:bg-gray-950 hover:text-white border-white/10"
                >
                  Add a Price
                </button>
              </div>
            </div>
            {renderNewPriceModal()}
            {renderEditPriceDrawer()}
            {renderEditTitleModal()}
            {renderEditDescriptionModal()}
            {renderEditLiveStatusModal()}
            {renderLinkSettings()}
            {renderQrSettings()}
          </div>
        </>
      )}
    </div>
  );
};

export default OpenTicket;
