import { useEffect, useState } from "react";
import Footer from "../../components/footer";
import { GetAllEvents } from "../../actions/data";
import toast from "react-hot-toast";
import _ from "lodash";
import { Skeleton } from "antd";
import LowerLevelImg from "../../assets/amhLowerLevel.jpg";
import SEOComponent from "../../components/seoComponent";
import { EVENT_CATEGORIES } from "../../lib/eventEnums";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Events = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllEvents()
        .then((res) => {
          setEvents(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving events, please try again.");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getEventStatusClasses = (category) => {
    let data = EVENT_CATEGORIES.find((c) => c.value === category);
    if (data) {
      function increaseTailwindColorIntensity(colorClass) {
        const parts = colorClass.split("-");
        if (parts.length === 2) {
          parts.unshift("bg");
        }
        const intensity = parseInt(parts[2], 10);
        const newIntensity = Math.min(intensity + 100, 900);
        const newColorClass = `${parts[0]}-${parts[1]}-${newIntensity}`;
        return newColorClass;
      }
      return `text-${data.color} bg-${increaseTailwindColorIntensity(data.color)}/10 ring-${data.color}/20`;
    } else {
      return `text-gray-500 bg-gray-600/10 ring-gray-500/20`;
    }
  };

  const renderEventDate = (eventData) => {
    const { startDate, endDate, eventType, showTime } = eventData;
    const isSingleDay = eventType === "single-day" ?? true;

    let renderTime = "";
    if (showTime && showTime.length > 0) {
      if (showTime.length === 2) {
        renderTime = ` - From ${dayjs(startDate).format("hh:mm A")} to ${dayjs(endDate).format("hh:mm A")}`;
      } else if (showTime.length === 1) {
        if (showTime.includes("startTime")) {
          renderTime = ` - Starts at ${dayjs(startDate).format("hh:mm A")}`;
        } else if (showTime.includes("endTime")) {
          renderTime = ` - Ends at ${dayjs(startDate).format("hh:mm A")}`;
        }
      }
    }

    return isSingleDay
      ? `${dayjs(startDate).format("MMMM Do, YYYY")} ${renderTime}`
      : `${dayjs(startDate).format("MMMM Do, YYYY")} - ${dayjs(endDate).format("MMMM Do, YYYY")}`;
  };

  const renderEvent = (event, index) => {
    return (
      <div className="flex items-center justify-start w-full gap-3 p-4 rounded-lg shadow-lg shadow-gray-300" key={index}>
        <img src={event.images.find((i) => i.isPrimary).previewUrl} alt={event.title} className="object-cover w-auto h-64 rounded-lg" />
        <div className="flex flex-col items-start justify-center flex-1 w-full h-full gap-2 px-4 py-8">
          <div className="flex items-center justify-start w-full gap-2">
            <div className={classNames(getEventStatusClasses(event.status), "rounded-md flex-none py-1 px-2 text-xs ring-1 ring-inset capitalize")}>
              Category: {EVENT_CATEGORIES.find((c) => c.value === event.category).label}
            </div>
            <div className={classNames(getEventStatusClasses(event.status), "rounded-md flex-none py-1 px-2 text-xs ring-1 ring-inset capitalize")}>
              {renderEventDate(event)}
            </div>
            {event.hasTickets && (
              <div className={classNames(getEventStatusClasses("tech"), "rounded-md flex-none py-1 px-2 text-xs ring-1 ring-inset capitalize")}>
                Tickets Available
              </div>
            )}
          </div>
          <p className="text-2xl font-bold">{event.title}</p>
          <p className="font-medium text-md text-gray-900/70">{event.summary}</p>
          <div className="flex items-center justify-end w-full mt-auto">
            <button
              className="inline-block py-2 px-4 text-center text-white font-bold rounded-sm bg-[#061538] bg-gradient-to-r from-[#061538] via-[#1E2E52] to-[#2A3F71] bg-size-200 bg-pos-0 hover:bg-pos-100 transition-all ease-in-out duration-200"
              onClick={() => navigate(event.slug & (event.slug.length > 0) ? event.slug : event.eventId)}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full overflow-y-auto bg-white">
      <SEOComponent title="Events" description="Dive into memorable educational experiences about history!" />
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-16 mt-6 mb-10 max-w-content">
        <section className="flex flex-col items-start justify-start w-full">
          <h3 className="text-5xl font-medium tracking-wide font-display">Events</h3>
          <h3 className="text-sm font-bold text-[#002AA8] uppercase">Dive into memorable educational experiences about history!</h3>
          <div className="grid items-center justify-between w-full grid-cols-2 gap-4 mt-10 h-fit">
            <div className="flex flex-col items-start justify-center w-full h-full gap-3">
              <div className="flex flex-row items-center justify-start gap-3 mb-4">
                <h3 className="text-4xl font-bold">Join us for memorable and educational experiences!</h3>
              </div>
              <p className="text-sm tracking-wide">
                The Museum is pleased to present a variety of annual events throughout the year, such as the Jimmie Dyess Symposium, Night at the Museum, The
                Great Building Showdown: Building History Brick by Brick, Holiday Gingerbread Village, and All Aboard! The Museum Express.
              </p>
            </div>
            <div className="flex flex-col items-end justify-start w-full h-full">
              <img src={LowerLevelImg} alt="Lower Level" className="object-cover w-full h-full" />
            </div>
          </div>
        </section>
        <section className="grid w-full grid-cols-1 divide-y divide-gray-600/40">
          {loading ? (
            <Skeleton paragraph={{ rows: 4, width: "100%" }} title={false} active />
          ) : events.length > 0 ? (
            events.map((event, index) => renderEvent(event, index))
          ) : (
            <div className="flex items-center justify-center w-full py-4">
              <p className="text-xl font-semibold text-black/60">No events found. Please come back later!</p>
            </div>
          )}
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Events;
