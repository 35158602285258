const EVENT_CATEGORIES = [
  { label: "Food and Drink", value: "foodAndDrink", color: "red-500" },
  { label: "Social", value: "social", color: "pink-500" },
  { label: "Music", value: "music", color: "blue-500" },
  { label: "Arts & Crafts", value: "artsAndCrafts", color: "yellow-500" },
  { label: "Sports", value: "sports", color: "green-500" },
  { label: "Comedy", value: "comedy", color: "orange-500" },
  { label: "Film", value: "film", color: "purple-500" },
  { label: "Performance", value: "performance", color: "pink-300" },
  { label: "Fashion", value: "fashion", color: "pink-600" },
  { label: "Gallery", value: "gallery", color: "teal-500" },
  { label: "Tech", value: "tech", color: "blue-600" },
  { label: "Business", value: "business", color: "green-600" },
  { label: "Workshop", value: "workshop", color: "orange-600" },
  { label: "Conference", value: "conference", color: "red-800" },
  { label: "Educational", value: "educational", color: "blue-700" },
  { label: "Other", value: "other", color: "gray-500" },
];

const EVENT_VISIBILITY = [
  { label: "Public", value: "public" },
  { label: "Invite Only", value: "inviteOnly" },
  { label: "Volunteers Only", value: "volunteersOnly" },
  { label: "Members Only", value: "membersOnly" },
  { label: "Donators Only", value: "donatorsOnly" },
];

module.exports = { EVENT_CATEGORIES, EVENT_VISIBILITY };
