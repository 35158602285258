import { useEffect, useState, useRef, Fragment } from "react";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { GetOrderDetails } from "../../actions/data";
import dayjs from "dayjs";
import { formatCurrency, GenerateQRURI } from "../../components/utilities";
import { Player } from "@lottiefiles/react-lottie-player";
import MuseumAnimation from "../../assets/museum.json";
import jsPDF from "jspdf";
import amhLogo from "../../assets/amh_logo.png";
import coracleLogo from "../../assets/coracle.png";
import QRGenerator from "../../components/qrGenerator";
import { PDFDocument, TextAlignment } from "pdf-lib";
import SEOComponent from "../../components/seoComponent";

const CheckoutSuccess = () => {
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [tickets, setTickets] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const containerRef = useRef(null);

  const params = useParams();

  useEffect(() => {
    let inView = true;

    if (inView) {
      if (params && params.orderId && params.orderId !== "") {
        setOrderId(params.orderId);
        GetOrderDetails(params.orderId)
          .then((res) => {
            setOrderDetails(res.data);
            let totalTickets = [];
            for (let i = 0; i < res.data.lineItems.length; i++) {
              let el = res.data.lineItems[i];
              if (el.itemType === "ticket" || el.itemType === "admission") {
                for (let j = 0; j < el.tickets.length; j++) {
                  totalTickets.push(el.tickets[j]);
                }
              }
            }
            setTickets(totalTickets);
            setTimeout(() => setLoading(false), 500);
          })
          .catch((err) => {
            toast.error("Invalid order id.");
            goBack();
          });
      } else {
        toast.error("Invalid order id.");
        goBack();
      }
    }

    return () => {
      inView = false;
    };
  }, []);

  const goBack = () => {
    const thereIsAPrevPage = location.key !== "default";
    if (thereIsAPrevPage) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const renderLineItem = (item) => {
    if (item.itemType === "ticket" || item.itemType === "admission") {
      return (
        <div className="flex flex-row items-center justify-between w-full gap-1 px-10 py-5 bg-gray-50">
          <div className="flex flex-col items-start justify-center w-2/3 gap-1">
            <p className="text-sm font-semibold text-gray-800 uppercase">{item.title ? item.title : item.name}</p>
            <p className="text-xs font-medium text-gray-400 uppercase">Price - {formatCurrency(item.price)}</p>
            <p className="text-xs font-medium text-gray-400 uppercase">Quantity - {item.quantity}</p>
          </div>
          <div className="flex flex-col items-end justify-end w-1/3 gap-1">
            <p className="text-sm font-semibold text-gray-800 uppercase">{formatCurrency(item.price * item.quantity)}</p>
          </div>
        </div>
      );
    } else if (item.itemType === "donation") {
      return (
        <div className="flex flex-row items-center justify-between w-full gap-1 px-10 py-5 bg-gray-50">
          <div className="flex flex-col items-start justify-center w-2/3 gap-1">
            <p className="text-sm font-semibold text-gray-800 uppercase">One-Time Donation</p>
            <p className="text-xs font-medium text-gray-400 uppercase">Amount - {formatCurrency(item.price)}</p>
          </div>
          <div className="flex flex-col items-end justify-end w-1/3 gap-1">
            <p className="text-sm font-semibold text-gray-800 uppercase">{formatCurrency(item.price * item.quantity)}</p>
          </div>
        </div>
      );
    } else if (item.itemType === "membership") {
      return (
        <div className="flex flex-row items-center justify-between w-full gap-1 px-10 py-5 bg-gray-50">
          <div className="flex flex-col items-start justify-center w-2/3 gap-1">
            <p className="text-sm font-semibold text-gray-800 uppercase">{item.title}</p>
            <p className="text-xs font-medium text-gray-400 uppercase">Amount - {formatCurrency(item.price)}</p>
          </div>
          <div className="flex flex-col items-end justify-end w-1/3 gap-1">
            <p className="text-sm font-semibold text-gray-800 uppercase">{formatCurrency(item.price)}</p>
          </div>
        </div>
      );
    }
  };

  const printReceipt = () => {
    const content = containerRef.current;
    if (content) {
      const doc = new jsPDF("p", "pt", "letter");
      doc.addImage(amhLogo, "PNG", 50, 30, 219.92, 20.86, "AMH Logo", "NONE", 0);
      let pageWidth = doc.internal.pageSize.getWidth();
      let logoX = pageWidth - 50 - 46.79;
      doc.addImage(coracleLogo, "PNG", logoX, 34.58, 46.79, 11.69, "Coracle Logo", "NONE", 0);
      doc.setFontSize(14);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#3d4044");
      doc.text("ORDER RECEIPT", pageWidth / 2, 80, {
        align: "center",
      });
      doc.html(content, {
        callback: function (doc) {
          doc.setProperties({
            title: `Order Receipt- ${process.env.REACT_APP_INSTITUTION_NAME}`,
            subject: `Order Receipt- ${process.env.REACT_APP_INSTITUTION_NAME}`,
            author: "Coracle | getcoracle.com",
            keywords: "",
            creator: "contact@getcoracle.com",
          });
          window.open(doc.output("bloburl"), "_blank");
        },
        width: 515, // <- here
        windowWidth: 768, // <- here
        x: 50,
        y: 100,
      });
    }
  };

  const blobToDataUrl = async (blob) => {
    return new Promise((r) => {
      let a = new FileReader();
      a.onload = r;
      a.readAsDataURL(blob);
    }).then((e) => e.target.result);
  };

  const printTicket = async (ticket) => {
    let qr = await GenerateQRURI(ticket.ticketId);
    let dataUri = await blobToDataUrl(qr);
    const url = "https://s3.us-east-1.wasabisys.com/hypercdn/ticket.pdf";
    const arrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
    const doc = await PDFDocument.load(arrayBuffer);
    const form = doc.getForm();
    let titleField = form.getTextField("title");
    let titleRightField = form.getTextField("rightTitle");
    let ticketIdField = form.getTextField("ticketId");
    let titleFormatted = getTitle(ticket, true);
    titleField.setText(titleFormatted);
    titleField.setFontSize(10);
    titleField.enableReadOnly();
    titleField.setAlignment(TextAlignment.Center);
    titleRightField.setText(titleFormatted);
    titleRightField.setFontSize(8);
    titleRightField.enableReadOnly();
    titleRightField.setAlignment(TextAlignment.Center);
    ticketIdField.setText("#" + ticket.ticketId.split("-")[ticket.ticketId.split("-").length - 1].toUpperCase());
    ticketIdField.setFontSize(6);
    ticketIdField.enableReadOnly();
    ticketIdField.setAlignment(TextAlignment.Center);
    form.flatten();
    const pngImage = await doc.embedPng(dataUri);
    let page = doc.getPage(0);
    const pngDims = pngImage.scaleToFit(88.8, 88.8);
    const { width, height } = page.getSize();
    page.drawImage(pngImage, {
      x: 442,
      y: height - 185,
      width: pngDims.width,
      height: pngDims.height,
    });
    doc.setAuthor("Coracle | getcoracle.com");
    doc.setTitle("Ticket Printout | " + ticket.title);
    doc.setSubject("Ticket Printout");
    doc.setCreator("Coracle | getcoracle.com");
    doc.setCreationDate(new Date());

    let saved = await doc.save();
    const file = new Blob([saved], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL, "_blank");
  };

  const printAllTickets = async () => {
    let finalFile = await PDFDocument.create();
    const url = "https://s3.us-east-1.wasabisys.com/hypercdn/ticket.pdf";
    const arrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
    for (let i = 0; i < tickets.length; i++) {
      let ticket = tickets[i];
      let qr = await GenerateQRURI(ticket.ticketId);
      let dataUri = await blobToDataUrl(qr);
      const doc = await PDFDocument.load(arrayBuffer);
      const form = doc.getForm();
      let titleField = form.getTextField("title");
      let titleRightField = form.getTextField("rightTitle");
      let ticketIdField = form.getTextField("ticketId");
      let titleFormatted = getTitle(ticket, true);
      titleField.setText(titleFormatted);
      titleField.setFontSize(10);
      titleField.enableReadOnly();
      titleField.setAlignment(TextAlignment.Center);
      titleRightField.setText(titleFormatted);
      titleRightField.setFontSize(8);
      titleRightField.enableReadOnly();
      titleRightField.setAlignment(TextAlignment.Center);
      ticketIdField.setText("#" + ticket.ticketId.split("-")[ticket.ticketId.split("-").length - 1].toUpperCase());
      ticketIdField.setFontSize(6);
      ticketIdField.enableReadOnly();
      ticketIdField.setAlignment(TextAlignment.Center);
      form.flatten();
      const pngImage = await doc.embedPng(dataUri);
      let page = doc.getPage(0);
      const pngDims = pngImage.scaleToFit(88.8, 88.8);
      page.drawImage(pngImage, {
        x: 442,
        y: 607,
        width: pngDims.width,
        height: pngDims.height,
      });
      let pg1 = await finalFile.copyPages(doc, [0]);
      finalFile.addPage(pg1[0]);
    }
    finalFile.setAuthor("Coracle | getcoracle.com");
    finalFile.setTitle("Tickets Printout | " + orderDetails.name);
    finalFile.setSubject("Tickets Printout");
    finalFile.setCreator("Coracle | getcoracle.com");
    finalFile.setCreationDate(new Date());

    let saved = await finalFile.save();
    const file = new Blob([saved], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL, "_blank");
  };

  const getTitle = (ticket, smallCap) => {
    let title = ticket.title ? ticket.title : ticket.name;
    let lenCap = 48;
    if (smallCap === true) {
      lenCap = 43;
    }
    if (title.length > lenCap) {
      title = title.split(" ").splice(0, 3).join(" ").replace(":", "");
      return title.substring(0, lenCap) + "...";
    } else {
      return title;
    }
  };

  const renderTicket = (ticket) => {
    return (
      <div className="relative transition-all bg-white rounded-lg shadow-md ring-1 ring-gray-900/5 sm:mx-auto hover:scale-105 group">
        <div className="mx-auto flex h-60 w-[768px] max-w-3xl flex-row items-center justify-between gap-0 text-[#0f172a]">
          <div className="relative w-1/3 h-full bg-black rounded-l-lg">
            <img
              src="https://images.unsplash.com/photo-1587648415693-4a5362b2ce41"
              className="object-cover w-full h-full opacity-50 rounded-l-xl"
              alt="Ticket Background"
            />
            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-between px-1 text-white [writing-mode:vertical-rl]">
              <p className="text-xs transform rotate-180 opacity-0 mix-blend-soft-light">x</p>
              <img src="https://i.imgur.com/cMoZjQZ.png" className="top-0 left-0 w-24 h-auto mix-blend-exclusion" alt="AMH Logo" />
              <p className="text-xs transform rotate-180 mix-blend-soft-light">ADMIT ONE | ADMIT ONE | ADMIT ONE</p>
            </div>
            <div className="absolute z-10 flex items-center justify-center w-full h-full"></div>
          </div>
          <div className="relative grid w-2/3 h-full grid-cols-5 bg-white rounded-r-lg">
            <div className="flex flex-col items-center justify-between w-full h-full col-span-3 px-3 py-2">
              <div className="flex w-full flex-row items-center justify-between border-y border-black px-2 py-0.5">
                <p className="text-[0.6rem] uppercase leading-4">AMH</p>
                <p className="text-sm font-bold leading-5 uppercase">{getTitle(ticket)}</p>
                <p className="text-[0.6rem] uppercase leading-4">AMH</p>
              </div>
              <div className="flex flex-col items-center justify-around flex-grow w-full px-2">
                <div className="flex flex-col items-center justify-center w-full">
                  <p className="text-lg font-medium font-display">Augusta Museum of History</p>
                  <p className="text-xs font-heading">Experience Yesterday - Today!</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-col items-start justify-center w-full">
                    <p className="text-[0.6rem]">Tue-Sat - 10AM to 5PM</p>
                    <p className="text-[0.6rem]">Sun - 1PM to 5PM</p>
                    <p className="text-[0.6rem]">Mon-Wed - Closed</p>
                  </div>
                  <div className="flex flex-col items-end justify-center w-full">
                    <p className="text-[0.6rem]">(706) 722-8454</p>
                    <p className="text-[0.6rem]">hello@augusta.museum</p>
                    <p className="text-[0.6rem]">www.augustamuseum.org</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full px-2 pt-2 border-t border-black">
                <p className="text-[0.6rem] uppercase leading-4">560 Reynolds St, Augusta, GA 30901</p>
              </div>
            </div>
            <div className="relative w-full h-full col-span-2 border-l border-dashed border-spacing-x-10 border-slate-950">
              <div className="absolute left-0 top-0 flex h-full flex-row items-center justify-center px-1 text-[#0f172a]/20 [writing-mode:vertical-rl]">
                <p className="text-xs font-bold transform rotate-180">ADMIT ONE | ADMIT ONE | ADMIT ONE</p>
              </div>
              <div className="flex flex-col items-center justify-between w-full h-full py-4 pl-4 pr-2">
                <p className="text-sm font-bold uppercase">{getTitle(ticket, true)}</p>
                <QRGenerator link={ticket.ticketId} className="w-32 h-auto" size={128} />
                <p className="font-body text-[0.6rem] font-semibold uppercase tracking-wide text-[#0f172a]/70">
                  #{ticket.ticketId.split("-")[ticket.ticketId.split("-").length - 1]}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute h-60 w-[768px] top-0 left-0 rounded-lg bg-black/40 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all group-hover:backdrop-blur-sm cursor-pointer">
          <button
            className="px-4 py-2 text-xs font-bold text-black uppercase transition-all duration-300 bg-gray-200 border rounded-md border-slate-400 hover:bg-black hover:text-white"
            onClick={() => printTicket(ticket)}
          >
            Print Ticket
          </button>
        </div>
      </div>
    );
  };

  const renderPaymentMethod = () => {
    if (orderDetails.cardLast4 && orderDetails.cardBrand) {
      return (
        <>
          <span className="capitalize">{orderDetails.cardBrand}</span> ending in {orderDetails.cardLast4}
        </>
      );
    } else if (orderDetails.paypal === true) {
      return (
        <>
          <span className="font-medium">PayPal Account:</span> <span className="text-xs lowercase">{orderDetails?.paymentData?.email_address}</span>
        </>
      );
    }
  };

  const renderDonationMembershipNotice = () => {
    let donations = orderDetails.lineItems.filter((l) => l.itemType === "donation");
    let memberships = orderDetails.lineItems.filter((l) => l.itemType === "membership");
    if (memberships.length > 0 && donations.length > 0) {
      return (
        <div className="flex flex-row items-center justify-center w-full max-w-3xl gap-2 px-10 py-3">
          <p className="text-xs font-medium text-center">
            You will receive a separate email containing your donation confirmation and membership info, please look out for it. Thank you for your support!
          </p>
        </div>
      );
    } else if (donations.length > 0 && memberships.length === 0) {
      return (
        <div className="flex flex-row items-center justify-center w-full max-w-3xl gap-2 px-10 py-3">
          <p className="text-xs font-medium text-center">
            You will receive a separate confirmation for your donation, please look out for it. Thank you for your support!
          </p>
        </div>
      );
    } else if (donations.length === 0 && memberships.length > 0) {
      return (
        <div className="flex flex-row items-center justify-center w-full max-w-3xl gap-2 px-10 py-3">
          <p className="text-xs font-medium text-center">
            You will receive a separate email containing your membership info, please look out for it. Thank you for your support!
          </p>
        </div>
      );
    }
  };

  const renderFooterBanner = () => {
    if (!loading && orderDetails && orderDetails.lineItems.length > 0) {
      let memberships = orderDetails.lineItems.some((l) => l.itemType === "membership");
      if (!memberships) {
        return (
          <section className="relative w-full">
            <div className="container px-4 mx-auto">
              <div className="max-w-6xl p-6 py-12 mx-auto bg-black md:p-12">
                <div className="flex flex-wrap items-center -mx-4">
                  <div className="w-full px-4 mb-6 md:w-1/2 lg:w-4/12 md:mb-10 lg:mb-0">
                    <h2 className="text-3xl text-white sm:text-4xl font-heading">Become a Member</h2>
                  </div>
                  <div className="w-full px-4 mb-6 md:w-1/2 lg:w-5/12 md:mb-10 lg:mb-0">
                    <p className="leading-8 text-white">Enjoy unlimited free admission for you and your guest(s) on every visit.</p>
                  </div>
                  <div className="w-full px-4 text-center lg:w-3/12 lg:text-right">
                    <a
                      className="inline-block w-full md:w-auto py-3 px-8 text-center text-black font-bold bg-[#E2E3D5] hover:bg-white transition-all"
                      href={"/memberships"}
                    >
                      Join Today
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full overflow-y-auto bg-white">
      <SEOComponent title="Order Confirmation" description="Thank you for your purchase!" />
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-16 mt-6 mb-10 max-w-content">
        {!loading ? (
          <div className="flex flex-col items-center justify-start w-full max-w-6xl gap-3 px-4 py-10">
            <h1 className="font-serif text-3xl font-bold tracking-wider uppercase">Order Details</h1>
            <h2 className="font-serif text-base font-semibold tracking-wider text-slate-900/50">Thank you for your purchase!</h2>
            {/* TODO: Add logic to check if user has a membership, if not - pitch one */}
            <div className="flex flex-col items-start justify-start w-full max-w-3xl gap-2 mx-auto" ref={containerRef}>
              <div className="grid items-start justify-start w-full max-w-3xl grid-cols-2 grid-rows-3 gap-2 mt-7">
                <div className="flex flex-col items-start justify-center w-full gap-1 p-3 bg-gray-50">
                  <p className="text-xs font-medium text-gray-400 uppercase">Your Email</p>
                  <p className="text-gray-800">{orderDetails.email}</p>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-full row-span-3 gap-1 p-5 bg-gray-50">
                  <p className="text-xs font-medium text-gray-400 uppercase">Billed to</p>
                  <p className="text-sm text-gray-800">{renderPaymentMethod()}</p>
                  <p className="text-sm text-gray-800">{orderDetails.name}</p>
                  <p className="text-sm text-gray-800">{orderDetails.shippingAddress.address1}</p>
                  <p className="text-sm text-gray-800">
                    {orderDetails.shippingAddress.city}, {orderDetails.shippingAddress.stateCode} {orderDetails.shippingAddress.zip}
                  </p>
                  <p className="text-sm text-gray-800">{orderDetails.shippingAddress.countryCode}</p>
                </div>
                <div className="flex flex-col items-start justify-center w-full gap-1 p-3 bg-gray-50">
                  <p className="text-xs font-medium text-gray-400 uppercase">Purchase Date</p>
                  <p className="text-gray-800">{dayjs(orderDetails.createdAt).format("MM/DD/YYYY")}</p>
                </div>
                <div className="flex flex-col items-start justify-center w-full gap-1 p-3 bg-gray-50">
                  <p className="text-xs font-medium text-gray-400 uppercase">Order ID</p>
                  <p className="text-gray-800 uppercase">{orderId.split("-")[orderId.split("-").length - 1]}</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center w-full max-w-3xl gap-1 p-3 bg-gray-50">
                <h4 className="text-lg font-semibold text-gray-400 uppercase">Purchases</h4>
              </div>
              <div className="grid items-start justify-start w-full max-w-3xl grid-cols-1 gap-2">
                {orderDetails.lineItems.map((item) => renderLineItem(item))}
              </div>
              <div className="flex flex-row items-center justify-end w-full max-w-3xl gap-5 px-10 py-3 bg-gray-50">
                <h4 className="text-sm font-bold text-gray-800 uppercase">Total:</h4>
                <h4 className="text-sm font-bold text-gray-800 uppercase">{formatCurrency(orderDetails.total)}</h4>
              </div>
            </div>
            {renderDonationMembershipNotice()}
            <div className="flex flex-row items-center justify-center w-full max-w-3xl gap-2 px-10 py-3">
              <button
                className="px-4 py-2 text-xs font-bold text-gray-400 uppercase transition-all border border-gray-300 rounded-md hover:border-slate-950 hover:text-slate-950"
                onClick={() => printReceipt()}
              >
                Print Receipt
              </button>
              {tickets.length > 0 && (
                <button
                  className="px-4 py-2 text-xs font-bold text-gray-400 uppercase transition-all border border-gray-300 rounded-md hover:border-slate-950 hover:text-slate-950"
                  onClick={() => printAllTickets()}
                >
                  Print Tickets [{tickets.length}]
                </button>
              )}
            </div>
            <div className="flex flex-col items-center justify-start w-full max-w-4xl gap-4 py-2 mx-auto bg-white rounded-2xl">
              {tickets.map((ticket) => renderTicket(ticket))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <Player autoplay loop src={MuseumAnimation} style={{ height: "150px", width: "150px" }} />
            <p className="text-sm font-semibold tracking-wide animate-pulse">LOADING</p>
          </div>
        )}
        {renderFooterBanner()}
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutSuccess;
