const PayPalLogo = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} preserveAspectRatio="xMidYMid" viewBox="0 0 256 302">
      <path
        fill="#27346A"
        d="M217.168476 23.5070146C203.234077 7.62479651 178.045612.81575334 145.823355.81575334H52.3030619c-6.5926188 0-12.19468 4.79463186-13.2268577 11.29568656L.1364683 259.076601c-.77413327 4.869548 2.99664492 9.281275 7.93278501 9.281275H65.804612l14.5004318-91.972027-.4494967 2.880109c1.0321777-6.501055 6.5926188-11.295686 13.1769136-11.295686H120.46841c53.897988 0 96.100737-21.892156 108.428602-85.2212523.366256-1.872903.682569-3.695862.957261-5.4772009-1.55659-.8240774-1.55659-.8240774 0 0 3.67089-23.4071264-.024972-39.3392886-12.685797-53.7648042"
      />
      <path
        fill="#27346A"
        d="M102.396976 68.8395929c1.539943-.7325132 3.254689-1.1403899 5.052676-1.1403899h73.317913c8.681946 0 16.781211.566033 24.181259 1.7563669 2.122624.3412846 4.178655.7325132 6.176418 1.18201 1.997764.4411727 3.937259.9406135 5.818486 1.4899984.940613.2746925 1.864579.5577089 2.771896.8573734 3.637594 1.215306 7.025468 2.6303883 10.138649 4.2868671 3.67089-23.4154505-.024972-39.3392886-12.685797-53.7648042C203.225753 7.62479651 178.045612.81575334 145.823355.81575334H52.2947379c-6.5842948 0-12.186356 4.79463186-13.2185337 11.29568656L.1364683 259.068277c-.77413327 4.877872 2.99664492 9.281275 7.924461 9.281275H65.804612L95.8875974 77.5798073c.615977-3.9122865 3.1298291-7.1170317 6.5093786-8.7402144Z"
      />
      <path
        fill="#2790C3"
        d="M228.897012 82.7490197c-12.327865 63.3207723-54.530614 85.2212523-108.428602 85.2212523H93.0241367c-6.5842948 0-12.144736 4.794631-13.1685896 11.295686l-18.0381376 114.3553c-.6742451 4.261895 2.6220643 8.124237 6.9339034 8.124237h48.6705081c5.760217 0 10.663061-4.195303 11.562055-9.880604l.474468-2.480556 9.173063-58.134912.591005-3.213069c.898994-5.685301 5.801838-9.880604 11.562055-9.880604h7.283512c47.147214 0 84.064214-19.153556 94.852136-74.549866 4.503291-23.149082 2.172568-42.477442-9.739096-56.0539084-3.612622-4.1120627-8.099265-7.5082603-13.326746-10.2801568-.283016 1.7896629-.591005 3.6042979-.957261 5.4772009Z"
      />
      <path
        fill="#1F264F"
        d="M216.952052 72.1275783c-1.881227-.5493849-3.820722-1.0488257-5.818486-1.4899984-1.997763-.4411728-4.062118-.8324014-6.176418-1.173686-7.408372-1.1986579-15.499313-1.7646909-24.189583-1.7646909h-73.309589c-1.806311 0-3.521057.4078767-5.052676 1.1487139-3.3878735 1.6231827-5.8934016 4.8196039-6.5093786 8.7402144L80.3133678 176.385849l-.4494967 2.880109c1.0238537-6.501055 6.5842948-11.295686 13.1685896-11.295686h27.4442733c53.897988 0 96.100737-21.892156 108.428602-85.2212523.366256-1.872903.674245-3.687538.957261-5.4772009-3.121505-1.6481548-6.501055-3.0715611-10.138649-4.2785431-.907317-.2996645-1.831283-.5910049-2.771896-.8656974"
      />
    </svg>
  );
};

export default PayPalLogo;
