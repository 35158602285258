import { useEffect, useState, useRef, Fragment } from "react";
import Footer from "../../components/footer";
import { useNavigate, useOutletContext } from "react-router-dom";
import toast from "react-hot-toast";
import { formatCurrency } from "../../components/utilities";
import { useCart } from "react-use-cart";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Checkbox } from "antd";
import axios from "axios";
import { Loader } from "@googlemaps/js-api-loader";
import { Helmet } from "react-helmet-async";
import Autocomplete from "react-google-autocomplete";
import { Combobox, Transition } from "@headlessui/react";
import { statesList, countriesList } from "../../components/data";
import { ArrowSeparateVertical, Check } from "iconoir-react";
import validator from "validator";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/checkoutForm";
import { Player } from "@lottiefiles/react-lottie-player";
import MuseumAnimation from "../../assets/museum.json";
import { GetPaymentMethods, NewPaymentIntent, UpdatePaymentIntent } from "../../actions/payments";
import { v4 as uuidv4 } from "uuid";
import { MaskedInput } from "antd-mask-input";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, updateCheckout } from "../../reducers";
import EmptyCartSVG from "../../assets/emptyCart";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axiosInstance from "../../actions/axios";
import PreFooter from "../../components/preFooter";
import SEOComponent from "../../components/seoComponent";

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const testPhone = (val) => {
  return /^((\+1|1)?( |-)?)?(\(([2-9])(?:\d(?!\5)\d|(?!\5)\d\d)\)|([2-9])(?:\d(?!\6)\d|(?!\6)\d\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Checkout = () => {
  const authState = useSelector(selectAuth);
  const [loading, setLoading] = useState(true);
  const [dummyLoad, setDummyLoad] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    address: null,
    email: "",
    phone: "",
    newsletter: true,
  });
  const [ipData, setIpData] = useState(null);
  const [stateQuery, setStateQuery] = useState("");
  const [countryQuery, setCountryQuery] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [step, setStep] = useState(0);
  const [locData, setLocData] = useState({
    lat: 0,
    lng: 0,
  });
  const [methods, setMethods] = useState({ paypal: false, stripe: false });

  const dispatch = useDispatch();

  const filteredStates =
    stateQuery === ""
      ? statesList
      : statesList.filter(
          (state) =>
            state.name.toLowerCase().replace(/\s+/g, "").includes(stateQuery.toLowerCase().replace(/\s+/g, "")) ||
            state.abbreviation.toLowerCase().replace(/\s+/g, "").includes(stateQuery.toLowerCase().replace(/\s+/g, ""))
        );

  const filteredCountries =
    countryQuery === ""
      ? countriesList
      : countriesList.filter(
          (state) =>
            state.name.toLowerCase().replace(/\s+/g, "").includes(countryQuery.toLowerCase().replace(/\s+/g, "")) ||
            state.code.toLowerCase().replace(/\s+/g, "").includes(countryQuery.toLowerCase().replace(/\s+/g, ""))
        );

  const addressRef = useRef(null);

  const navigate = useNavigate();
  const [setOpenCart] = useOutletContext();

  const { items, addItem, inCart, updateItemQuantity } = useCart();

  useEffect(() => {
    let inView = true;
    if (inView) {
      loadGoogle();
      loadIPData();
      if (authState.isAuth) {
        let tmp = {
          firstName: authState.user.firstName && authState.user.firstName.length > 0 ? authState.user.firstName : "",
          lastName: authState.user.lastName && authState.user.lastName.length > 0 ? authState.user.lastName : "",
          email: authState.user.email && authState.user.email.length > 0 ? authState.user.email : "",
          phone: authState.user.phoneNumber && authState.user.phoneNumber.length > 0 ? authState.user.phoneNumber : "",
          address: authState.user.address && authState.user.address.address ? authState.user.address : null,
        };
        setValue("firstName", tmp.firstName || "");
        setValue("lastName", tmp.firstName || "");
        setValue("email", tmp.email || "");
        setValue("phone", tmp.phone || "");
        if (tmp.address) {
          if (tmp.address.address) {
            setValue("address", tmp.address.address || "");
          }
          if (tmp.address.address2) {
            setValue("address2", tmp.address.address2 || "");
          }
          if (tmp.address.city) {
            setValue("city", tmp.address.city || "");
          }
          if (tmp.address.state) {
            setValue("state", tmp.address.state || "");
          }
          if (tmp.address.zip) {
            setValue("zip", tmp.address.zip || "");
          }
          if (tmp.address.country) {
            setValue("country", tmp.address.country || "");
          }
        }
        setData(tmp);
      }
      GetPaymentMethods()
        .then((res) => {
          setMethods(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const loadGoogle = () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_KEY,
      version: "weekly",
      libraries: ["places"],
    });
    loader.importLibrary("maps");
  };

  const loadIPData = () => {
    axios
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIpData(res.data.ip);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderCartTotal = () => {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      const el = items[i];
      total += el.price * el.quantity;
    }
    return formatCurrency(total);
  };
  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    control,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
  } = formMethods;

  const onSubmit = (vals) => {
    setLoading(true);
    setData(vals);
    let toSend = vals;
    toSend.items = items;
    toSend.customerLocale = navigator.language;
    toSend.ip = ipData;
    toSend.stateCode = vals.state;
    toSend.state = statesList.find((s) => s.abbreviation === vals.state).name;
    toSend.countryCode = vals.country;
    toSend.country = countriesList.find((s) => s.code === vals.country).name;
    toSend.lat = locData.lat;
    toSend.lng = locData.lng;
    toSend.acceptLanguage = navigator.language;
    toSend.userAgent = window.navigator.userAgent;
    toSend.browserWidth = window.innerWidth;
    toSend.browserHeight = window.innerHeight;
    NewPaymentIntent(toSend)
      .then((res) => {
        setClientSecret(res.data.clientSecret);
        setOrderId(res.data.orderId);
        dispatch(updateCheckout({ checkoutSession: res.data.orderId }));
        let hasDonation = items.some((el) => el.type === "donation");
        if (hasDonation) {
          setStep(2);
        } else {
          setStep(1);
        }
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again");
        setLoading(false);
      });
  };

  const completeOrder = () => {
    if (step === 0) {
      handleSubmit(onSubmit)();
    } else if (step === 1) {
      setStep(2);
    }
  };

  const updateAddress = (place) => {
    let lat = place.geometry.location.lat();
    let lng = place.geometry.location.lng();
    setLocData({ lat, lng });
    let address = "";
    let city = "";
    let state = "";
    let zip = "";
    let country = "";
    for (let i = 0; i < place.address_components.length; i++) {
      let el = place.address_components[i];
      if (el.types.includes("street_number")) {
        address += el.long_name + " ";
      } else if (el.types.includes("route")) {
        address += el.long_name;
      } else if (el.types.includes("locality")) {
        city = el.long_name;
      } else if (el.types.includes("administrative_area_level_1")) {
        state = el.short_name;
      } else if (el.types.includes("country")) {
        country = el.short_name;
      } else if (el.types.includes("postal_code") && !el.types.includes("postal_code_suffix")) {
        zip = el.long_name;
      }
    }
    setValue("address", address);
    setValue("city", city);
    setValue("state", state);
    setValue("country", country);
    setValue("zip", zip);
  };

  const stripeOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#000000",
        colorBackground: "#ffffff",
        colorText: "#000000",
        colorDanger: "#df1b41",
        fontFamily: "manrope, sans-serif",
        spacingUnit: "4px",
        borderRadius: "4px",
        fontSizeBase: "0.95em",
        fontSmooth: "always",
        colorLogo: "light",
        spacingGridColumn: "10px",
        spacingGridRow: "11px",
      },
      rules: {
        ".Input:focus": {
          borderColor: "#000000",
          boxShadow: "none",
        },
      },
    },
  };

  const addDonation = (amount) => {
    let preAdd = [];
    for (let i = 0; i < items.length; i++) {
      const el = items[i];
      preAdd.push(el);
    }
    setDummyLoad(true);
    let toAdd = {
      id: uuidv4(),
      name: `One-Time Donation`,
      price: amount * 100,
      description: `One-Time $${amount} Donation`,
      type: "donation",
      quantity: 1,
      itemTotal: amount * 100,
      upsell: true,
    };
    addItem(toAdd);
    setTimeout(() => setDummyLoad(false), 300);
    preAdd.push(toAdd);
    UpdatePaymentIntent(preAdd, orderId)
      .then((res) => {
        toast.success("Donation added to your cart. Your gratitude is greatly appreciated!");
        setStep(2);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "Something went wrong, please try again");
        setLoading(false);
      });
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <>
          <p className="mb-4 text-2xl font-bold tracking-wide uppercase">Your Information</p>
          <FormProvider {...formMethods}>
            <div className="w-full">
              <label htmlFor="firstName" className="block w-full text-sm font-medium leading-6 text-gray-900">
                First Name
              </label>
              <div className="w-full mt-2">
                <Controller
                  control={control}
                  rules={{
                    required: "First Name is required",
                    validate: (value) => value.length >= 3 || "Please enter your first name",
                  }}
                  name="firstName"
                  defaultValue={data.firstName}
                  render={({ field: { value, onChange, ref } }) => (
                    <input
                      type="text"
                      id="firstName"
                      placeholder="First Name"
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                    />
                  )}
                />
                <ErrorMessage errors={errors} name="firstName" as="p" className="px-1 pt-1 text-sm text-red-500" />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="lastName" className="block w-full text-sm font-medium leading-6 text-gray-900">
                Last Name
              </label>
              <div className="w-full mt-2">
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  defaultValue={data.lastName}
                  {...register("lastName", {
                    required: "Last Name is required",
                    validate: (value) => value.length >= 3 || "Please enter your last name",
                  })}
                  className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                />
                <ErrorMessage errors={errors} name="lastName" as="p" className="px-1 pt-1 text-sm text-red-500" />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="address" className="block w-full text-sm font-medium leading-6 text-gray-900">
                Address
              </label>
              <div className="w-full mt-2">
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_KEY}
                  className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                  onPlaceSelected={(place, inputRef) => {
                    if (inputRef) {
                      let val = place.address_components[0].long_name + " " + place.address_components[1].long_name;
                      inputRef.value = val;
                      updateAddress(place);
                    }
                  }}
                  placeholder="Start typing your address..."
                  defaultValue={data?.address ? data?.address?.address : null}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "us" },
                  }}
                />
                <input
                  type="text"
                  id="address"
                  placeholder="Address"
                  defaultValue={data.address}
                  {...register("address", {
                    required: "Address is required",
                    validate: (value) => value.length >= 3 || "Please select your address",
                  })}
                  ref={addressRef}
                  className="hidden"
                />
                <ErrorMessage errors={errors} name="address" as="p" className="px-1 pt-1 text-sm text-red-500" />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="address2" className="block w-full text-sm font-medium leading-6 text-gray-900">
                Apt / Unit / Suite
              </label>
              <div className="w-full mt-2">
                <input
                  type="text"
                  id="address2"
                  placeholder="Apt / Unit / Suite"
                  defaultValue={data?.address ? data?.address?.address2 : null}
                  {...register("address2", {
                    required: false,
                  })}
                  className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                />
                <ErrorMessage errors={errors} name="address2" as="p" className="px-1 pt-1 text-sm text-red-500" />
              </div>
            </div>
            <div className="grid w-full grid-cols-2 grid-rows-2 gap-2">
              <div className="w-full">
                <label htmlFor="city" className="block w-full text-sm font-medium leading-6 text-gray-900">
                  City
                </label>
                <div className="w-full mt-2">
                  <input
                    type="text"
                    id="city"
                    placeholder="City"
                    defaultValue={data?.address ? data?.address?.city : null}
                    {...register("city", {
                      required: "City is required",
                      validate: (value) => value.length >= 3 || "Please enter your city",
                    })}
                    className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                  />
                  <ErrorMessage errors={errors} name="city" as="p" className="px-1 pt-1 text-sm text-red-500" />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="state" className="block w-full text-sm font-medium leading-6 text-gray-900">
                  State
                </label>
                <div className="w-full mt-2">
                  <Controller
                    control={formMethods.control}
                    defaultValue={data?.address ? data?.address?.state : null}
                    name="state"
                    rules={{
                      required: "Please select a state",
                      validate: (value) => value.length === 2 || "Please select a state",
                    }}
                    render={({ field: { value, onChange, ref } }) => (
                      <Combobox onChange={(e) => onChange(e.abbreviation)} ref={ref} value={value}>
                        <div className="relative mt-1">
                          <div className="relative w-full overflow-hidden text-left bg-white rounded-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                            <Combobox.Input
                              className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                              displayValue={(state) => (state ? statesList.find((s) => s.abbreviation === state).name : null)}
                              onChange={(event) => setStateQuery(event.target.value)}
                              defaultValue={null}
                              placeholder="State"
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setStateQuery("")}
                          >
                            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {filteredStates.length === 0 && stateQuery !== "" ? (
                                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">Nothing found.</div>
                              ) : (
                                filteredStates.map((state) => (
                                  <Combobox.Option
                                    key={state.abbreviation}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-blue-600 text-white" : "text-gray-900"}`
                                    }
                                    value={state}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{state.name}</span>
                                        {selected ? (
                                          <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-blue-600"}`}>
                                            <Check className="w-5 h-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    )}
                  />
                  <ErrorMessage errors={errors} name="state" as="p" className="px-1 pt-1 text-sm text-red-500" />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="zip" className="block w-full text-sm font-medium leading-6 text-gray-900">
                  ZIP Code
                </label>
                <div className="w-full mt-2">
                  <input
                    type="text"
                    id="zip"
                    placeholder="ZIP Code"
                    defaultValue={data?.address ? data?.address?.zip : null}
                    {...register("zip", {
                      required: "ZIP Code is required",
                      validate: (value) => testZip(value) || "Please enter your ZIP Code",
                    })}
                    className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                  />
                  <ErrorMessage errors={errors} name="zip" as="p" className="px-1 pt-1 text-sm text-red-500" />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="country" className="block w-full text-sm font-medium leading-6 text-gray-900">
                  Country
                </label>
                <div className="w-full mt-2">
                  <Controller
                    control={formMethods.control}
                    defaultValue={data?.address ? data?.address?.country : null}
                    name="country"
                    rules={{
                      required: "Please select a country",
                      validate: (value) => value.length === 2 || "Please select a country",
                    }}
                    render={({ field: { value, onChange, ref } }) => (
                      <Combobox onChange={(e) => onChange(e.code)} ref={ref} value={value}>
                        <div className="relative mt-1">
                          <div className="relative w-full overflow-hidden text-left bg-white rounded-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                            <Combobox.Input
                              className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                              displayValue={(country) => (country ? countriesList.find((s) => s.code === country).name : null)}
                              onChange={(event) => setCountryQuery(event.target.value)}
                              defaultValue={null}
                              placeholder="Country"
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setCountryQuery("")}
                          >
                            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {filteredCountries.length === 0 && countryQuery !== "" ? (
                                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">Nothing found.</div>
                              ) : (
                                filteredCountries.map((state) => (
                                  <Combobox.Option
                                    key={state.code}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-blue-600 text-white" : "text-gray-900"}`
                                    }
                                    value={state}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{state.name}</span>
                                        {selected ? (
                                          <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-blue-600"}`}>
                                            <Check className="w-5 h-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    )}
                  />
                  <ErrorMessage errors={errors} name="country" as="p" className="px-1 pt-1 text-sm text-red-500" />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="email" className="block w-full text-sm font-medium leading-6 text-gray-900">
                Email Address
              </label>
              <div className="w-full mt-2">
                <input
                  type="text"
                  id="email"
                  placeholder="Email Address"
                  defaultValue={data?.email ? data?.email : null}
                  {...register("email", {
                    required: "Email Address is required",
                    validate: (value) => validator.isEmail(value) || "Please enter your Email Address",
                  })}
                  className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                />
                <ErrorMessage errors={errors} name="email" as="p" className="px-1 pt-1 text-sm text-red-500" />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="phone" className="block w-full text-sm font-medium leading-6 text-gray-900">
                Phone Number
              </label>
              <div className="w-full mt-2">
                <Controller
                  control={formMethods.control}
                  defaultValue={data?.phone ? data?.phone : null}
                  value={data.phone}
                  name="phone"
                  rules={{
                    required: "Phone Number is required",
                    validate: (value) => validator.isMobilePhone(value) || "Please enter your Phone Number",
                  }}
                  render={({ field: { value, onChange, ref, defaultValue } }) => (
                    <MaskedInput
                      mask={"(000) 000-0000"}
                      onChange={(e) => onChange(e.unmaskedValue)}
                      ref={ref}
                      value={value}
                      defaultValue={defaultValue}
                      className="block w-full px-4 py-3 text-sm border rounded-md border-gray-600/40 focus:border-black focus:ring-0 focus:outline-none"
                    />
                  )}
                />
                <ErrorMessage errors={errors} name="phone" as="p" className="px-1 pt-1 text-sm text-red-500" />
              </div>
            </div>
            <div className="relative flex items-center w-full">
              <Controller
                control={formMethods.control}
                name="newsletter"
                defaultValue={data.newsletter}
                rules={{ required: false }}
                render={(props) => (
                  <Checkbox
                    onChange={(e) => props.field.onChange(e.target.checked)}
                    defaultChecked={data.newsletter}
                    className="z-0 w-full text-sm font-medium leading-6 text-gray-900"
                    ref={props.field.ref}
                  >
                    Yes I'd like to receive occasional emails and newsletter from the {process.env.REACT_APP_INSTITUTION_NAME}
                  </Checkbox>
                )}
              />
              <ErrorMessage errors={errors} name="newsletter" as="p" className="px-1 pt-1 text-sm text-red-500" />
            </div>
          </FormProvider>
        </>
      );
    } else if (step === 1) {
      return (
        <div className="w-full p-10 mt-5">
          <div className="w-full">
            <p className="mb-2 text-2xl font-bold tracking-wide uppercase">Support Augusta Museum of History</p>
            <p className="mb-4 font-body">
              By making a tax-deductible contribution to the Augusta Museum of History, you support the work of the Museum, which impacts thousands of visitors
              who come to learn about Augusta and the River Region, and supports the preservation of over three million artifacts and archival materials in the
              Museum's collection.
            </p>
            <div className="grid items-center grid-cols-5 gap-2 mt-6">
              <button
                onClick={() => addDonation(5)}
                className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                $5
              </button>
              <button
                onClick={() => addDonation(10)}
                className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                $10
              </button>
              <button
                onClick={() => addDonation(25)}
                className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                $25
              </button>
              <button
                onClick={() => addDonation(50)}
                className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                $50
              </button>
              <button
                onClick={() => addDonation(100)}
                className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                $100
              </button>
            </div>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <>
          <p className="mb-4 text-2xl font-bold tracking-wide uppercase">Payment Details</p>
          {methods && methods.paypal && renderPaypal()}
          {methods && methods.stripe && clientSecret && (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <CheckoutForm orderId={orderId} />
            </Elements>
          )}
        </>
      );
    }
  };

  const renderPaypal = () => {
    const initialOptions = {
      "client-id": "AW21R9Lw-vROGJ6Hby7fvxYJCP45zphbOdzeXnCXkQJuXqvlf19YQc7ESOjSfwVcYAUerr5Pc4aT9PUV",
      "enable-funding": "",
      "disable-funding": "card,credit,venmo",
      currency: "USD",
      "data-page-type": "product-details",
      components: "buttons",
      "data-sdk-integration-source": "developer-studio",
      debug: "false",
    };
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <PayPalScriptProvider options={initialOptions} className="w-full">
          <PayPalButtons
            style={{
              shape: "rect",
              layout: "vertical",
              color: "gold",
              label: "paypal",
            }}
            className="w-full"
            createOrder={async () => {
              try {
                const response = await axiosInstance.post("/paypal/order", {
                  cart: items,
                  orderId: orderId,
                });

                const orderData = await response.data;

                if (orderData.id) {
                  return orderData.id;
                } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})` : JSON.stringify(orderData);

                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
              }
            }}
            onApprove={async (data, actions) => {
              try {
                const response = await axiosInstance.post(`/paypal/order/${data.orderID}/capture`, {
                  cart: items,
                });

                const orderData = await response.data;

                const errorDetail = orderData?.details?.[0];

                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  return actions.restart();
                } else if (errorDetail) {
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                } else if (orderData.error) {
                  throw new Error(orderData.error_description);
                } else {
                  const transaction = orderData.purchase_units[0].payments.captures[0];
                  if (orderData.status === "COMPLETED" && transaction.status === "COMPLETED") {
                  }
                }
              } catch (error) {
                console.error(error);
                toast.error(`Sorry, your transaction could not be processed...\n${error}`);
              }
            }}
          />
        </PayPalScriptProvider>
      </div>
    );
  };

  const renderStepConditional = () => {
    if (items && items.length > 0) {
      return renderStep();
    } else {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full py-6">
          <EmptyCartSVG />
          <p className="mt-2 text-sm font-semibold tracking-wide">Your cart is empty, please add items to continue</p>
        </div>
      );
    }
  };

  const renderBanner = () => {
    if (items && items.length > 0) {
      if (!items.some((i) => i.type === "membership")) {
        return <PreFooter />;
      }
    }
  };

  const renderDiscounts = () => {
    let discounts = items.filter((i) => (i.studentDiscount && i.studentDiscount.eligible) || (i.militaryDiscount && i.militaryDiscount.eligible));
    let eligible = [];
    for (let i = 0; i < discounts.length; i++) {
      const el = discounts[i];
      if (el.studentDiscount && el.studentDiscount.eligible) {
        eligible.push("student");
      }
      if (el.militaryDiscount && el.militaryDiscount.eligible) {
        eligible.push("military");
      }
    }
    eligible = [...new Set(eligible)];
    if (eligible.length === 2 && step > 0) {
      return (
        <div className="flex flex-col items-center justify-center w-full gap-2 px-2 pt-3 border-t border-gray-600/30">
          <p className="text-xs font-semibold tracking-wide uppercase">Unlock a $5 student and military discount</p>
          <a
            href={`https://groups.id.me/?client_id=c1523fbfd9920cb4748c00dd506e2888&redirect_uri=${process.env.REACT_APP_HOST}/idme/callback&response_type=code&scopes=military,student`}
          >
            <img
              src="https://developers.id.me/assets/buttons/verify-32b84e457998bb752606ed89415338ec7aec19954ee1d3d4bfa002dd307ad433.svg"
              className="w-auto h-10"
            />
          </a>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full overflow-y-auto bg-white">
      <SEOComponent title="Checkout" />
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-16 mt-6 mb-10 max-w-content">
        <div className="flex flex-col items-center justify-start w-full max-w-6xl gap-1 px-4 py-10">
          <p className="font-serif text-3xl font-bold tracking-wider uppercase">Checkout</p>
          <p className="font-serif text-base font-semibold tracking-wider text-slate-900/50">
            ({items.length} item{items.length > 1 && "s"}
            {items.length === 0 && "s"}) {renderCartTotal()}
          </p>
          <div className="grid w-full grid-cols-5 gap-10">
            <div className="flex flex-col items-start justify-start col-span-3 gap-3">
              {!loading ? (
                renderStepConditional()
              ) : (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <Player autoplay loop src={MuseumAnimation} style={{ height: "150px", width: "150px" }} />
                  <p className="text-sm font-semibold tracking-wide animate-pulse">LOADING</p>
                </div>
              )}
            </div>
            <div className="flex flex-col items-start justify-start h-full col-span-2 gap-4 p-6">
              {items && items.length > 0 ? (
                <div className="top-0 flex flex-col items-start justify-start w-full col-span-2 gap-4">
                  <div className="flex flex-row items-center justify-between w-full mb-3">
                    <p className="font-serif text-base font-bold tracking-wider uppercase">Your Order</p>
                    <button className="font-serif text-base font-bold tracking-wider underline uppercase underline-offset-2" onClick={() => setOpenCart(true)}>
                      Edit
                    </button>
                  </div>
                  {items.map((el, i) => (
                    <div className="flex flex-row items-center justify-between w-full gap-3 px-2">
                      <p className="text-xs font-semibold leading-none tracking-normal uppercase font-body">
                        {el.quantity} x {el.name}
                      </p>
                      <p className="text-base font-semibold leading-none tracking-normal uppercase font-body">
                        {el.price === 0 ? "FREE" : formatCurrency(el.price * el.quantity)}
                      </p>
                    </div>
                  ))}
                  <div className="flex flex-row items-center justify-between w-full px-2 pt-3 border-t border-gray-600/30">
                    <p className="text-base font-bold leading-none tracking-normal uppercase font-body">Subtotal</p>
                    <p className="text-base font-bold leading-none tracking-normal uppercase font-body">{renderCartTotal()}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full px-2 pt-3 border-t border-gray-600/30">
                    <p className="text-base font-bold leading-none tracking-normal uppercase font-body">Order Total</p>
                    <p className="text-base font-bold leading-none tracking-normal uppercase font-body">{renderCartTotal()}</p>
                  </div>
                  {step !== 2 && (
                    <button
                      className="w-full py-3 font-semibold tracking-wide text-white uppercase transition-all bg-black border border-black font-body hover:text-black hover:bg-transparent"
                      onClick={() => completeOrder()}
                    >
                      {step === 2 ? "Complete Order" : "Next Step"}
                    </button>
                  )}
                  {step > 0 && !loading && (
                    <button
                      className="w-full py-3 font-semibold tracking-wide text-black uppercase transition-all bg-white border border-black font-body hover:text-gray-500"
                      onClick={() => setStep(step - 1)}
                    >
                      Back
                    </button>
                  )}
                  {renderDiscounts()}
                </div>
              ) : (
                <div className="top-0 flex flex-col items-start justify-center w-full h-full col-span-2 gap-4">
                  <button
                    className="w-full py-3 font-semibold tracking-wide text-white uppercase transition-all bg-black border border-black font-body hover:text-black hover:bg-transparent"
                    onClick={() => navigate("/tickets")}
                  >
                    Buy Tickets
                  </button>
                  <button
                    className="w-full py-3 font-semibold tracking-wide text-white uppercase transition-all bg-black border border-black font-body hover:text-black hover:bg-transparent"
                    onClick={() => navigate("/tickets")}
                  >
                    Become a member
                  </button>
                  <button
                    className="w-full py-3 font-semibold tracking-wide text-white uppercase transition-all bg-black border border-black font-body hover:text-black hover:bg-transparent"
                    onClick={() => navigate("/tickets")}
                  >
                    Donate to the Museum
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {renderBanner()}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
