import axiosInstance from "./axios";

export const NewPaymentIntent = (data) => {
  return axiosInstance.put("/payments/paymentIntent", data);
};

export const UpdatePaymentIntent = (data, orderId) => {
  return axiosInstance.put(`/payments/update/${orderId}`, data);
};

export const CheckPaymentIntent = (orderId) => {
  return axiosInstance.get(`/payments/status/${orderId}`);
};

export const GetPaymentMethods = () => {
  return axiosInstance.get("/payments/methods");
};

export const PayPalCreateOrder = (data) => {
  return axiosInstance.post("/paypal/order", data);
};

export const PayPalCaptureOrder = (orderId) => {
  return axiosInstance.post(`/paypal/order/${orderId}/capture`);
};
