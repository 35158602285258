import axiosInstance from "./axios";
import multiPartyInstance from "./multiPartyAxios";

export const GetAllTickets = () => {
  return axiosInstance.get("/data/tickets");
};

export const GetOrderDetails = (orderId) => {
  return axiosInstance.get(`/data/purchase/${orderId}`);
};

export const GetAllMemberships = () => {
  return axiosInstance.get("/data/memberships");
};

export const UploadData = (data) => {
  return multiPartyInstance.post("/data/upload/files", data);
};

export const GetAllEvents = () => {
  return axiosInstance.get("/data/events");
};

export const GetOneEvent = (eventId) => {
  return axiosInstance.get(`/data/event/${eventId}`);
};
