import { useState, useEffect } from "react";
import ImageUpload from "../../../../components/imageUpload";
import { GetUser, ManagementCheck } from "../../../../actions/auth";
import { Button } from "antd";
import toast from "react-hot-toast";
import { UpdateEvent } from "../../../../actions/mgmt";
import { UploadData } from "../../../../actions/data";

const EventMedia = ({ eventData, reloadData }) => {
  const [images, setImages] = useState(eventData.images.filter((img) => !img.isCanva));
  const [canvaImages, setCanvaImages] = useState(eventData.images.filter((img) => img.isCanva));
  const [canvaLoading, setCanvaLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canvaAvailable, setCanvaAvailable] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let inView = true;
    if (inView) {
      ManagementCheck()
        .then((res) => {
          GetUser()
            .then((res) => {
              setUserId(res.data.userId);
              if (res.data?.integrations?.canva?.active) {
                setCanvaAvailable(true);
              } else {
                setCanvaAvailable(false);
              }
              setLoading(false);
            })
            .catch((err) => {
              setCanvaAvailable(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          setCanvaAvailable(false);
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const submitImages = () => {
    if (images.length + canvaImages.length === 0) {
      toast.error("Please upload at least one image.");
    } else {
      let toSend = {
        images: [],
        canvaImages: [],
      };
      if (images.length === 0) {
        toSend.images = [];
        toSend.canvaImages = canvaImages;
        UpdateEvent(eventData.eventId, toSend)
          .then((res) => {
            toast.success("Images updated successfully.");
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred while updating images, please try again.");
          });
      } else {
        let toUpload = [];
        images.forEach((img) => {
          if (!img.previewUrl.includes("https://coracle.nyc3.cdn.digitaloceanspaces.com")) {
            toUpload.push(img);
          }
        });
        if (toUpload.length > 0) {
          setIsUploading(true);
          const formData = new FormData();
          toUpload.forEach((image, index) => {
            formData.append(`image${index}`, image.file, image.file.name);
            formData.append(`image${index}IsPrimary`, image.isPrimary);
            formData.append(`image${index}Id`, image.id);
          });
          formData.append("for", eventData.title);
          UploadData(formData)
            .then((res) => {
              setIsUploading(false);
              toSend.images = toUpload.map((image) => {
                const matchingResponse = res.data.find((item) => item.id === image.id);
                return {
                  ...image,
                  previewUrl: matchingResponse ? matchingResponse.url : image.previewUrl,
                };
              });
              setIsUploading(false);
              toSend.canvaImages = canvaImages;
              UpdateEvent(eventData.eventId, toSend)
                .then((res) => {
                  toast.success("Images updated successfully.");
                  reloadData();
                })
                .catch((err) => {
                  toast.error(err.response.data ? err.response.data.message : "An error occurred while updating images, please try again.");
                });
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data ? err.response.data.message : "An error occurred while uploading images, please try again.");
              setIsUploading(false);
            });
        } else {
          toSend.images = images;
          toSend.canvaImages = canvaImages;
          UpdateEvent(eventData.eventId, toSend)
            .then((res) => {
              toast.success("Images updated successfully.");
              reloadData();
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "An error occurred while updating images, please try again.");
            });
        }
      }
    }
  };

  useEffect(() => {
    let primaryUpdated = false;
    let newPrimaryExistsInDB = false;
    let imgRemoved = [];
    let canvaImgRemoved = [];

    let newImages = images.concat(canvaImages);
    let newPrimary = newImages.find((img) => img.isPrimary);
    let oldPrimary = eventData.images.find((img) => img.isPrimary);
    if (newPrimary && oldPrimary && newPrimary.previewUrl !== oldPrimary.previewUrl) {
      primaryUpdated = true;
      if (newPrimary.previewUrl.includes("https://coracle.nyc3.cdn.digitaloceanspaces.com")) {
        newPrimaryExistsInDB = true;
      }
    }
    let oldCanvaImages = eventData.images.filter((img) => img.isCanva);
    let oldImages = eventData.images.filter((img) => !img.isCanva);
    if (oldCanvaImages.length !== canvaImages.length) {
      console.log("Canva image got removed....");
      for (let i = 0; i < oldCanvaImages.length; i++) {
        if (!canvaImages.find((img) => img.canvaId === oldCanvaImages[i].canvaId)) {
          canvaImgRemoved.push(oldCanvaImages[i]);
        }
      }
    } else if (oldImages.length !== images.length) {
      console.log("Image got removed....");
      for (let i = 0; i < oldImages.length; i++) {
        if (!images.find((img) => img.previewUrl === oldImages[i].previewUrl && img.previewUrl.includes("https://coracle.nyc3.cdn.digitaloceanspaces.com"))) {
          imgRemoved.push(oldImages[i]);
        }
      }
    }
    if (primaryUpdated === true || newPrimaryExistsInDB === true || imgRemoved.length > 0 || canvaImgRemoved.length > 0) {
      console.log("REPORT: ", {
        primaryUpdated,
        newPrimaryExistsInDB,
        imgRemoved,
        canvaImgRemoved,
      });
      processImageUpdate(primaryUpdated, newPrimaryExistsInDB, imgRemoved, canvaImgRemoved, newPrimary);
    }
  }, [images, canvaImages]);

  const processImageUpdate = async (primaryUpdated, newPrimaryExistsInDB, imgRemoved, canvaImgRemoved, newPrimary) => {
    setIsUploading(true);
    let toSend = {};
    if (primaryUpdated && newPrimaryExistsInDB) {
      toSend.primaryImage = newPrimary.previewUrl;
    } else if (primaryUpdated && !newPrimaryExistsInDB) {
      try {
        const formData = new FormData();
        formData.append(`image1`, newPrimary.file, newPrimary.file.name);
        formData.append(`image1IsPrimary`, newPrimary.isPrimary);
        formData.append(`image1Id`, newPrimary.id);
        formData.append("for", eventData.title);
        let uploadedImage = await UploadData(formData);
        if (uploadedImage.status === 200) {
          toSend.primaryImage = uploadedImage.data[0].url;
          toSend.images = [{ previewUrl: uploadedImage.data[0].url, isPrimary: true }];
        } else {
          toast.error("An error occurred while uploading primary image, please try again.");
          setIsUploading(false);
          setTimeout(() => reloadData(), 700);
        }
      } catch (e) {
        toast.error("An error occurred while uploading primary image, please try again.");
        setIsUploading(false);
        setTimeout(() => reloadData(), 700);
      }
    }
    if (imgRemoved.length > 0) {
      toSend.removedImages = imgRemoved;
    }
    if (canvaImgRemoved.length > 0) {
      toSend.removedCanvaImages = canvaImgRemoved;
    }
    UpdateEvent(eventData.eventId, toSend)
      .then((res) => {
        toast.success("Images updated successfully.");
        setTimeout(() => setIsUploading(false), 700);
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating images, please try again.");
        setTimeout(() => setIsUploading(false), 700);
      });
  };

  return (
    <div className="flex flex-col items-start justify-center w-full h-full max-w-5xl gap-2 mx-auto">
      <h3 className="text-2xl font-bold">Event Media</h3>
      <p className="w-full text-sm">Add photos to show what your event will be about. You can upload up to 10 images.</p>
      {!loading && (
        <>
          <ImageUpload
            images={images}
            setImages={setImages}
            hasCanva={canvaAvailable}
            canvaImages={canvaImages}
            setCanvaImages={setCanvaImages}
            eventTitle={eventData.title}
            setCanvaLoading={setCanvaLoading}
            userId={userId}
          />
          <div className="flex items-center justify-end w-full gap-2 px-6 pt-4 border-t border-white/20">
            <Button type="primary" disabled={canvaLoading || isUploading} onClick={() => submitImages()}>
              {canvaLoading || isUploading ? "Processing..." : "Save Changes"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EventMedia;
