import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BeginPaypalOnboarding, VerifyPaypalOnboarding } from "../../../actions/mgmt";
import { ManagementCheck } from "../../../actions/auth";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { Paypal } from "iconoir-react";
import PayPalLogo from "../../../assets/payPal";
import StripeLogo from "../../../assets/stripeLogo";

const PaymentSettings = ({ authState, authDispatch, data }) => {
  const [loading, setLoading] = useState(true);
  const [dummyLoading, setDummyLoading] = useState(false);
  const [institution, setInstitution] = useState(null);
  const [paypalOnboarding, setPaypalOnboarding] = useState(0);
  const [paypalOnboardingUrl, setPaypalOnboardingUrl] = useState(null);

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    ManagementCheck()
      .then((res) => {
        setInstitution(res.data);
        let inst = res.data.payments;
        if (searchParams.get("merchantIdInPayPal")) {
          let sellerMerchantId = searchParams.get("merchantIdInPayPal");
          VerifyPaypalOnboarding({ sellerMerchantId })
            .then((res) => {
              toast.success("PayPal onboarding verified");
              setPaypalOnboarding(1);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error verifying PayPal onboarding");
              setPaypalOnboarding(0);
              if (inst.paypal.actionURL && inst.paypal.actionURL.length > 0) {
                setPaypalOnboardingUrl(inst.paypal.actionURL);
              } else {
                startPPOnboarding();
              }
            });
        } else {
          if (inst && inst.paypal && inst.paypal.active) {
            setPaypalOnboarding(1);
          } else {
            setPaypalOnboarding(0);
            if (inst.paypal.actionURL && inst.paypal.actionURL.length > 0) {
              setPaypalOnboardingUrl(inst.paypal.actionURL);
            } else {
              startPPOnboarding();
            }
          }
        }
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Error loading institution data");
      });
  }, []);

  const startPPOnboarding = () => {
    setDummyLoading(true);
    BeginPaypalOnboarding()
      .then((res) => {
        setPaypalOnboardingUrl(res.data.partnerLink);
        setTimeout(() => setDummyLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Error: PayPal onboarding");
      });
  };

  window.paypalOnboardingCallback = (authCode, sharedId) => {
    console.log({ authCode, sharedId });
  };

  const rawScriptHTML = `<div dir="ltr" style="text-align: left;" trbidi="on">
<script>
  (function(d, s, id) {
    var js, ref = d.getElementsByTagName[s](0);
    if (!d.getElementById(id)) {
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.src = "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
      ref.parentNode.insertBefore(js, ref);
    }
  }(document, "script", "paypal-js"));
</script>
<a data-paypal-button="true" href="${paypalOnboardingUrl}&displayMode=minibrowser" target="PPFrame" class="bg-[#FFC439] text-[#003087] text-sm px-4 py-2 rounded-md font-bold">Process payments with PayPal</a>
</div>`;

  const renderPaypal = () => {
    if (paypalOnboarding === 0) {
      return (
        <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
          <div className="flex items-center justify-start gap-1.5">
            <PayPalLogo className="w-7 h-7" />
            <p className="text-xl font-black">PayPal</p>
          </div>
          {paypalOnboardingUrl ? (
            <div
              dangerouslySetInnerHTML={{
                __html: rawScriptHTML,
              }}
            />
          ) : (
            <button
              className="bg-[#FFC439] hover:bg-[#ffb339] transition-all duration-200 text-[#003087] text-sm px-4 py-2 rounded-md font-bold disabled:cursor-not-allowed"
              disabled
            >
              Loading PayPal...
            </button>
          )}
        </div>
      );
    } else if (paypalOnboarding === 1) {
      return (
        <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
          <div className="flex items-center justify-start gap-1.5">
            <PayPalLogo className="w-7 h-7" />
            <p className="text-xl font-black">PayPal</p>
          </div>
          <button
            className="bg-[#FFC439] disabled:hover:bg-[#FFC439] hover:bg-[#ffb339] transition-all duration-200 text-[#003087] text-sm px-4 py-2 rounded-md font-bold disabled:cursor-default"
            disabled
          >
            Merchant ID: {institution.payments.paypal.merchantId}
          </button>
        </div>
      );
    }
  };

  const renderStripe = () => {
    if (!institution.payments.stripe.active) {
      return (
        <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
          <div className="flex items-center justify-start gap-1.5">
            <StripeLogo className="w-auto h-7" />
          </div>
          <button
            onClick={() => window.open(process.env.REACT_APP_STRIPE_LINK)}
            className="bg-[#675DFF] hover:bg-[#4841b5] transition-all duration-200 text-white text-sm px-4 py-2 rounded-md font-bold"
          >
            Setup Stripe Payments
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex items-center justify-between w-full max-w-3xl gap-3 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
          <div className="flex items-center justify-start gap-1.5">
            <StripeLogo className="w-auto h-7" />
          </div>
          <button className="bg-[#675DFF] cursor-default text-white text-sm px-4 py-2 rounded-md font-bold">
            Connected to {institution.payments.stripe.accountId.toUpperCase()}
          </button>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-screen overflow-y-auto">
      <Helmet>
        {paypalOnboardingUrl && <script id="paypal-js" src="https://www.sandbox.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js"></script>}
      </Helmet>
      <nav id="toggleMobileMenu" className="w-full bg-black border-b border-gray-200 shadow-sm">
        <div className="flex items-center justify-start gap-4 px-4 py-4 text-sm">
          <div
            onClick={() => navigate("/management/settings")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Institution Settings
          </div>
          <div
            onClick={() => navigate("/management/settings/socialPresence")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Social Presence
          </div>
          <div
            onClick={() => navigate("/management/settings/payments")}
            className="font-semibold text-white transition-all duration-200 rounded-lg cursor-pointer hover:text-gray-400 focus:ring-0"
            aria-current="page"
          >
            Payments
          </div>
          <div
            onClick={() => navigate("/management/settings/integrations")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Integrations
          </div>
        </div>
      </nav>
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-4 mx-auto mt-5 text-white max-w-content">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="font-serif text-2xl font-bold">Payment Methods</p>
          <p className="text-sm font-medium">Manage the integrations with different payment processors</p>
        </div>
        {!loading && (
          <>
            {renderStripe()}
            {renderPaypal()}
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentSettings;
