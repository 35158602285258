import { useNavigate } from "react-router-dom";

const InstitutionSettings = ({ authState, authDispatch, data }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-screen overflow-y-auto">
      <nav id="toggleMobileMenu" className="w-full bg-black border-b border-gray-200 shadow-sm">
        <div className="flex items-center justify-start gap-4 px-4 py-4 text-sm">
          <div
            onClick={() => navigate("/management/settings")}
            className="font-semibold text-white transition-all duration-200 rounded-lg cursor-pointer hover:text-gray-400 focus:ring-0"
            aria-current="page"
          >
            Institution Settings
          </div>
          <div
            onClick={() => navigate("/management/settings/socialPresence")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Social Presence
          </div>
          <div
            onClick={() => navigate("/management/settings/payments")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Payments
          </div>
          <div
            onClick={() => navigate("/management/settings/integrations")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Integrations
          </div>
        </div>
      </nav>
      <div className="flex flex-col items-start justify-start flex-grow w-full h-full mx-auto mt-5 max-w-content">
        <p className="font-serif text-2xl font-bold">Institution Settings</p>
        <p className="text-sm font-medium">Manage the details of your institution</p>
      </div>
    </div>
  );
};

export default InstitutionSettings;
