const EmptyCartSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 896 747.97143"
      className="h-[250px] w-auto"
    >
      <path
        fill="#2f2e41"
        d="M41.634 712.73796c12.42842 23.049 38.806 32.9435 38.806 32.9435s6.22712-27.47543-6.2013-50.52448-38.806-32.9435-38.806-32.9435-6.22711 27.47543 6.2013 50.52448Z"
      />
      <path
        fill="#1e2e52"
        d="M50.17653 705.15498c22.43841 13.49969 31.08016 40.3138 31.08016 40.3138s-27.73812 4.92679-50.17653-8.57291S0 696.58207 0 696.58207s27.73811-4.92678 50.17653 8.57291Z"
      />
      <path fill="#f2f2f2" d="M413.2485 35.90779h140v2h-140z" />
      <path
        fill="#f2f2f2"
        d="M513.2485 37.40779h2v18.5h-2zm-61 0h2v18.5h-2zm32 94.5h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M522.2485 113.90779h2v18.5h-2zm61 0h2v18.5h-2zm87 63h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M708.2485 158.90779h2v18.5h-2zm61 0h2v18.5h-2zm-113 482h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M694.2485 622.90779h2v18.5h-2zm61 0h2v18.5h-2zm-338-303h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M455.2485 301.90779h2v18.5h-2zm61 0h2v18.5h-2zm-55 259h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M499.2485 542.90779h2v18.5h-2zm61 0h2v18.5h-2zm125-55h140v2h-140z"
      />
      <path fill="#f2f2f2" d="M723.2485 469.90779h2v18.5h-2zm61 0h2v18.5h-2z" />
      <path
        fill="#2f2e41"
        d="M362.06 702.184H125.274v-1.703h235.082v-82.62H145.18l-10.453-21.777 1.536-.737 9.989 20.81H362.06v86.027z"
      />
      <circle cx="156.78851" cy="726.03301" r="17.88673" fill="#3f3d56" />
      <circle cx="333.10053" cy="726.03301" r="17.88673" fill="#3f3d56" />
      <circle cx="540.92726" cy="346.153" r="11.07274" fill="#3f3d56" />
      <path
        fill="#2f2e41"
        d="M387.38538 589.75318H121.23673L63.64844 401.51671h383.04412l-.34852 1.10753Zm-264.8885-1.7035H386.136l58.23417-184.82951H65.95082Z"
      />
      <path
        fill="#f2f2f2"
        d="M366.61 579.958H132.842L82.26 413.015h336.441l-.306.983-51.785 165.96z"
      />
      <path
        fill="#2f2e41"
        d="m451.465 384.7-1.647-.437 11.241-42.369h65.389v1.704H462.37L451.465 384.7zM82.2584 458.58385h345.2931v1.7035H82.2584zm19.20054 62.75992h306.31852v1.7035H101.45894z"
      />
      <path
        fill="#2f2e41"
        d="M254.31376 402.36843h1.7035v186.53301h-1.7035zm65.74379404 186.47491769 12.22836349-186.5283683 1.70014048.11145723-12.22836349 186.5283683zM176.38040981 402.42382162l1.70018278-.11081005 12.1573594 186.53300959-1.70018278.11081005zM0 745h896v2H0z"
      />
      <path
        fill="#a0616a"
        d="M595.41068 61.87599s14.61842 41.60627 5.62246 48.00724 30.36134 58.67548 30.36134 58.67548l47.22874-12.80193-25.86336-43.73993s-3.37348-43.73992-3.37348-50.14089-53.9757.00003-53.9757.00003Z"
      />
      <path
        d="M595.41068 61.87599s14.61842 41.60627 5.62246 48.00724 30.36134 58.67548 30.36134 58.67548l47.22874-12.80193-25.86336-43.73993s-3.37348-43.73992-3.37348-50.14089-53.9757.00003-53.9757.00003Z"
        opacity=".1"
      />
      <path
        fill="#2f2e41"
        d="M570.87364 358.45403s-4.26731 53.34138 0 81.07889 10.66828 104.5491 10.66828 104.5491 0 145.08854 23.4702 147.22219 40.53945 4.26731 42.6731-4.26731-10.66827-12.80193-4.26731-17.06924 8.53462-19.20289 0-36.27213 0-189.8953 0-189.8953l40.53945 108.81641s4.26731 89.61351 8.53462 102.41544-4.26731 36.27213 10.66827 38.40579 32.00483-10.66828 40.53945-14.93559-12.80193-4.26731-8.53462-6.401 17.06924-8.53462 12.80193-10.66828-8.53462-104.54909-8.53462-104.54909-11.73511-218.6996-26.67069-227.2342-24.537 6.16576-24.537 6.16576Z"
      />
      <path
        fill="#2f2e41"
        d="M609.27943 682.76959v17.06924s-19.20289 46.39942 0 46.39942 34.13848 4.8083 34.13848-1.59266V687.0369Zm125.88565-.0303v17.06924s19.20289 46.39941 0 46.39941-34.13848 4.80831-34.13848-1.59266V687.0066Z"
      />
      <circle cx="625.28185" cy="54.4082" r="38.40579" fill="#a0616a" />
      <path
        fill="#1e2e52"
        d="M613.54674 125.88564s10.66828 32.00482 27.73752 25.60386l17.06924-6.401 29.87117 204.83091s-23.47021 34.13848-57.60869 12.80193-17.06924-236.8357-17.06924-236.8357Z"
      />
      <path
        fill="#3f3d56"
        d="m643.41791 119.48471 9.60145-20.26972s56.54186 26.67069 65.07648 35.20531 8.53462 21.33655 8.53462 21.33655l-14.93559 53.34137s4.26731 117.351 4.26731 121.61834 14.93559 27.73751 4.26731 19.20289-12.80193-17.06924-21.33655-4.26731-27.73751 27.73752-27.73751 27.73752Z"
      />
      <path
        fill="#a0616a"
        d="m718.09584 273.10783-6.401 59.74234s-38.40579 34.13848-29.87117 36.27214 12.80193-6.401 12.80193-6.401 14.93559 14.93559 23.47021 6.401S747.967 279.5088 747.967 279.5088Z"
      />
      <path
        fill="#2f2e41"
        d="M626.1.12987c-8.51412-.30437-17.62549-.45493-24.80406 4.13321a36.31263 36.31263 0 0 0-8.5723 8.39153c-6.99153 8.83846-13.03253 19.95926-10.43553 30.92537l3.01633-1.1764a19.75086 19.75086 0 0 1-1.90515 8.46261c.42475-1.2351 1.84722.76151 1.4664 2.01085L581.543 63.77771c5.46207-2.00239 12.25661 2.05189 13.08819 7.80969.37974-12.66123 1.6932-27.17965 11.964-34.59331 5.17951-3.73868 11.73465-4.88 18.04162-5.8935 5.81832-.935 11.91781-1.82659 17.49077.08886s10.31871 7.615 9.0553 13.37093c2.56964-.88518 5.44356.90566 6.71347 3.30856s1.33662 5.2375 1.37484 7.95506c2.73911 1.93583 5.85632-1.9082 6.97263-5.07112 2.62033-7.42434 4.94941-15.32739 3.53783-23.073s-7.72325-15.14773-15.59638-15.174a5.46676 5.46676 0 0 0 1.42176-3.84874l-6.48928-.5483a7.1723 7.1723 0 0 0 4.28575-2.25954C650.7981 8.71623 630.31323.28048 626.1.12987Z"
      />
      <path
        fill="#3f3d56"
        d="M624.215 113.08371s-17.36929-17.02085-23.62023-15.97822-14.78554 15.97822-14.78554 15.97822-51.20772 17.06924-49.07407 34.13848S562.339 247.50397 562.339 247.50397s19.2029 100.28179 2.13366 110.95006 81.07889 38.40579 83.21254 25.60386 6.401-140.82123 0-160.02412-23.4702-110.95006-23.4702-110.95006Zm74.67794 34.13848h26.38265s18.42411 81.07889 20.55776 89.61352 6.401 49.07406 4.26731 49.07406-44.80675-8.53462-44.80675-2.13365Z"
      />
      <path
        fill="#f2f2f2"
        d="M698 348H597c-9.85608-45.34-10.67957-89.14649 0-131h101c-16.29919 41.10071-17.31775 84.60708 0 131Z"
      />
      <path
        fill="#a0616a"
        d="m555.93806 292.31071 29.87117 12.802s57.60868 8.53462 57.60868-14.93559-57.60868-10.66827-57.60868-10.66827l-19.20418-6.14014Z"
      />
      <path
        fill="#3f3d56"
        d="m562.339 134.42026-25.60386 6.401-19.20287 113.08368s-6.401 29.87117 4.26731 32.00482 40.53942 19.20295 40.53942 19.20295 4.26731-32.00483 12.80193-32.00483l-21.33653-17.06929 12.80193-74.67788Z"
      />
      <path fill="#f2f2f2" d="M60.2485 352.90779h140v2h-140z" />
      <path
        fill="#f2f2f2"
        d="M98.2485 334.90779h2v18.5h-2zm61 0h2v18.5h-2zm-50-278h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M209.2485 58.40779h2v18.5h-2zm-61 0h2v18.5h-2zm102 195.5h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M350.2485 255.40779h2v18.5h-2zm-61 0h2v18.5h-2zm-277-2.5h140v2h-140z"
      />
      <path
        fill="#f2f2f2"
        d="M112.2485 254.40779h2v18.5h-2zm-61 0h2v18.5h-2zm129-101.5h140v2h-140z"
      />
      <path fill="#f2f2f2" d="M218.2485 134.90779h2v18.5h-2zm61 0h2v18.5h-2z" />
    </svg>
  );
};

export default EmptyCartSVG;
