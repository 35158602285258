const processHtmlForTailwind = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const processTextStyles = (el) => {
    // Alignment
    if (el.classList.contains("ql-align-center")) {
      el.classList.remove("ql-align-center");
      el.classList.add("text-center");
    } else if (el.classList.contains("ql-align-right")) {
      el.classList.remove("ql-align-right");
      el.classList.add("text-right");
    } else if (el.classList.contains("ql-align-justify")) {
      el.classList.remove("ql-align-justify");
      el.classList.add("text-justify");
    }

    // Font weight
    if (el.style.fontWeight === "bold" || el.style.fontWeight === "700") {
      el.classList.add("font-bold");
    } else if (el.style.fontWeight === "600") {
      el.classList.add("font-semibold");
    } else if (el.style.fontWeight === "normal" || el.style.fontWeight === "400") {
      el.classList.add("font-normal");
    }
    el.style.fontWeight = "";

    // Font style
    if (el.style.fontStyle === "italic") {
      el.classList.add("italic");
    }
    el.style.fontStyle = "";

    // Handle <strong> elements
    const handleStrong = (element) => {
      try {
        const strongElements = element.querySelectorAll("strong");
        strongElements.forEach((strongEl) => {
          strongEl.classList.add("font-bold");
          while (strongEl.firstChild) {
            strongEl.parentNode.insertBefore(strongEl.firstChild, strongEl);
          }
          strongEl.parentNode.removeChild(strongEl);
        });
      } catch (e) {
        console.log("HANDLE STRONG FAILED: ", e);
      }
    };

    handleStrong(el);
  };

  const isEmptyParagraph = (el) => {
    return el.innerHTML.trim() === "" || el.innerHTML === "<br>" || el.innerHTML === "<br/>";
  };

  // Process paragraphs
  doc.querySelectorAll("p").forEach((el) => {
    if (!isEmptyParagraph(el)) {
      el.classList.add("mb-2", "text-base", "leading-relaxed");
      processTextStyles(el);
    } else {
      // Remove the empty paragraph
      el.parentNode.removeChild(el);
    }
  });

  // Process headings
  doc.querySelectorAll("h1").forEach((el) => {
    el.classList.add("text-4xl", "font-bold", "mb-2", "leading-none", "tracking-wide", "font-display");
    processTextStyles(el);
  });

  doc.querySelectorAll("h2").forEach((el) => {
    el.classList.add("text-2xl", "font-bold", "mb-4", "leading-none", "tracking-wide", "font-display");
    processTextStyles(el);
  });

  doc.querySelectorAll("h3").forEach((el) => {
    el.classList.add("text-2xl", "font-semibold", "mb-2", "leading-none", "tracking-wide", "font-display");
    processTextStyles(el);
  });

  doc.querySelectorAll("h4").forEach((el) => {
    el.classList.add("text-xl", "font-semibold", "mb-2", "leading-none", "tracking-wide", "font-display");
    processTextStyles(el);
  });

  doc.querySelectorAll("h5").forEach((el) => {
    el.classList.add("text-lg", "font-medium", "mb-2", "tracking-wide", "font-display");
    processTextStyles(el);
  });

  doc.querySelectorAll("h6").forEach((el) => {
    el.classList.add("text-base", "font-semibold", "mb-2", "tracking-wide", "font-display");
    processTextStyles(el);
  });

  // Process lists
  doc.querySelectorAll("ul").forEach((el) => {
    el.classList.add("list-disc", "list-inside", "mb-2");
    processTextStyles(el);
  });
  doc.querySelectorAll("ol").forEach((el) => {
    el.classList.add("list-decimal", "list-inside", "mb-2");
    processTextStyles(el);
  });

  // Process list items
  doc.querySelectorAll("li").forEach((el) => {
    el.classList.add("mb-1");
    processTextStyles(el);
  });

  // Process blockquotes
  doc.querySelectorAll("blockquote").forEach((el) => {
    el.classList.add("border-l-4", "border-gray-500", "pl-4", "italic", "my-4");
    processTextStyles(el);
  });

  // Process links
  doc.querySelectorAll("a").forEach((el) => {
    el.classList.add("text-blue-500", "hover:underline");
    // Add attributes to open link in a new tab
    el.setAttribute("target", "_blank");
    el.setAttribute("rel", "noopener noreferrer");
    processTextStyles(el);
  });

  // Process images
  doc.querySelectorAll("img").forEach((el) => {
    el.classList.add("max-w-full", "h-auto", "my-4");
  });

  // Process inline styles
  doc.querySelectorAll("b").forEach((el) => el.classList.add("font-bold"));
  doc.querySelectorAll("em, i").forEach((el) => el.classList.add("italic"));
  doc.querySelectorAll("u").forEach((el) => el.classList.add("underline"));
  doc.querySelectorAll("s").forEach((el) => el.classList.add("line-through"));

  return doc.body.innerHTML;
};

export default processHtmlForTailwind;
