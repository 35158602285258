import axiosInstance from "./axios";

export const CreateANewTicket = (data) => {
  return axiosInstance.post("/mgmt/tickets", data);
};

export const GetAllTickets = () => {
  return axiosInstance.get("/mgmt/tickets");
};

export const GetOneTicket = (ticketId) => {
  return axiosInstance.get(`/mgmt/tickets/${ticketId}`);
};

export const AddPriceToATicket = (ticketId, data) => {
  return axiosInstance.put(`/mgmt/tickets/${ticketId}/price`, data);
};

export const UpdateTicketPrice = (ticketId, stripePriceId, data) => {
  return axiosInstance.put(`/mgmt/tickets/${ticketId}/price/${stripePriceId}`, data);
};

export const UpdateTicketInfo = (ticketId, data) => {
  return axiosInstance.put(`/mgmt/tickets/${ticketId}`, data);
};

export const BeginPaypalOnboarding = () => {
  return axiosInstance.get("/mgmt/payments/onboarding/paypal");
};

export const VerifyPaypalOnboarding = (data) => {
  return axiosInstance.post("/mgmt/payments/onboarding/paypal/verify", data);
};

export const SetupStripePayments = (data) => {
  return axiosInstance.post("/mgmt/payments/onboarding/stripe", data);
};

export const GetAllMemberships = () => {
  return axiosInstance.get("/mgmt/memberships");
};

export const CreateANewMembership = (data) => {
  return axiosInstance.post("/mgmt/memberships", data);
};

export const GetOneMembership = (membershipId) => {
  return axiosInstance.get(`/mgmt/memberships/${membershipId}`);
};

export const UpdateMembershipInfo = (membershipId, data) => {
  return axiosInstance.put(`/mgmt/memberships/${membershipId}`, data);
};

export const UpdateMembershipPrice = (membershipId, stripePriceId, data) => {
  return axiosInstance.put(`/mgmt/memberships/${membershipId}/price/${stripePriceId}`, data);
};

export const AddPriceToMembership = (membershipId, data) => {
  return axiosInstance.put(`/mgmt/memberships/${membershipId}/price`, data);
};

export const AddMembershipBenefit = (membershipId, data) => {
  return axiosInstance.post(`/mgmt/memberships/${membershipId}/benefits`, data);
};

export const UpdateMembershipBenefit = (membershipId, benefitId, data) => {
  return axiosInstance.put(`/mgmt/memberships/${membershipId}/benefits/${benefitId}`, data);
};

export const DeleteMembershipBenefit = (membershipId, benefitId) => {
  return axiosInstance.delete(`/mgmt/memberships/${membershipId}/benefits/${benefitId}`);
};

export const UpdateMembershipDiscounts = (membershipId, type, data) => {
  return axiosInstance.put(`/mgmt/memberships/${membershipId}/discounts/${type}`, data);
};

export const GetEvents = () => {
  return axiosInstance.get("/mgmt/events");
};

export const CreateEvent = (data) => {
  return axiosInstance.post("/mgmt/events", data);
};

export const GetCanvaAuthLink = () => {
  return axiosInstance.get("/mgmt/integrations/canva/auth");
};

export const ExchangeCanvaCode = (data) => {
  return axiosInstance.post("/mgmt/integrations/canva/code", data);
};

export const GetOneEvent = (eventId) => {
  return axiosInstance.get(`/mgmt/events/${eventId}`);
};

export const UpdateEvent = (eventId, data) => {
  return axiosInstance.put(`/mgmt/events/${eventId}`, data);
};

export const AddTicketToEvent = (eventId, data) => {
  return axiosInstance.post(`/mgmt/events/${eventId}/ticket`, data);
};

export const UpdateEventStatus = (eventId, data) => {
  return axiosInstance.put(`/mgmt/events/${eventId}/status`, data);
};

export const KillCanvaIntegration = () => {
  return axiosInstance.post("/mgmt/integrations/canva/kill");
};
