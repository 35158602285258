import { Checkbox, DatePicker, Input, Modal, Radio, TimePicker } from "antd";
import { Edit } from "lucide-react";
import { useState, useCallback } from "react";
import toast from "react-hot-toast";
import { UpdateEvent } from "../../../../actions/mgmt";
import { AddressAutofill } from "@mapbox/search-js-react";
import dayjs from "dayjs";

const WhenAndWhere = ({ eventData, reloadData }) => {
  const [editTimes, setEditTimes] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editType, setEditType] = useState(false);

  const [showTime, setShowTime] = useState(eventData.showTime);
  const [locationType, setLocationType] = useState(null);
  const [address, setAddress] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [eventDate, setEventDate] = useState({
    startDate: eventData.startDate,
    endDate: eventData.endDate,
    startTime: eventData.startDate,
    endTime: eventData.endDate,
  });

  const renderTimes = (times) => {
    if (times.length === 0) {
      return "Both Hidden";
    } else if (times.length === 1) {
      if (times[0] === "startTime") {
        return "Start Time Visible";
      } else {
        return "End Time Visible";
      }
    } else {
      return "Both Visible";
    }
  };

  const handleRetrieve = useCallback((res) => {
    let tmpFeature = res.features[0];
    if (tmpFeature) {
      setAddress(tmpFeature.properties.full_address);
    }
  });

  const renderLocationTypeModal = () => {
    return (
      <Modal
        title="Event location settings"
        open={editLocation}
        onCancel={() => closeLocationSettings()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeLocationSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitLocationSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the event location
          </label>
          <Radio.Group
            options={[
              { label: "Online", value: "online" },
              { label: "At the institution", value: "institution" },
              { label: "At a different location", value: "address" },
            ]}
            onChange={({ target: { value } }) => setLocationType(value)}
            optionType="button"
            buttonStyle="solid"
            defaultValue={eventData.locationType}
          />
          {locationType === "address" && (
            <div className="w-full mt-2">
              <AddressAutofill
                accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                onRetrieve={(r) => handleRetrieve(r)}
                options={{ language: "en", country: "US" }}
                className="w-full"
              >
                <Input placeholder="Start typing the event address..." onChange={(e) => setAddress(e.target.value)} value={address} />
              </AddressAutofill>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const closeLocationSettings = () => {
    setEditLocation(false);
    setLocationType(null);
    setAddress(null);
    reloadData();
  };

  const submitLocationSettings = () => {
    if (locationType === "address" && !address) {
      toast.error("Please enter an address");
      return;
    }
    UpdateEvent(eventData.eventId, { locationType, address })
      .then(() => {
        toast.success("Event location updated!");
        closeLocationSettings();
      })
      .catch(() => {
        toast.error("Failed to update event location");
      });
  };

  const renderLocationType = (type) => {
    switch (type) {
      case "online":
        return "Online";
      case "institution":
        return "At the institution";
      case "address":
        return "At a different location";
      default:
        return "";
    }
  };

  const renderAddressModal = () => {
    return (
      <Modal
        title="Event address settings"
        open={editAddress}
        onCancel={() => closeAddressSettings()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeAddressSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitAddressSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the event address
          </label>
          {locationType === "address" && (
            <div className="w-full mt-2">
              <AddressAutofill
                accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                onRetrieve={(r) => handleRetrieve(r)}
                options={{ language: "en", country: "US" }}
                className="w-full"
              >
                <Input placeholder="Start typing the event address..." onChange={(e) => setAddress(e.target.value)} value={address} />
              </AddressAutofill>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const closeAddressSettings = () => {
    setEditAddress(false);
    setAddress(null);
    reloadData();
  };

  const submitAddressSettings = () => {
    if (!address) {
      toast.error("Please enter an address");
      return;
    }
    UpdateEvent(eventData.eventId, { address })
      .then(() => {
        toast.success("Event address updated!");
        closeAddressSettings();
      })
      .catch(() => {
        toast.error("Failed to update event address");
      });
  };

  const renderEventDates = () => {
    if (eventData.eventType === "single-day") {
      let format1 = "MM/DD/YYYY hh:mm A";
      let format2 = "hh:mm A";
      if (eventData.showTime.length === 0) {
        format1 = "MM/DD/YYYY";
        format2 = null;
      } else if (eventData.showTime.length === 1) {
        if (eventData.showTime[0] === "startTime") {
          format1 = "MM/DD/YYYY hh:mm A";
          format2 = null;
        } else {
          format1 = "MM/DD/YYYY";
          format2 = "[ends at] hh:mm A";
        }
      } else {
        format1 = "MM/DD/YYYY hh:mm A";
        format2 = "hh:mm A";
      }
      return `${dayjs(eventData.startDate).format(format1)}${format2 ? ` - ${dayjs(eventData.endDate).format(format2)}` : ""}`;
    } else {
      return `${dayjs(eventData.startDate).format("MM/DD/YYYY")} - ${dayjs(eventData.endDate).format("MM/DD/YYYY")}`;
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const dateRangeValue = () => {
    if (eventDate.startDate && eventDate.endDate) {
      if (dayjs(eventDate.startDate).isValid() && dayjs(eventDate.endDate).isValid()) {
        if (dayjs(eventDate.endDate).isAfter(dayjs(eventDate.startDate), "day")) {
          return [dayjs(eventDate.startDate), dayjs(eventDate.endDate)];
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderEventTypeModal = () => {
    return (
      <Modal
        title="Event type"
        open={editType}
        onCancel={() => closeEditType()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeEditType()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitEditType()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the event type
          </label>
          <Radio.Group
            options={[
              { label: "Single-Day Event", value: "single-day" },
              { label: "Multi-Day Event", value: "multi-day" },
            ]}
            onChange={({ target: { value } }) => setEventType(value)}
            optionType="button"
            buttonStyle="solid"
          />
          {eventType !== null && (
            <>
              {eventType === "multi-day" ? (
                <DatePicker.RangePicker
                  className="py-1.5 px-5"
                  disabledDate={disabledDate}
                  onChange={(v, s) =>
                    setEventDate((prevState) => ({
                      ...prevState,
                      startDate: v[0].toJSON(),
                      endDate: v[1].toJSON(),
                    }))
                  }
                  value={dateRangeValue()}
                  defaultValue={[
                    dayjs(eventData?.startDate).isValid() ? dayjs(eventData?.startDate) : null,
                    dayjs(eventData?.endDate).isValid() ? dayjs(eventData?.endDate) : null,
                  ]}
                />
              ) : (
                <div className="flex flex-row items-center justify-start gap-2">
                  <DatePicker
                    className="py-1.5 px-5"
                    needConfirm={false}
                    disabledDate={disabledDate}
                    onChange={(v, s) => setEventDate((prevState) => ({ ...prevState, startDate: dayjs(v).toJSON() }))}
                    defaultValue={dayjs(eventData?.startDate).isValid() ? dayjs(eventData?.startDate) : null}
                  />
                  <TimePicker
                    className="py-1.5 px-5"
                    format="hh:mm A"
                    needConfirm={false}
                    placeholder="Start Time"
                    onChange={(d, s) => setEventDate((prevState) => ({ ...prevState, startTime: dayjs(d).toJSON() }))}
                    defaultValue={dayjs(eventData?.startDate).isValid() ? dayjs(eventData?.startDate) : null}
                  />
                  <TimePicker
                    className="py-1.5 px-5"
                    format="hh:mm A"
                    needConfirm={false}
                    placeholder="End Time"
                    onChange={(d, s) => setEventDate((prevState) => ({ ...prevState, endTime: dayjs(d).toJSON() }))}
                    defaultValue={dayjs(eventData?.endDate).isValid() ? dayjs(eventData?.endDate) : null}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    );
  };

  const closeEditType = () => {
    setEditType(false);
    setEventType(null);
    setEventDate({
      startDate: eventData.startDate,
      endDate: eventData.endDate,
      startTime: eventData.startDate,
      endTime: eventData.endDate,
    });
    reloadData();
  };

  const submitEditType = () => {
    if (!eventType) {
      toast.error("Please select an event type");
      return;
    } else if (eventType === "single-day" || eventType === "multi-day") {
      if (eventType === "multi-day") {
        if (!dayjs(eventDate.startDate).isValid() || !dayjs(eventDate.endDate).isValid()) {
          toast.error("Please select a start and end date");
          return;
        }
      }
      if (eventType === "single-day") {
        if (!dayjs(eventDate.startDate).isValid()) {
          toast.error("Please select a start date and time");
          return;
        }
        if (!dayjs(eventDate.endDate).isValid()) {
          toast.error("Please select a end date and time");
          return;
        }
      }
      UpdateEvent(eventData.eventId, {
        eventType,
        startDate: eventDate.startDate,
        endDate: eventDate.endDate,
        startTime: eventDate.startTime,
        endTime: eventDate.endTime,
      })
        .then(() => {
          toast.success("Event type updated!");
          closeEditType();
        })
        .catch(() => {
          toast.error("Failed to update event type");
        });
    } else {
      toast.error("Invalid event type");
    }
  };

  const renderShowTimesModal = () => {
    return (
      <Modal
        title="Edit event start / end time visibility"
        open={editTimes}
        onCancel={() => closeTimeSettings()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeTimeSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitTimeSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the event location
          </label>
          <Checkbox.Group
            options={[
              { label: "Display start time", value: "startTime" },
              { label: "Display end time", value: "endTime" },
            ]}
            value={showTime}
            defaultValue={showTime}
            onChange={(v) => setShowTime(v)}
          />
        </div>
      </Modal>
    );
  };

  const closeTimeSettings = () => {
    setEditTimes(false);
    setShowTime(eventData.showTime);
    reloadData();
  };

  const submitTimeSettings = () => {
    UpdateEvent(eventData.eventId, { showTime })
      .then(() => {
        toast.success("Event start / end time visibility updated!");
        closeTimeSettings();
      })
      .catch(() => {
        toast.error("Failed to update event start / end time visibility");
      });
  };

  return (
    <div className="flex flex-col w-full gap-3">
      <dl className="h-full divide-y divide-white/10">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Event Location</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <p className="capitalize">{renderLocationType(eventData.locationType)}</p>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setEditLocation(true)}
              >
                <Edit className="w-4 h-4" />
              </button>
            )}
          </dd>
        </div>
        {eventData.locationType === "address" && (
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Address</dt>
            <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
              <p>{eventData.address}</p>
              {eventData.status === "draft" && (
                <button
                  className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                  onClick={() => setEditAddress(true)}
                >
                  <Edit className="w-4 h-4" />
                </button>
              )}
            </dd>
          </div>
        )}
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Event Type</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <p className="capitalize">{eventData.eventType}</p>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setEditType(true)}
              >
                <Edit className="w-4 h-4" />
              </button>
            )}
          </dd>
        </div>
        {eventData.eventType === "single-day" && (
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Show Start/End Time</dt>
            <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
              <p className="capitalize">{renderTimes(eventData.showTime)}</p>
              {eventData.status === "draft" && (
                <button
                  className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                  onClick={() => setEditTimes(true)}
                >
                  <Edit className="w-4 h-4" />
                </button>
              )}
            </dd>
          </div>
        )}
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Event Date {eventData.eventType === "single-day" ? "& Time" : "s"}</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <p className="capitalize">{renderEventDates()}</p>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setEditType(true)}
              >
                <Edit className="w-4 h-4" />
              </button>
            )}
          </dd>
        </div>
      </dl>
      {renderLocationTypeModal()}
      {renderAddressModal()}
      {renderEventTypeModal()}
      {renderShowTimesModal()}
    </div>
  );
};

export default WhenAndWhere;
