import { Button, Image, Skeleton, Tag } from "antd";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { formatCurrency, getRandomTagColor } from "../../../components/utilities";
import dayjs from "dayjs";
import { GetOneEvent } from "../../../actions/data";
import toast from "react-hot-toast";
import TailwindHtmlRenderer from "../../../components/tailwindHtmlRenderer";
import PreFooter from "../../../components/preFooter";
import Footer from "../../../components/footer";
import { useCart } from "react-use-cart";
import { EmailShareButton, FacebookShareButton, RedditShareButton, TwitterShareButton } from "react-share";
import { FacebookF, Reddit, Twitter } from "styled-icons/fa-brands";
import { Email } from "styled-icons/material-outlined";
import styled from "styled-components";
import { EVENT_CATEGORIES } from "../../../lib/eventEnums";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const EventPage = () => {
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [eventData, setEventData] = useState(null);

  const params = useParams();
  const navigate = useNavigate();
  const { items, addItem, inCart, updateItemQuantity } = useCart();

  const [setOpenCart] = useOutletContext();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (params && params.eventId && params.eventId !== "") {
        setEventId(params.eventId);
        GetOneEvent(params.eventId)
          .then((res) => {
            setEventData(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error("Failed to fetch event data");
            setTimeout(() => navigate("/events"), 500);
          });
      } else {
        navigate("/events");
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const EventCategory = ({ category }) => {
    let search = `/events?category=${category}`;
    search = encodeURI(search);
    return (
      <a href={search} className="text-blue-600 hover:underline" target="_blank" rel="noreferrer">
        <Tag color="#2580ff">{category.toUpperCase()}</Tag>
      </a>
    );
  };

  const Location = () => {
    const { locationType, address } = eventData;
    if (locationType === "Online") {
      return <p>Online Event</p>;
    }
    return (
      <p>
        {locationType === "Institution" && <span className="font-semibold">{process.env.REACT_APP_INSTITUTION_NAME}: </span>}
        {address}
      </p>
    );
  };

  const DateTimeDisplay = () => {
    const { startDate, endDate, startTime, endTime, eventType, showTime } = eventData;
    const isSingleDay = eventType === "single-day" ?? true;

    let renderTime = "";
    if (showTime && showTime.length > 0) {
      if (showTime.length === 2) {
        renderTime = ` - From ${dayjs(startDate).format("hh:mm A")} to ${dayjs(endDate).format("hh:mm A")}`;
      } else if (showTime.length === 1) {
        if (showTime.includes("startTime")) {
          renderTime = ` - Starts at ${dayjs(startDate).format("hh:mm A")}`;
        } else if (showTime.includes("endTime")) {
          renderTime = ` - Ends at ${dayjs(startDate).format("hh:mm A")}`;
        }
      }
    }

    return isSingleDay ? (
      <p className="text-base leading-none whitespace-nowrap font-body">
        {dayjs(startDate).format("MMMM Do, YYYY")}
        {renderTime}
      </p>
    ) : (
      <p className="text-base leading-none whitespace-nowrap font-body">
        {dayjs(startDate).format("MMMM Do, YYYY")} - {dayjs(endDate).format("MMMM Do, YYYY")}
      </p>
    );
  };

  const addTicketToCart = (ticket) => {
    let itemData = {
      id: ticket.ticketId,
      name: ticket.title,
      price: ticket.price,
      description: ticket.description,
      type: ticket.type,
    };
    if (inCart(itemData)) {
      let existing = items.find((item) => item.id === itemData.id);
      updateItemQuantity(itemData.id, (existing?.quantity || 0) + 1);
    } else {
      addItem(itemData);
    }
    setOpenCart(true);
  };

  const TicketSection = () => {
    const tickets = eventData?.tickets ?? [];
    return (
      <div className="mt-6">
        <h3 className="mb-3 text-2xl font-medium leading-none tracking-wide font-display">Tickets</h3>
        <div className="flex flex-col items-start justify-start gap-3 px-5 mt-6 md:px-10">
          {tickets.map((ticket, index) => (
            <div
              key={index}
              className="flex flex-col items-start justify-between w-full gap-4 px-4 py-4 border md:flex-row md:items-center md:px-6 lg:py-6 border-black/10 md:min-h-28"
            >
              <div className="flex flex-col items-start justify-center gap-1 mb-3 md:mb-0">
                <p className="text-lg font-semibold md:text-xl font-display">{ticket.title}</p>
                {ticket.description && <p className="text-sm text-gray-900/70">{ticket.description}</p>}
                {ticket.quantity && ticket.quantity > 0 && ticket.quantity < 10 && (
                  <p className="text-xs font-semibold tracking-wide md:text-sm font-base text-black/60">ONLY A FEW TICKETS LEFT</p>
                )}
              </div>
              <div className="flex flex-row items-center justify-between w-full gap-2 md:justify-end md:w-auto md:gap-4">
                <p className="text-sm font-semibold whitespace-nowrap">{ticket.price === 0 ? "Free" : formatCurrency(ticket.price)}</p>
                <button
                  onClick={() => addTicketToCart(ticket)}
                  className="px-4 py-2 text-sm font-semibold leading-3 tracking-wide text-white uppercase transition-all bg-black border border-black md:px-6 md:py-3 md:text-base font-body hover:text-black hover:bg-transparent"
                >
                  {ticket.price === 0 ? "RSVP" : "Purchase"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getImageElement = () => {
    if (eventData?.images && eventData.images.length > 0) {
      const primaryImage = eventData.images.find((image) => image.isPrimary);
      return primaryImage?.previewUrl;
    }
    return "https://images.unsplash.com/photo-1721143571164-06550363f6d0";
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getEventStatusClasses = (category) => {
    let data = EVENT_CATEGORIES.find((c) => c.value === category);
    if (data) {
      function increaseTailwindColorIntensity(colorClass) {
        const parts = colorClass.split("-");
        if (parts.length === 2) {
          parts.unshift("bg");
        }
        const intensity = parseInt(parts[2], 10);
        const newIntensity = Math.min(intensity + 100, 900);
        const newColorClass = `${parts[0]}-${parts[1]}-${newIntensity}`;
        return newColorClass;
      }
      return `text-${data.color} bg-${increaseTailwindColorIntensity(data.color)}/10 ring-${data.color}/20`;
    } else {
      return `text-gray-500 bg-gray-600/10 ring-gray-500/20`;
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full min-h-screen text-black bg-white">
      {loading ? (
        <div className="flex flex-col items-center justify-center flex-grow w-full h-full gap-5 px-10 py-20 mx-auto max-w-content">
          <p className="text-xl font-semibold font-body animate-pulse">Loading Event...</p>
          <Skeleton paragraph={{ rows: 2 }} title={false} active className="w-1/2" />
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {eventData.title} | {process.env.REACT_APP_INSTITUTION_NAME}
            </title>
            <meta name="description" content={eventData.summary} />
          </Helmet>
          <div className="relative h-[35vh] sm:h-[45vh] md:h-[55vh] w-full mb-8 sm:mb-4">
            <img src={getImageElement()} alt={eventData.title} className="object-cover w-full h-full" />
            <div className="absolute bottom-0 left-0 right-0 w-full mx-auto max-w-content">
              <div className="absolute left-0 right-auto flex items-center justify-between gap-3 py-4 pl-6 pr-10 mr-2 bg-white md:mr-0 bottom-8 md:max-w-full md:px-10 md:gap-10 w-fit">
                <div className="flex flex-col items-start justify-center gap-1 w-fit">
                  <h2 className="text-lg font-semibold leading-none sm:text-xl md:text-2xl font-display whitespace-nowrap">{eventData.title}</h2>
                  <DateTimeDisplay />
                </div>
                <button className="px-6 py-3 font-semibold leading-3 tracking-wide text-white uppercase transition-all bg-black border border-black font-body hover:text-black hover:bg-transparent">
                  RSVP
                </button>
              </div>
            </div>
          </div>
          <main className="w-full mx-auto max-w-content">
            <div className="flex flex-col items-start justify-start gap-4 px-6 py-2 mb-4 sm:px-10 sm:py-6">
              <section className="flex flex-col items-start justify-between w-full gap-4 mb-4 md:flex-row">
                <div className="flex flex-col items-start justify-start gap-1">
                  <h1 className="max-w-4xl mb-1 text-2xl font-medium leading-none tracking-wide uppercase sm:text-3xl sm:mb-2 font-display">
                    {eventData.title}
                  </h1>
                  <DateTimeDisplay />
                  <Location />
                </div>

                <div className="flex flex-col items-end justify-start gap-2">
                  <div className="flex items-center justify-end gap-2">
                    <FacebookShareButton
                      url={`${process.env.REACT_APP_HOST}/events/${eventData.eventId}`}
                      hashtag={`#${process.env.REACT_APP_INSTITUTION_SLUG.replaceAll("-", "")}`}
                    >
                      <Button icon={<FacebookF size={14} />} type="primary" size="small">
                        Share
                      </Button>
                    </FacebookShareButton>
                    <TwitterShareButton url={`${process.env.REACT_APP_HOST}/events/${eventData.eventId}`} title={eventData.title}>
                      <BlackButton>
                        <Button icon={<Twitter size={14} />} type="primary" size="small">
                          Tweet
                        </Button>
                      </BlackButton>
                    </TwitterShareButton>
                    <EmailShareButton
                      url={`${process.env.REACT_APP_HOST}/events/${eventData.eventId}`}
                      subject={`Check out this event - ${eventData.title} - ${process.env.REACT_APP_INSTITUTION_NAME}`}
                      body={eventData.summary + `\n\n\n`}
                    >
                      <Button icon={<Email size={14} />} type="primary" size="small">
                        Email
                      </Button>
                    </EmailShareButton>
                    <RedditShareButton
                      url={`${process.env.REACT_APP_HOST}/events/${eventData.eventId}`}
                      title={eventData.title}
                      windowWidth={660}
                      windowHeight={460}
                    >
                      <RedditButton>
                        <Button icon={<Reddit size={14} />} type="primary" size="small">
                          Share
                        </Button>
                      </RedditButton>
                    </RedditShareButton>
                  </div>
                  <div className="flex flex-row items-center justify-end gap-2">
                    <p className="text-xs font-semibold text-black/50">CATEGORY: </p>
                    <div className={classNames(getEventStatusClasses(eventData.status), "rounded-md flex-none py-1 px-2 text-xs ring-1 ring-inset capitalize")}>
                      {EVENT_CATEGORIES.find((c) => c.value === eventData.category).label}
                    </div>
                  </div>
                </div>
              </section>
              <section className="w-full mt-3 mb-6 text-base italic leading-none font-display sm:mb-8 text-black/50">
                <p className="text-sm leading-none">{eventData.summary}</p>
              </section>
              <section className="w-full mt-3 mb-6 sm:mb-8">
                <TailwindHtmlRenderer html={eventData?.description} />
              </section>
              <section className="w-full mt-3 mb-6 sm:mb-8">
                <TicketSection />
              </section>
              {eventData.images.length > 1 && (
                <section className="w-full mt-3 mb-6 sm:mb-8">
                  <h4 className="max-w-4xl mb-4 text-2xl font-medium leading-none tracking-wide md:text-2xl sm:mb-4 font-display">Event Images</h4>
                  <Image.PreviewGroup>
                    {eventData?.images?.map((image, index) => {
                      return (
                        index !== 0 && (
                          <Image key={index} src={image.previewUrl} alt={image.title} width={250} fallback={process.env.REACT_APP_BASE_FEATURED_IMG} />
                        )
                      );
                    })}
                  </Image.PreviewGroup>
                </section>
              )}
              <PreFooter />
            </div>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

const BlackButton = styled.div`
  .ant-btn-primary {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }
`;

const RedditButton = styled.div`
  .ant-btn-primary {
    background-color: #ff4500 !important;
    border-color: #ff4500 !important;
  }
`;

export default EventPage;
