const TailwindHtmlRenderer = ({ html, preview }) => {
  return (
    <div
      className={`w-full ${preview ? "px-8 py-6 text-white border border-white/5 mt-2" : "prose-sm max-w-content"}`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default TailwindHtmlRenderer;
