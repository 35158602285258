import { useState, useEffect, useCallback } from "react";
import { Button, Checkbox, DatePicker, Image, Input, InputNumber, Modal, Progress, Radio, Select, Switch, Table, TimePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { EVENT_CATEGORIES, EVENT_VISIBILITY } from "../../../lib/eventEnums";
import { AddressAutofill } from "@mapbox/search-js-react";
import dayjs from "dayjs";
import ImageUpload from "../../../components/imageUpload";
import { UploadData } from "../../../actions/data";
import MarkdownEditor from "../../../components/markdownEditor";
import processHtmlForTailwind from "../../../components/processHtmlForTailwind";
import { GetUser, ManagementCheck } from "../../../actions/auth";
import { v4 as uuidv4, validate as validateUUID } from "uuid";
import { formatCurrency } from "../../../components/utilities";
import toast from "react-hot-toast";
import { Edit, Trash2 } from "lucide-react";
import TailwindHtmlRenderer from "../../../components/tailwindHtmlRenderer";
import { CreateEvent } from "../../../actions/mgmt";

const CreateNewEvent = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(0);
  const [address, setAddress] = useState("");
  const [locationType, setLocationType] = useState(null);
  const [eventType, setEventType] = useState("single-day");
  const [showTime, setShowTime] = useState([]);
  const [eventDate, setEventDate] = useState({ startDate: null, endDate: null, startTime: null, endTime: null });
  const [data, setData] = useState({
    title: "",
    category: "",
    visibility: "",
  });
  const [images, setImages] = useState([]);
  const [canvaImages, setCanvaImages] = useState([]);
  const [canvaLoading, setCanvaLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [proceedWithoutImages, setProceedWithoutImages] = useState(false);
  const [canvaAvailable, setCanvaAvailable] = useState(false);
  const [hasTickets, setHasTickets] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [ticketModal, setTicketModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [ticketData, setTicketData] = useState({
    price: null,
    description: "",
    quantity: null,
  });
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let inView = true;

    if (inView) {
      reloadData();
    }

    return () => {
      inView = false;
    };
  }, []);

  useEffect(() => {
    if (images.length + canvaImages.length > 0) {
      setProceedWithoutImages(false);
    }
  }, [images]);

  const reloadData = () => {
    ManagementCheck()
      .then((res) => {
        GetUser()
          .then((res) => {
            setUserId(res.data.userId);
            if (res.data?.integrations?.canva?.active) {
              setCanvaAvailable(true);
            } else {
              setCanvaAvailable(false);
            }
          })
          .catch((err) => {
            setCanvaAvailable(false);
          });
      })
      .catch((err) => {
        setCanvaAvailable(false);
      });
  };

  const getProgress = () => {
    return step * 20;
  };

  const handleRetrieve = useCallback((res) => {
    let tmpFeature = res.features[0];
    if (tmpFeature) {
      setAddress(tmpFeature.properties.full_address);
    }
  });

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const dateRangeValue = () => {
    if (eventDate.startDate && eventDate.endDate) {
      if (dayjs(eventDate.startDate).isValid() && dayjs(eventDate.endDate).isValid()) {
        if (dayjs(eventDate.endDate).isAfter(dayjs(eventDate.startDate), "day")) {
          return [dayjs(eventDate.startDate), dayjs(eventDate.endDate)];
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const submitStep = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setData((prevState) => ({ ...prevState, locationType: locationType, address: address }));
      setStep(2);
    } else if (step === 2) {
      setData((prevState) => ({ ...prevState, ...eventDate, showTime: showTime, eventType: eventType }));
      setStep(3);
    } else if (step === 3) {
      if (proceedWithoutImages && images.length + canvaImages.length === 0) {
        setData((prevState) => ({
          ...prevState,
          images: [],
          canvaImages: [],
          summary,
          description: processHtmlForTailwind(description),
          rawDescription: description,
        }));
        setStep(4);
      } else if (!proceedWithoutImages && images.length + canvaImages.length > 0) {
        if (images.length === 0) {
          setData((prevState) => ({
            ...prevState,
            images: [],
            canvaImages: canvaImages,
            summary,
            description: processHtmlForTailwind(description),
            rawDescription: description,
          }));
          setStep(4);
        } else {
          setIsUploading(true);
          const formData = new FormData();
          images.forEach((image, index) => {
            formData.append(`image${index}`, image.file, image.file.name);
            formData.append(`image${index}IsPrimary`, image.isPrimary);
            formData.append(`image${index}Id`, image.id);
          });
          formData.append("for", data.title);
          UploadData(formData)
            .then((res) => {
              setIsUploading(false);
              const updatedImages = images.map((image) => {
                const matchingResponse = res.data.find((item) => item.id === image.id);
                return {
                  ...image,
                  previewUrl: matchingResponse ? matchingResponse.url : image.previewUrl,
                };
              });
              setImages(updatedImages);
              setData((prevState) => ({
                ...prevState,
                images: updatedImages,
                canvaImages: canvaImages,
                summary,
                description: processHtmlForTailwind(description),
                rawDescription: description,
              }));
              setStep(4);
              setIsUploading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsUploading(false);
            });
        }
      }
    } else if (step === 4) {
      if (hasTickets === null) {
        setHasTickets(false);
        setData((prevState) => ({ ...prevState, tickets: [], hasTickets: false }));
        setStep(5);
      } else {
        setData((prevState) => ({ ...prevState, tickets: tickets, hasTickets: true }));
        setStep(5);
        cancelTicketModal();
      }
    } else if (step === 5) {
      setIsUploading(true);
      CreateEvent(data)
        .then((res) => {
          toast.success("Event created successfully");
          navigate(`/management/events/${res.data.eventId}`);
        })
        .catch((err) => {
          setIsUploading(false);
          toast.error("An error occurred while creating the event");
        });
    }
  };

  const renderLocationType = (type) => {
    switch (type) {
      case "online":
        return "Online";
      case "institution":
        return "At the institution";
      case "address":
        return "At a different location";
      default:
        return "";
    }
  };

  const renderStep1 = () => {
    return (
      <>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <label className="block pl-1 text-sm font-medium text-white/50">
            Event title <span className="text-red-500">*</span>
          </label>
          <Input placeholder="Event title" defaultValue={data?.title} onChange={(e) => setData((prevState) => ({ ...prevState, title: e.target.value }))} />
        </div>
        <div className="flex flex-col items-start justify-start w-full col-span-2 gap-2">
          <label className="block pl-1 text-sm font-medium text-white/50">
            Event category <span className="text-red-500">*</span>
          </label>
          <Select
            options={EVENT_CATEGORIES}
            placeholder="Select the event category"
            className="w-full"
            defaultValue={data?.category}
            onChange={(e) => setData((prevState) => ({ ...prevState, category: e }))}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full col-span-2 gap-2">
          <label className="block pl-1 text-sm font-medium text-white/50">
            Event visibility <span className="text-red-500">*</span>
          </label>
          <Select
            options={EVENT_VISIBILITY}
            placeholder="Select the event visibility"
            className="w-full"
            defaultValue={data?.visibility}
            onChange={(e) => setData((prevState) => ({ ...prevState, visibility: e }))}
          />
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <div className="flex flex-col items-start justify-start w-full col-span-2 gap-2">
        <label className="block pl-1 text-sm font-medium text-white/50">
          Event location <span className="text-red-500">*</span>
        </label>
        <Radio.Group
          options={[
            { label: "Online", value: "online" },
            { label: "At the institution", value: "institution" },
            { label: "At a different location", value: "address" },
          ]}
          onChange={({ target: { value } }) => setLocationType(value)}
          optionType="button"
          buttonStyle="solid"
          defaultValue={data?.locationType}
        />
        {locationType === "address" && (
          <div className="w-full mt-2">
            <AddressAutofill
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onRetrieve={(r) => handleRetrieve(r)}
              options={{ language: "en", country: "US" }}
              className="w-full"
            >
              <Input placeholder="Start typing the event address..." onChange={(e) => setAddress(e.target.value)} value={address} />
            </AddressAutofill>
          </div>
        )}
      </div>
    );
  };

  const renderStep3 = () => {
    return (
      <div className="flex flex-col items-start justify-start w-full col-span-2 gap-2">
        <label className="block pl-1 text-sm font-medium text-white/50">
          Event Date & Time <span className="text-red-500">*</span>
        </label>
        <div className="flex flex-row items-center justify-between w-full gap-2">
          <Radio.Group
            options={[
              { label: "Single-Day Event", value: "single-day" },
              { label: "Multi-Day Event", value: "multi-day" },
            ]}
            onChange={({ target: { value } }) => setEventType(value)}
            optionType="button"
            buttonStyle="solid"
            defaultValue={eventType}
          />
          {eventType === "multi-day" ? (
            <DatePicker.RangePicker
              className="py-1.5 px-5"
              disabledDate={disabledDate}
              onChange={(v, s) =>
                setEventDate((prevState) => ({
                  ...prevState,
                  startDate: v[0].toJSON(),
                  endDate: v[1].toJSON(),
                }))
              }
              value={dateRangeValue()}
              defaultValue={[
                dayjs(eventDate?.startDate).isValid() ? dayjs(eventDate?.startDate) : null,
                dayjs(eventDate?.endDate).isValid() ? dayjs(eventDate?.endDate) : null,
              ]}
            />
          ) : (
            <div className="flex flex-row items-center justify-start gap-2">
              <DatePicker
                className="py-1.5 px-5"
                needConfirm={false}
                disabledDate={disabledDate}
                value={eventDate.startDate && dayjs(eventDate.startDate).isValid() ? dayjs(eventDate.startDate) : null}
                onChange={(v, s) => setEventDate((prevState) => ({ ...prevState, startDate: dayjs(v).toJSON() }))}
                defaultValue={dayjs(eventDate?.startDate).isValid() ? dayjs(eventDate?.startDate) : null}
              />
              <TimePicker
                className="py-1.5 px-5"
                format="hh:mm A"
                needConfirm={false}
                placeholder="Start Time"
                onChange={(d, s) => setEventDate((prevState) => ({ ...prevState, startTime: dayjs(d).toJSON() }))}
                value={eventDate.startTime && dayjs(eventDate.startTime).isValid() ? dayjs(eventDate.startTime) : null}
                defaultValue={dayjs(eventDate?.startTime).isValid() ? dayjs(eventDate?.startTime) : null}
              />
              <TimePicker
                className="py-1.5 px-5"
                format="hh:mm A"
                needConfirm={false}
                placeholder="End Time"
                onChange={(d, s) => setEventDate((prevState) => ({ ...prevState, endTime: dayjs(d).toJSON() }))}
                value={eventDate.endTime && dayjs(eventDate.endTime).isValid() ? dayjs(eventDate.endTime) : null}
                defaultValue={dayjs(eventDate?.endTime).isValid() ? dayjs(eventDate?.endTime) : null}
              />
            </div>
          )}
        </div>
        {eventType === "single-day" && (
          <div className="flex flex-row items-center justify-end w-full gap-2 mt-2">
            <Checkbox.Group
              options={[
                { label: "Display start time", value: "startTime" },
                { label: "Display end time", value: "endTime" },
              ]}
              value={showTime}
              defaultValue={showTime}
              onChange={(v) => setShowTime(v)}
            />
          </div>
        )}
      </div>
    );
  };

  const renderStep4 = () => {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-6">
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <h3 className="text-2xl font-bold">Main event image</h3>
          <p className="text-sm">Add photos to show what your event will be about. You can upload up to 10 images.</p>
          <ImageUpload
            images={images}
            setImages={setImages}
            hasCanva={canvaAvailable}
            canvaImages={canvaImages}
            setCanvaImages={setCanvaImages}
            eventTitle={data.title}
            setCanvaLoading={setCanvaLoading}
            userId={userId}
          />
          <Checkbox
            checked={proceedWithoutImages}
            onChange={({ target: { checked } }) => setProceedWithoutImages(checked)}
            disabled={images.length + canvaImages.length > 0}
          >
            Proceed without images (You won't be able to publish the event until at least one image is added)
          </Checkbox>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <h3 className="text-2xl font-bold">Summary</h3>
          <p className="text-sm">
            Grab people's attention with a short description of your event. Attendees will see this at the top of your event page. (140 characters max)
          </p>
          <div className="w-full p-4">
            <Input.TextArea
              rows={4}
              maxLength={140}
              className="!resize-none"
              placeholder="Provide a brief summary for the event..."
              onChange={({ target: { value } }) => updateSummary(value)}
            />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <h3 className="text-2xl font-bold">Description</h3>
          <p className="text-sm">
            Add a more detailed description to your event. Tell attendees what to expect, how to prepare, and any other important details.
          </p>
          <MarkdownEditor onChange={(v) => updateDescription(v)} />
        </div>
      </div>
    );
    //TODO: Add more sections (agenda, attendees, FAQ)
  };

  const renderStep5 = () => {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-6">
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="text-2xl font-bold">Manage tickets & admission for your event</h3>
            {hasTickets !== null && <Button onClick={() => setTicketModal(true)}>Add Tickets</Button>}
          </div>
          {hasTickets === null ? (
            <div className="w-full mx-auto">
              <div className="relative px-6 py-24 overflow-hidden text-center isolate sm:rounded-3xl sm:px-16">
                <h2 className="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-white sm:text-4xl">Does your event have tickets?</h2>
                <p className="max-w-xl mx-auto mt-6 text-lg leading-8 text-gray-300">
                  If you wish to limit availability, or collect an admission fee, you can create tickets for your event. Otherwise select no to continue.
                </p>
                <div className="flex items-center justify-center max-w-sm mx-auto mt-10 gap-x-6">
                  <Button size="large" block onClick={() => submitStep()}>
                    No
                  </Button>
                  <Button size="large" block type="primary" onClick={() => setHasTickets(true)}>
                    Yes
                  </Button>
                </div>
                <svg
                  viewBox="0 0 1024 1024"
                  aria-hidden="true"
                  className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                >
                  <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
                  <defs>
                    <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                      <stop stopColor="#7775D6" />
                      <stop offset={1} stopColor="#E935C1" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
            </div>
          ) : (
            <div className="flex-col items-center justify-start w-full h-full gap-3 mb-4">
              <div className="w-full h-full overflow-y-auto">
                <Table dataSource={tickets} className="h-full" pagination={false} sticky={true}>
                  <Table.Column title="Title" key={uuidv4()} width={"50%"} dataIndex="title" render={(title) => <p className="font-semibold">{title}</p>} />
                  <Table.Column
                    title="Price"
                    align="right"
                    key={uuidv4()}
                    width={"15%"}
                    dataIndex="price"
                    render={(price) => <p className="font-semibold">{formatCurrency(price, true)}</p>}
                  />
                  <Table.Column
                    title="Quantity"
                    align="right"
                    key={uuidv4()}
                    width={"15%"}
                    dataIndex="quantity"
                    render={(quantity) => <p className="font-semibold">{quantity}</p>}
                  />
                  <Table.Column
                    title=""
                    key={uuidv4()}
                    width={"20%"}
                    dataIndex="ticketId"
                    render={(ticketId) => (
                      <div className="flex flex-row items-center justify-end w-full gap-2">
                        <Button onClick={() => editTicket(ticketId)}>Edit</Button>
                        <Button onClick={() => deleteTicket(ticketId)} danger>
                          Delete
                        </Button>
                      </div>
                    )}
                  />
                </Table>
                <Modal
                  open={ticketModal}
                  onCancel={() => cancelTicketModal()}
                  onClose={() => cancelTicketModal()}
                  onOk={() => submitTicketModal()}
                  centered
                  destroyOnClose
                  width={750}
                  title="Add a new ticket"
                >
                  <div className="grid grid-cols-2 gap-4 my-6">
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                      <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                        Price <span className="text-red-500">*</span>
                        <span className="text-xs italic font-base text-white/20"> (enter 0 if you just want to limit admissions)</span>
                      </label>
                      <InputNumber
                        placeholder="Admission Price"
                        className="w-full"
                        step={0.01}
                        min={0}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, price: e }))}
                        prefix="$"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                      <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                        Quantity available <span className="text-red-500">*</span>
                      </label>
                      <InputNumber
                        placeholder="Available quantity"
                        className="w-full"
                        step={1}
                        min={1}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, quantity: e }))}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full col-span-2 mt-3">
                      <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
                        Description <span className="text-red-500">*</span>
                      </label>
                      <Input.TextArea
                        placeholder="Ticket Description"
                        className="!resize-none"
                        rows={4}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, description: e.target.value }))}
                      />
                    </div>
                  </div>
                </Modal>
                <Modal
                  open={editModal}
                  onCancel={() => cancelTicketModal()}
                  onClose={() => cancelTicketModal()}
                  onOk={() => submitTicketEdit()}
                  centered
                  destroyOnClose
                  width={750}
                  title="Edit ticket"
                >
                  <div className="grid grid-cols-2 gap-4 my-6">
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                      <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                        Price <span className="text-red-500">*</span>
                        <span className="text-xs italic font-base text-white/20"> (enter 0 if you just want to limit admissions)</span>
                      </label>
                      <InputNumber
                        placeholder="Admission Price"
                        className="w-full"
                        step={0.01}
                        min={0}
                        defaultValue={ticketData.price}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, price: e }))}
                        prefix="$"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                      <label htmlFor="price" className="block pl-1 text-sm font-medium text-white/50">
                        Quantity available <span className="text-red-500">*</span>
                      </label>
                      <InputNumber
                        placeholder="Available quantity"
                        className="w-full"
                        step={1}
                        min={1}
                        defaultValue={ticketData.quantity}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, quantity: e }))}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full col-span-2 mt-3">
                      <label htmlFor="description" className="block pb-2 pl-1 text-sm font-medium text-white/50">
                        Description <span className="text-red-500">*</span>
                      </label>
                      <Input.TextArea
                        placeholder="Ticket Description"
                        className="!resize-none"
                        rows={4}
                        onChange={(e) => setTicketData((prevState) => ({ ...prevState, description: e.target.value }))}
                        defaultValue={ticketData.description}
                      />
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderStep6 = () => {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-6">
        <div className="flex flex-col items-start justify-start w-full gap-5 divide-y divide-white/25">
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-6 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h3 className="text-2xl font-bold">{stepTitles[0].title}</h3>
              <Button icon={<Edit size={16} />} onClick={() => goBack(0)} />
            </div>
            <dl className="w-full divide-y divide-white/10">
              <div className="grid grid-cols-3 gap-4 px-4 py-6">
                <dt className="text-sm font-semibold leading-6 text-white/90">Event Title</dt>
                <dd className="col-span-2 mt-0 text-sm leading-6 text-white/50">{data?.title}</dd>
              </div>
              <div className="grid grid-cols-3 gap-4 px-4 py-6">
                <dt className="text-sm font-semibold leading-6 text-white/90">Event Category</dt>
                <dd className="col-span-2 mt-0 text-sm leading-6 text-white/50">{EVENT_CATEGORIES.find((c) => c.value === data?.category).label}</dd>
              </div>
              <div className="grid grid-cols-3 gap-4 px-4 py-6">
                <dt className="text-sm font-semibold leading-6 text-white/90">Event Visibility</dt>
                <dd className="col-span-2 mt-0 text-sm leading-6 text-white/50">{EVENT_VISIBILITY.find((v) => v.value === data?.visibility).label}</dd>
              </div>
            </dl>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-6 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h3 className="text-2xl font-bold">{stepTitles[1].title}</h3>
              <Button icon={<Edit size={16} />} onClick={() => goBack(1)} />
            </div>
            <dl className="w-full divide-y divide-white/10">
              <div className="grid grid-cols-3 gap-4 px-4 py-6">
                <dt className="text-sm font-semibold leading-6 text-white/90">Event Location</dt>
                <dd className="col-span-2 mt-0 text-sm leading-6 text-white/50">{renderLocationType(data?.locationType)}</dd>
              </div>
              {data?.locationType === "address" && (
                <div className="grid grid-cols-3 gap-4 px-4 py-6">
                  <dt className="text-sm font-semibold leading-6 text-white/90">Event Address</dt>
                  <dd className="col-span-2 mt-0 text-sm leading-6 text-white/50">{data?.address}</dd>
                </div>
              )}
            </dl>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-6 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h3 className="text-2xl font-bold">{stepTitles[2].title}</h3>
              <Button icon={<Edit size={16} />} onClick={() => goBack(2)} />
            </div>
            <dl className="w-full divide-y divide-white/10">
              <div className="grid grid-cols-3 gap-4 px-4 py-6">
                <dt className="text-sm font-semibold leading-6 text-white/90">Event Type</dt>
                <dd className="col-span-2 mt-0 text-sm leading-6 capitalize text-white/50">{data?.eventType}</dd>
              </div>
              <div className="grid grid-cols-3 gap-4 px-4 py-6">
                <dt className="text-sm font-semibold leading-6 text-white/90">Start Date</dt>
                <dd className="col-span-2 mt-0 text-sm leading-6 capitalize text-white/50">{data?.startDate && dayjs(data?.startDate).format("MM/DD/YYYY")}</dd>
              </div>
              {data?.showTime?.includes("startTime") && (
                <div className="grid grid-cols-3 gap-4 px-4 py-6">
                  <dt className="text-sm font-semibold leading-6 text-white/90">Start Time</dt>
                  <dd className="col-span-2 mt-0 text-sm leading-6 capitalize text-white/50">{data?.startTime && dayjs(data?.startTime).format("hh:mm A")}</dd>
                </div>
              )}
              {data?.eventType === "multi-day" && (
                <div className="grid grid-cols-3 gap-4 px-4 py-6">
                  <dt className="text-sm font-semibold leading-6 text-white/90">End Date</dt>
                  <dd className="col-span-2 mt-0 text-sm leading-6 capitalize text-white/50">{data?.endDate && dayjs(data?.endDate).format("MM/DD/YYYY")}</dd>
                </div>
              )}
              {data?.showTime?.includes("endTime") && (
                <div className="grid grid-cols-3 gap-4 px-4 py-6">
                  <dt className="text-sm font-semibold leading-6 text-white/90">End Time</dt>
                  <dd className="col-span-2 mt-0 text-sm leading-6 capitalize text-white/50">{data?.endTime && dayjs(data?.endTime).format("hh:mm A")}</dd>
                </div>
              )}
            </dl>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-6 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h3 className="text-2xl font-bold">{stepTitles[3].title}</h3>
              <Button icon={<Edit size={16} />} onClick={() => goBack(3)} />
            </div>
            <h5 className="w-full px-4 pt-6 text-base font-semibold leading-6 text-white/90">Event Images</h5>
            {(data?.images?.length || 0) + (data?.canvaImages?.length || 0) === 0 ? (
              <p className="w-full px-4 mt-0 text-sm leading-6 capitalize text-white/50">No images were added</p>
            ) : (
              <div className="flex items-center justify-start w-full gap-2 px-4 py-6 overflow-x-auto">
                <Image.PreviewGroup>
                  {data?.images?.concat(data?.canvaImages)?.map((img) => (
                    <Image src={img?.previewUrl} width={200} fallback={process.env.REACT_APP_CANVA_IN_PROGRESS} preview={{ toolbarRender: () => {} }} />
                  ))}
                </Image.PreviewGroup>
              </div>
            )}
            <h5 className="w-full px-4 pt-6 text-base font-semibold leading-6 text-white/90">Event Summary</h5>
            <p className="w-full px-4 mt-0 text-sm leading-6 capitalize text-white/50">{data?.summary}</p>
            <h5 className="w-full px-4 pt-6 text-base font-semibold leading-6 text-white/90">Event Description</h5>
            <TailwindHtmlRenderer html={data?.description} preview={true} />
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-6 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h3 className="text-2xl font-bold">{stepTitles[4].title}</h3>
              <Button icon={<Edit size={16} />} onClick={() => goBack(4)} />
            </div>
            <Table dataSource={tickets} className="w-full h-full px-6" pagination={false} sticky={true}>
              <Table.Column title="Title" key={uuidv4()} width={"50%"} dataIndex="title" render={(title) => <p className="font-semibold">{title}</p>} />
              <Table.Column
                title="Price"
                align="right"
                key={uuidv4()}
                width={"30%"}
                dataIndex="price"
                render={(price) => <p className="font-semibold">{formatCurrency(price, true)}</p>}
              />
              <Table.Column
                title="Quantity"
                align="right"
                key={uuidv4()}
                width={"20%"}
                dataIndex="quantity"
                render={(quantity) => <p className="font-semibold">{quantity}</p>}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  };

  const cancelTicketModal = () => {
    setEditModal(false);
    setTicketModal(false);
    setTicketData({
      price: null,
      description: "",
      quantity: null,
    });
  };

  const submitTicketModal = () => {
    if (ticketData.price === null) {
      toast.error("Please enter a valid admission price");
    } else if (ticketData.quantity === null || ticketData.quantity < 1) {
      toast.error("Please enter a valid quantity");
    } else if (ticketData.description === "" || ticketData.description.length < 10) {
      toast.error("Please enter a valid description");
    } else {
      const newTicket = {
        ...ticketData,
        title: data.title + " - Admission",
        ticketId: uuidv4(),
      };
      setTickets((prevState) => [...prevState, newTicket]);
      toast.success("Ticket added successfully");
      cancelTicketModal();
    }
  };

  const submitTicketEdit = () => {
    if (!ticketData.ticketId || !validateUUID(ticketData.ticketId)) {
      toast.error("Something went wrong, please try again");
      cancelTicketModal();
    } else if (ticketData.price === null) {
      toast.error("Please enter a valid admission price");
    } else if (ticketData.quantity === null || ticketData.quantity < 1) {
      toast.error("Please enter a valid quantity");
    } else if (ticketData.description === "" || ticketData.description.length < 10) {
      toast.error("Please enter a valid description");
    } else {
      const newTicket = {
        ...ticketData,
        title: data.title + " - Admission",
        ticketId: uuidv4(),
      };
      setTickets((prevState) => [...prevState, newTicket]);
      toast.success("Ticket added successfully");
      cancelTicketModal();
    }
  };

  const deleteTicket = (ticketId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this ticket?",
      content: "This action cannot be undone.",
      onOk: () => {
        setTickets((prevState) => prevState.filter((ticket) => ticket.ticketId !== ticketId));
        toast.success("Ticket deleted successfully");
      },
      onCancel: () => {},
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        danger: true,
      },
      centered: true,
      destroyOnClose: true,
      closable: false,
      icon: <Trash2 size={24} color="#f20c2e" className="pr-2" />,
    });
  };

  const editTicket = (id) => {
    let ticket = tickets.find((item) => item.ticketId === id);
    if (ticket) {
      setTicketData(ticket);
      setEditModal(true);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const updateSummary = useCallback((newContent) => {
    setSummary(newContent);
  }, []);

  const updateDescription = useCallback((newContent) => {
    setDescription(newContent);
  }, []);

  const renderStep = () => {
    switch (step) {
      case 0:
        return renderStep1();
      case 1:
        return renderStep2();
      case 2:
        return renderStep3();
      case 3:
        return renderStep4();
      case 4:
        return renderStep5();
      case 5:
        return renderStep6();
      default:
        return renderStep1();
    }
  };

  const submitDisabled = () => {
    if (step === 0) {
      return data.title === "" || data.category === "" || data.visibility === "";
    } else if (step === 1) {
      return locationType === null || (locationType === "address" && address === "");
    } else if (step === 2) {
      if (eventType === "single-day") {
        return (
          eventDate.startDate === null ||
          (showTime.includes("startTime") && eventDate.startTime === null) ||
          (showTime.includes("endTime") && eventDate.endTime === null) ||
          (showTime.includes("startTime") && showTime.includes("endTime") && dayjs(eventDate.endTime).isBefore(dayjs(eventDate.startTime), "minute"))
        );
      } else {
        return !dayjs(eventDate.startDate).isValid() || !dayjs(eventDate.endDate).isValid();
      }
    } else if (step === 3) {
      return summary === "" || description === "" || (!proceedWithoutImages && images.length + canvaImages.length === 0) || canvaLoading;
    } else if (step === 4) {
      return hasTickets === null || tickets.length === 0;
    }
  };

  const stepTitles = [
    {
      title: "Basic Info",
      description: "Name your event and tell event-goers why they should come. Add details that highlight what makes it unique.",
    },
    {
      title: "Event Location",
      description: "Help people in the area discover your event and let attendees know where to show up.",
    },
    {
      title: "Event Date & Time",
      description: "Tell event-goers when your event starts and ends so they can make plans to attend.",
    },
    {
      title: "Details & Description",
      description: "Write a brief overview of your event. This will be the first thing people see when they find your event.",
    },
    {
      title: "Tickets & Admission",
      description: "Add tickets for your event. You can always change this later, if needed.",
    },
    {
      title: "Review",
      description: "Make sure everything looks good, then click publish to make your event live. You can always update it later.",
    },
  ];

  const goBack = (goTo) => {
    let newStep = goTo;
    if (!goTo) {
      if (step > 0) {
        newStep = step - 1;
      }
    }
    if (newStep >= 0) {
      if (newStep === 1) {
        setLocationType(data?.locationType);
        setAddress(data?.address);
      } else if (newStep === 2) {
        setEventDate({
          startDate: data?.startDate,
          endDate: data?.endDate,
          startTime: data?.startTime,
          endTime: data?.endTime,
        });
        setShowTime(data?.showTime);
        setEventType(data?.eventType);
      } else if (newStep === 3) {
        setImages(data?.images);
        setCanvaImages(data?.canvaImages);
        setSummary(data?.summary);
        setDescription(data?.description);
      } else if (newStep === 4) {
        setHasTickets(data?.hasTickets);
        setTickets(data?.tickets);
      }
      setStep(newStep);
    } else {
      const thereIsAPrevPage = location.key !== "default";
      if (thereIsAPrevPage) {
        navigate(-1);
      } else {
        navigate("/management/events");
      }
    }
  };

  const renderLowerControls = () => {
    let submitTitle = "Next";
    if (isUploading) {
      submitTitle = "Uploading...";
    }
    if (step === 5) {
      submitTitle = "Create Event";
      if (isUploading) {
        submitTitle = "Processing...";
      }
    }
    return (
      <div className="flex items-center justify-end w-full gap-2 pt-3 mt-3 border-t border-white/20">
        <Button onClick={() => goBack()}>{step === 0 ? "Cancel" : "Back"}</Button>
        <Button
          onClick={() => submitStep()}
          type="primary"
          disabled={submitDisabled()}
          className="disabled:!bg-blue-700/40 disabled:!text-blue-300 disabled:!border-none"
        >
          {submitTitle}
        </Button>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-3 p-10 overflow-y-auto text-white overflow-x-clip min-h-[calc(100vh-80px)]">
      <Helmet>
        <title>New Event | {process.env.REACT_APP_INSTITUTION_NAME}</title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <h1 className="text-xl font-bold">New Event</h1>
        <button
          className="px-4 py-2 text-xs font-bold uppercase duration-150 ease-in-out border border-white hover:bg-white hover:text-black"
          onClick={() => navigate("/management/events")}
        >
          Cancel & Go Back
        </button>
      </div>
      <div className="flex flex-col items-start justify-center w-full max-w-content">
        <div className="flex items-center justify-center w-full py-3">
          <Progress
            percent={getProgress()}
            percentPosition={{
              align: "start",
              type: "outer",
            }}
            strokeColor="#ffffff"
            className="px-10"
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-3 px-10 pt-3 mt-1 border-t border-white/20">
          <div className="flex flex-col items-center justify-start w-2/3 gap-5 mx-auto my-5">
            <div className="flex flex-col items-start justify-start w-full pb-5">
              <p className="font-sans text-2xl font-bold uppercase">
                Step {step}/5 - {stepTitles?.[step]?.title ?? ""}
              </p>
              <p className="max-w-md -mt-0 text-sm font-medium">{stepTitles?.[step]?.description ?? ""}</p>
            </div>
            {renderStep()}
            {step === 4 && hasTickets === null ? null : renderLowerControls()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewEvent;
