import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CheckPaymentIntent } from "../actions/payments";
import { useCart } from "react-use-cart";

const CheckoutForm = ({ orderId }) => {
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { setItems } = useCart();

  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: "if_required",
    });

    if (error) {
      setErrorMessage(error.message);
      toast.error(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      CheckPaymentIntent(orderId)
        .then((res) => {
          if (res.data.status !== "requires_action") {
            toast.success("Purchase Successful!");
            setItems([]);
            navigate(`/checkout/success/${orderId}`);
          } else {
            toast.error("Additional action required!");
            setErrorMessage("Additional action required!");
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrorMessage("Additional action required!");
          setLoading(true);
        });
    } else {
      setErrorMessage("Something went wrong, please try again.");
      setLoading(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <PaymentElement readOnly={true} />
      <div className="flex flex-row items-center justify-end w-full mt-5">
        <button
          className="px-10 py-3 font-semibold tracking-wide text-white uppercase transition-all bg-black border border-black font-body hover:text-black hover:bg-transparent disabled:bg-slate-600 disabled:border-gray-300 disabled:cursor-not-allowed disabled:hover:text-white"
          disabled={!stripe || !elements || loading}
        >
          {loading ? "Processing" : "Pay"}
        </button>
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
