import { useState, useRef, useEffect } from "react";
import QRCodeStyling from "qr-code-styling";
import QRData from "../assets/qrData";
import toast from "react-hot-toast";

const QRCodeViewer = ({ title, url, type }) => {
  const [link, setLink] = useState(url);
  const ref = useRef(null);

  let data = QRData;
  data.data = link;
  const qrCode = new QRCodeStyling(data);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  const onDownloadClick = () => {
    let name = `${type} - ${title}`;
    qrCode.download({
      extension: "png",
      name: name,
    });
    toast.success("QR Code downloaded successfully!");
  };

  return (
    <div className="flex items-center justify-center cursor-pointer w-76 h-76" onClick={() => onDownloadClick()}>
      <div ref={ref} className="w-full h-full" />
    </div>
  );
};

export default QRCodeViewer;
