import axios from "axios";
import { store } from "../store/store";
import { logoutUser } from "../reducers/index";

let api_url = process.env.REACT_APP_API_URL;

let apiVersion = "v1";

api_url = api_url + "/" + apiVersion;

const axiosInstance = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
    "Coracle-Institution": process.env.REACT_APP_INSTITUTION_ID,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    let state = store.getState();
    const token = state.token;
    if (token) {
      config.headers["token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response.status === 440 && !originalConfig._retry) {
      console.log("AUTH CATCH - ERR");
      let state = store.getState();
      if (state.isAuth) {
        store.dispatch(logoutUser());
      }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
