import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetOneEvent } from "../../../actions/mgmt";
import { Button, Select } from "antd";
import EventBasicInfo from "./components/basicInfo";
import WhenAndWhere from "./components/whenAndWhere";
import EventMedia from "./components/eventMedia";
import EventDetails from "./components/eventDetails";
import EventTickets from "./components/eventTickets";
import dayjs from "dayjs";

const OpenEvent = () => {
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let inView = true;

    if (inView) {
      if (params && params.eventId && params.eventId !== "") {
        reloadData();
      } else {
        toast.error("Invalid Link");
        goBack();
      }
    }

    return () => {
      inView = false;
    };
  }, []);

  const reloadData = async () => {
    setLoading(true);
    GetOneEvent(params.eventId)
      .then((res) => {
        setEventData(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
        goBack();
      });
  };

  const goBack = () => {
    const thereIsAPrevPage = location.key !== "default";
    if (thereIsAPrevPage) {
      navigate(-1);
    } else {
      navigate("/management/events");
    }
  };

  const tabs = [
    { name: "Basic Info", value: 0, current: step === 0 },
    { name: "When and Where?", value: 1, current: step === 1 },
    { name: "Media", value: 2, current: step === 2 },
    { name: "Details", value: 3, current: step === 3 },
    { name: "Tickets", value: 4, current: step === 4 },
  ];

  const switchTab = (v) => {
    setStep(v);
  };

  const renderStep = () => {
    if (loading) {
      return (
        <div className="w-full h-full min-h-[60vh] flex justify-center items-center">
          <p className="text-2xl font-semibold leading-none tracking-wide animate-pulse font-display">Loading...</p>
        </div>
      );
    } else {
      if (step === 0) {
        return <EventBasicInfo eventData={eventData} reloadData={() => reloadData()} />;
      } else if (step === 1) {
        return <WhenAndWhere eventData={eventData} reloadData={() => reloadData()} />;
      } else if (step === 2) {
        return <EventMedia eventData={eventData} reloadData={() => reloadData()} />;
      } else if (step === 3) {
        return <EventDetails eventData={eventData} reloadData={() => reloadData()} />;
      } else if (step === 4) {
        return <EventTickets eventData={eventData} reloadData={() => reloadData()} />;
      }
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getEventStatusClasses = (status) => {
    if (status === "draft" || status === "archived") {
      return "text-gray-500 bg-gray-600/10 ring-gray-500/20";
    } else if (status === "published" || status === "completed") {
      return "text-green-400 bg-green-500/10 ring-green-600/20";
    } else if (status === "cancelled" || status === "deleted") {
      return "text-red-400 bg-red-500/10 ring-red-600/20";
    }
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-3 p-10 overflow-y-auto text-white overflow-x-clip min-h-[calc(100vh-80px)]">
      <Helmet>
        <title>
          {!loading && eventData?.title ? eventData?.title : "Event"} | {process.env.REACT_APP_INSTITUTION_NAME}
        </title>
      </Helmet>
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <div className="flex items-center justify-start gap-4">
          <h1 className="text-xl font-bold">{eventData?.title ? eventData?.title : ""}</h1>
          {!loading && (
            <>
              <div className={classNames(getEventStatusClasses(eventData.status), "rounded-md flex-none py-1 px-4 text-xs ring-1 ring-inset capitalize")}>
                Status: {eventData.status}
              </div>
            </>
          )}
        </div>
        <Button onClick={() => navigate("/management/events")}>Go Back</Button>
      </div>
      <div className="flex flex-col items-start justify-center w-full max-w-content">
        <div className="w-full">
          <div className="mx-auto max-w-7xl">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <Select
                id="eventEditNavTabs"
                defaultValue={tabs.find((tab) => tab.current).name}
                options={tabs.map((tab) => ({ label: tab.name, value: tab.value }))}
                placeholder="Select the event category"
                className="w-full"
                onChange={(v) => switchTab(v)}
              />
            </div>
            <div className="hidden sm:block">
              <nav className="flex py-4 border-b border-white/10">
                <ul role="list" className="flex flex-none min-w-full px-2 text-sm font-semibold leading-6 text-white/40 gap-x-6">
                  {tabs.map((tab) => (
                    <li key={tab.name}>
                      <p
                        className={`hover:text-blue-600 transition-all duration-300 cursor-pointer ${tab.current ? "text-white" : ""}`}
                        onClick={() => switchTab(tab.value)}
                      >
                        {tab.name}
                      </p>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <div className="flex-grow w-full h-full px-5 py-5 mx-auto max-w-7xl">{renderStep()}</div>
        </div>
      </div>
    </div>
  );
};

export default OpenEvent;
