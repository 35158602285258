import { useState, useEffect } from "react";
import { DatePicker, Input, InputNumber, Switch, Drawer, Select, Modal, Button } from "antd";
import toast from "react-hot-toast";
import { formatCurrency } from "../../../components/utilities";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { ArrowRight, Copy, Edit, Link, Plus, QrCode } from "iconoir-react";
import QRCodeViewer from "../../../components/QRCodeViewer";
import {
  GetOneMembership,
  AddPriceToMembership,
  UpdateMembershipInfo,
  UpdateMembershipPrice,
  UpdateMembershipBenefit,
  AddMembershipBenefit,
  DeleteMembershipBenefit,
  UpdateMembershipDiscounts,
} from "../../../actions/mgmt";
import { set } from "react-hook-form";
const HyprTK = require("hyprtk")(process.env.REACT_APP_HYPRTK_API_KEY);

const OpenMembership = () => {
  const [loading, setLoading] = useState(true);
  const [membershipData, setMembershipData] = useState([]);
  const [openPrice, setOpenPrice] = useState(false);
  const [openNewPrice, setOpenNewPrice] = useState(false);
  const [priceData, setPriceData] = useState({
    stripePriceId: "",
    active: false,
    currency: "usd",
    unitCost: 0,
    activeFrom: "",
    activeTo: "",
    changedFrom: "",
    changedTo: "",
    addedBy: "",
    removedBy: "",
  });
  const [activeVal, setActiveVal] = useState(false);
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [dateVal, setDateVal] = useState(null);
  const [priceVal, setPriceVal] = useState(0);

  const [editTitle, setEditTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const [linkSettings, setLinkSettings] = useState(false);
  const [qrSettings, setQrSettings] = useState(false);

  const [benefitsDrawer, setBenefitsDrawer] = useState(false);
  const [addBenefit, setAddBenefit] = useState(false);
  const [benefitData, setBenefitData] = useState({ benefit: "", description: "", benefitId: null });

  const [editDiscount, setEditDiscount] = useState(null);
  const [discountData, setDiscountData] = useState({ eligible: false, discount: 0, type: null });

  const [shortSlug, setShortSlug] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    let inView = true;

    if (inView) {
      if (params && params.membershipId && params.membershipId !== "") {
        reloadData();
      } else {
        toast.error("Invalid Link");
        goBack();
      }
    }

    return () => {
      inView = false;
    };
  }, []);

  const goBack = () => {
    const thereIsAPrevPage = location.key !== "default";
    if (thereIsAPrevPage) {
      navigate(-1);
    } else {
      navigate("/management/memberships");
    }
  };

  const reloadData = () => {
    GetOneMembership(params.membershipId)
      .then((res) => {
        setMembershipData(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
        goBack();
      });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const closeNewPrice = () => {
    setOpenNewPrice(false);
    setPriceData({
      stripePriceId: "",
      active: false,
      currency: "usd",
      unitCost: 0,
      activeFrom: "",
      activeTo: "",
      changedFrom: "",
      changedTo: "",
      addedBy: "",
      removedBy: "",
    });
    setActiveVal(false);
    setDateVal(null);
    setPriceVal(0);
  };

  const submitNewPrice = () => {
    if (!activeVal && !dateVal) {
      toast.error("Please select an active date for this price");
    } else {
      let data = {
        active: activeVal,
        unitCost: priceVal,
        activeFrom: dateVal,
      };
      if (activeVal) {
        data.activeFrom = null;
      }
      setLoading(true);
      AddPriceToMembership(params.membershipId, data)
        .then((res) => {
          toast.success("Successfully added a new price to the membership!");
          closeNewPrice();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while retrieving data, please try again.");
          goBack();
        });
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, "day").endOf("day");
  };

  const dateChange = (date, dateString) => {
    if (date) {
      setDateVal(date.toJSON());
    } else {
      setDateVal(null);
    }
  };

  const renderEditDiscount = () => {
    return (
      <Modal
        open={editDiscount}
        onClose={closeEditDiscount}
        centered
        destroyOnClose
        onCancel={closeEditDiscount}
        onOk={updateDiscount}
        title={`Edit ${editDiscount} discount`}
        okText="Update"
      >
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <div className="pb-4 -mt-3 text-left sm:-mt-5">
              <p className="text-sm text-gray-500">
                Edit ${editDiscount} discount for {membershipData.name}
              </p>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <div className="flex flex-row items-center justify-between w-full mt-5">
                <label htmlFor="eligible" className="block pl-1 text-sm text-white/90">
                  Enabled
                </label>
                <Switch className="bg-slate-800" checked={discountData.eligible} onChange={(v) => setDiscountData({ ...discountData, eligible: v })} />
              </div>
              <label htmlFor="discount" className="block pl-1 text-sm text-white/90">
                Discount <span className="text-red-500">*</span>
              </label>
              <InputNumber
                placeholder="Discount amount"
                className="w-full"
                step={0.01}
                min={0.01}
                onChange={(e) => setDiscountData({ ...discountData, discount: e })}
                defaultValue={discountData.discount}
                prefix="$"
                disabled={!discountData.eligible}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const closeEditDiscount = () => {
    setEditDiscount(false);
    setDiscountData({ eligible: false, discount: 0, type: null });
  };

  const updateDiscount = () => {
    setLoading(true);
    UpdateMembershipDiscounts(params.membershipId, discountData.type, { eligible: discountData.eligible, discount: discountData.discount })
      .then((res) => {
        toast.success("Discount Updated Successfully");
        closeEditDiscount();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the discount, please try again.");
        setLoading(false);
      });
  };

  const openEditDiscount = (type) => {
    setDiscountData({
      eligible: membershipData[type].eligible,
      discount: membershipData[type].discount,
      type: type,
    });
    setTimeout(() => setEditDiscount(true), 700);
  };

  const renderNewPriceModal = () => {
    return (
      <Modal
        open={openNewPrice}
        onClose={closeNewPrice}
        centered
        destroyOnClose
        onCancel={closeNewPrice}
        onOk={submitNewPrice}
        title="New price"
        okText="Add Price"
      >
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <div className="pb-4 -mt-3 text-left sm:-mt-5">
              <p className="text-sm text-gray-500">Add a price to the {membershipData.name}</p>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                Price <span className="text-red-500">*</span>
              </label>
              <InputNumber placeholder="Membership Price" className="w-full" step={0.01} min={0.01} onChange={(e) => setPriceVal(e)} prefix="$" />
              <div className="flex flex-row items-center justify-between w-full mt-5">
                <label htmlFor="description" className="block pl-1 text-sm text-white/90">
                  Replace the current price with this one?
                </label>
                <Switch className="bg-slate-800" onChange={setActiveVal} />
              </div>
              <div className="flex flex-row items-center justify-between w-full mt-5">
                <label htmlFor="description" className="pl-1 text-sm text-white/90">
                  When should this price be active?
                </label>
                <DatePicker
                  disabledDate={disabledDate}
                  className="px-6 py-2"
                  value={dateVal ? dayjs(dateVal) : null}
                  disabled={activeVal}
                  onChange={dateChange}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const openEditPrice = (price) => {
    setPriceData(price);
    setTimeout(() => setOpenPrice(true), 700);
  };

  const closeEditPrice = () => {
    setOpenPrice(false);
    setPriceData({
      stripePriceId: "",
      active: false,
      currency: "usd",
      unitCost: 0,
      activeFrom: "",
      activeTo: "",
      changedFrom: "",
      changedTo: "",
      addedBy: "",
      removedBy: "",
    });
    setActiveVal(false);
    setDateVal(null);
    setPriceVal(0);
  };

  const isPickerDisabled = (data) => {
    let activeFrom = data.activeFrom ? dayjs(data.activeFrom).isValid() && dayjs(data.activeFrom).isAfter(dayjs()) : false;

    let activeTo = data.activeTo ? dayjs(data.activeTo).isValid() : false;

    let priceActive = data.active;

    return !activeFrom + activeTo + priceActive;
  };

  const savePriceChanges = () => {
    if (!activeVal && !dateVal) {
      toast.success("No changes were made to the price");
      closeEditPrice();
    } else {
      setLoading(true);
      UpdateMembershipPrice(params.membershipId, priceData.stripePriceId.id, { active: false, activeFrom: dateVal })
        .then((res) => {
          toast.success("Successfully updated the price!");
          closeEditPrice();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the price, please try again.");
          setLoading(false);
        });
    }
  };

  const activatePriceNow = () => {
    let toSend = {
      stripePriceId: priceData.stripePriceId,
      active: true,
    };
    setLoading(true);
    UpdateMembershipPrice(params.membershipId, priceData.stripePriceId.id, toSend)
      .then((res) => {
        toast.success("Successfully activated the price!");
        closeEditPrice();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the price, please try again.");
        setLoading(false);
      });
  };

  const renderEditPriceDrawer = () => {
    return (
      <Drawer title="View / Edit Price" placement="right" closable={true} onClose={closeEditPrice} open={openPrice} width={500} className="text-white">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <dl className="w-full divide-y divide-white/10">
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Price Active</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">{priceData.active ? "Yes" : "No"}</dd>
            </div>
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Price</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">{formatCurrency(priceData.unitCost)}</dd>
            </div>
            {priceData.changedFrom && priceData.changedFrom.length > 10 && (
              <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Changed From</dt>
                <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {priceData.changedFrom && priceData.changedFrom.length > 10 ? priceData.changedFrom : "N/A"}
                </dd>
              </div>
            )}
            {priceData.changedTo && priceData.changedTo.length > 10 && (
              <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Replaced by</dt>
                <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {priceData.changedTo && priceData.changedTo.length > 10 ? priceData.changedTo : "N/A"}
                </dd>
              </div>
            )}
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Active From</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.activeFrom && dayjs(priceData.activeFrom).isValid() ? dayjs(priceData.activeFrom).format("MM/DD/YYYY") : "N/A"}
              </dd>
            </div>
            {priceData.activeTo && dayjs(priceData.activeTo).isValid() && (
              <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Active To</dt>
                <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {priceData.activeTo && dayjs(priceData.activeTo).isValid() ? dayjs(priceData.activeTo).format("MM/DD/YYYY") : "N/A"}
                </dd>
              </div>
            )}
            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Added By</dt>
              <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                {priceData.addedBy && priceData.addedBy.length > 5 ? priceData.addedBy.split(" | ")[0] : "N/A"}
              </dd>
            </div>
            {priceData.removedBy && priceData.removedBy.length > 5 && (
              <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Removed By</dt>
                <dd className="mt-1 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {priceData.removedBy && priceData.removedBy.length > 5 ? priceData.removedBy.split(" | ")[0] : "N/A"}
                </dd>
              </div>
            )}
          </dl>
          <p className="w-full py-4 text-lg font-medium border-y border-white/10">Make Changes to This Price</p>
          <div className="flex flex-col items-start justify-start flex-grow w-full gap-2 mt-4">
            <div className="grid items-center justify-between w-full grid-cols-2 gap-y-5 gap-x-5">
              {!priceData.active && (
                <>
                  <p>Active</p>
                  <button className="w-full py-2.5 border border-white/30 text-white rounded-md bg-black text-sm" onClick={() => activatePriceNow()}>
                    Activate Now
                  </button>
                </>
              )}
              <p>Active From</p>
              <DatePicker
                disabledDate={disabledDate}
                className="px-6 py-2"
                defaultValue={priceData.activeFrom ? dayjs(priceData.activeFrom) : null}
                disabled={isPickerDisabled(priceData)}
                onChange={dateChange}
              />
              {isPickerDisabled(priceData) ? (
                <p className="w-full col-span-2 text-xs text-right text-white/20">
                  Since this price is active already or has already been active, you cannot make changes to it. Create a new price or activate a different one
                  in order to update the membership price or schedule a price change.
                </p>
              ) : null}
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-3">
            <Button block onClick={() => closeEditPrice()}>
              Cancel
            </Button>
            <Button block type="primary" onClick={() => savePriceChanges()}>
              Save Changes
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  const renderBenefitsDrawer = () => {
    return (
      <Drawer
        title="Membership Benefits"
        placement="right"
        closable={true}
        onClose={() => setBenefitsDrawer(false)}
        open={benefitsDrawer}
        width={500}
        className="text-white"
      >
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className="flex flex-col items-start justify-start flex-grow w-full gap-2 mt-1 overflow-y-auto">
            <div className="grid items-center justify-between w-full grid-cols-1 gap-y-5 gap-x-5">
              {membershipData.benefits && membershipData.benefits.length > 0 ? (
                membershipData.benefits.map((benefit) => renderBenefit(benefit))
              ) : (
                <div className="flex flex-col items-center justify-center w-full gap-2">
                  <p className="text-sm font-light text-center text-white/40">No Benefits Added Yet</p>
                  <Button onClick={() => setAddBenefit(true)}>Add Benefits</Button>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-3">
            <Button block type="primary" onClick={() => setAddBenefit(true)}>
              Add New Benefit
            </Button>
            <Button block onClick={() => setBenefitsDrawer(false)}>
              Close
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  const renderBenefit = (benefit) => {
    return (
      <div key={benefit.benefitId} className="flex flex-col items-center justify-between w-full p-4 border rounded-md border-white/20">
        <dl className="w-full divide-y divide-white/10">
          <div className="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-xs font-medium leading-6 text-white">Name</dt>
            <dd className="mt-1 text-xs leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">{benefit.benefit}</dd>
          </div>
          <div className="p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-xs font-medium leading-6 text-white">Description</dt>
            <dd className="mt-1 text-xs leading-6 text-right text-gray-400 truncate sm:col-span-2 sm:mt-0">{benefit.description}</dd>
          </div>
        </dl>
        <div className="flex flex-row items-center justify-end w-full gap-2 mt-2">
          <Button onClick={() => deleteBenefit(benefit.benefitId)} danger>
            Delete
          </Button>
          <Button onClick={() => openEditBenefit(benefit)}>Edit</Button>
        </div>
      </div>
    );
  };

  const deleteBenefit = (benefitId) => {
    Modal.confirm({
      title: "Delete Benefit",
      content: "Are you sure you want to delete this benefit?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      destroyOnClose: true,
      onOk() {
        setLoading(true);
        DeleteMembershipBenefit(params.membershipId, benefitId)
          .then((res) => {
            toast.success("Successfully deleted the benefit!");
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred while deleting the benefit, please try again.");
            setLoading(false);
          });
      },
      onCancel() {},
    });
  };

  const openEditBenefit = (benefit) => {
    setBenefitData(benefit);
    setTimeout(() => setAddBenefit(true), 400);
  };

  const closeEditTitle = () => {
    setEditTitle(false);
    setNewTitle("");
  };

  const submitNewTitle = () => {
    if (newTitle.length < 3) {
      toast.error("Please enter a valid title for the membership");
    } else {
      setLoading(true);
      UpdateMembershipInfo(params.membershipId, { name: newTitle })
        .then((res) => {
          toast.success("Successfully updated the membership title!");
          closeEditTitle();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership title, please try again.");
          setLoading(false);
        });
    }
  };

  const renderEditTitleModal = () => {
    return (
      <Modal open={editTitle} onClose={closeEditTitle} centered destroyOnClose onCancel={closeEditTitle} onOk={submitNewTitle} title="Update Membership Title">
        <div>
          <div className="text-left">
            <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
              <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                New Title <span className="text-red-500">*</span>
              </label>
              <Input placeholder="Title" className="w-full" defaultValue={membershipData.name} onChange={(e) => setNewTitle(e.target.value)} />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const closeEditDescription = () => {
    setEditDescription(false);
    setNewDescription("");
  };

  const submitNewDescription = () => {
    if (newDescription.length < 3) {
      toast.error("Please enter a valid description for the membership");
    } else {
      setLoading(true);
      UpdateMembershipInfo(params.membershipId, { description: newDescription })
        .then((res) => {
          toast.success("Successfully updated the membership description!");
          closeEditDescription();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership description, please try again.");
          setLoading(false);
        });
    }
  };

  const renderEditDescriptionModal = () => {
    return (
      <Modal
        open={editDescription}
        onClose={closeEditDescription}
        centered
        destroyOnClose
        onCancel={closeEditDescription}
        onOk={submitNewDescription}
        title="Update Membership Description"
      >
        <div>
          <div className="text-left">
            <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
              <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                New Description <span className="text-red-500">*</span>
              </label>
              <Input
                placeholder="Description"
                defaultValue={membershipData.description}
                className="w-full"
                onChange={(e) => setNewDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderBenefitModal = () => {
    return (
      <Modal
        open={addBenefit}
        onClose={closeBenefitModal}
        centered
        destroyOnClose
        onCancel={closeBenefitModal}
        onOk={submitBenefit}
        title={benefitData.benefitId ? "Edit Benefit" : "Add New Benefit"}
      >
        <div>
          <div className="text-left">
            <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
              <label htmlFor="benefit" className="block pl-1 text-sm text-white/90">
                Benefit Name <span className="text-red-500">*</span>
              </label>
              <Input
                placeholder="Name"
                defaultValue={benefitData.benefit}
                className="w-full"
                onChange={(e) => setBenefitData({ ...benefitData, benefit: e.target.value })}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
              <label htmlFor="description" className="block pl-1 text-sm text-white/90">
                Benefit Description <span className="text-gray-300">(optional)</span>
              </label>
              <Input
                placeholder="Description"
                defaultValue={benefitData.description}
                className="w-full"
                onChange={(e) => setBenefitData({ ...benefitData, description: e.target.value })}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const closeBenefitModal = () => {
    setAddBenefit(false);
    setBenefitData({ benefit: "", description: "", benefitId: null });
  };

  const submitBenefit = () => {
    if (benefitData.benefit.length < 3) {
      toast.error("Please enter a valid name for the benefit");
    } else {
      setLoading(true);
      if (benefitData.benefitId) {
        UpdateMembershipBenefit(params.membershipId, benefitData.benefitId, benefitData)
          .then((res) => {
            toast.success("Successfully updated the benefit!");
            closeBenefitModal();
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the benefit, please try again.");
            setLoading(false);
          });
      } else {
        AddMembershipBenefit(params.membershipId, benefitData)
          .then((res) => {
            toast.success("Benefit added successfully!");
            closeBenefitModal();
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred while adding the benefit, please try again.");
            setLoading(false);
          });
      }
    }
    // closeBenefitModal();
  };

  const changeActiveVal = (val) => {
    setActiveVal(val);
    setActiveConfirm(true);
  };

  const closeActiveConfirm = () => {
    setActiveVal(membershipData.live);
    setActiveConfirm(false);
  };

  const submitChangeActiveStatus = () => {
    setLoading(true);
    UpdateMembershipInfo(params.membershipId, { live: activeVal })
      .then((res) => {
        toast.success("Successfully updated the membership status!");
        closeActiveConfirm();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership status, please try again.");
        setLoading(false);
      });
  };

  const renderEditLiveStatusModal = () => {
    return (
      <Modal
        open={activeConfirm}
        onClose={closeActiveConfirm}
        centered
        destroyOnClose
        onCancel={closeActiveConfirm}
        onOk={submitChangeActiveStatus}
        title={!activeVal ? "Disable Membership" : "Enable Membership"}
      >
        <div>
          <div className="text-left">
            <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
              <p className="block text-sm text-white/90">
                {!activeVal
                  ? "Are you sure you want to disable this membership. This will immediately make it unavailable for purchasing and prevent checkouts for users that already have this membership in their shopping cart."
                  : "Enabling this membership will make it immediately available for purchase and checkout on all sales channels."}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const closeLinkSettings = () => {
    setLinkSettings(false);
    setShortSlug("");
  };

  const submitLinkSettings = async () => {
    if (shortSlug.length > 3) {
      setLoading(true);
      HyprTK.checkSlug(shortSlug)
        .then((res) => {
          HyprTK.createLink({ slug: shortSlug, url: `${process.env.REACT_APP_HOST}/memberships/${membershipData.membershipId}` })
            .then((res) => {
              UpdateMembershipInfo(params.membershipId, { shortSlug: shortSlug })
                .then((res) => {
                  toast.success("Successfully updated the membership status!");
                  closeLinkSettings();
                  reloadData();
                })
                .catch((err) => {
                  toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership status, please try again.");
                  setLoading(false);
                });
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership status, please try again.");
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership status, please try again.");
          setLoading(false);
        });
    } else {
      toast.error("Please enter a valid slug for the membership");
    }
  };

  const renderLinkSettings = () => {
    return (
      <Modal
        open={linkSettings}
        onClose={closeLinkSettings}
        centered
        destroyOnClose
        onCancel={closeLinkSettings}
        onOk={submitLinkSettings}
        title="Membership Short Link Settings"
      >
        <div>
          <div className="text-left">
            <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
              <label htmlFor="price" className="block pl-1 text-sm text-white/90">
                Edit the slug in the short link to the membership
              </label>
              <Input
                prefix={"https://hyprtk.co/"}
                placeholder="Short Membership URL Slug"
                defaultValue={membershipData.shortSlug}
                className="w-full"
                onChange={(e) => setShortSlug(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const copyLinkToMembership = () => {
    if (membershipData.shortSlug && membershipData.shortSlug.length > 3) {
      navigator.clipboard.writeText("https://hyprtk.co/" + membershipData.shortSlug);
    } else {
      navigator.clipboard.writeText(process.env.REACT_APP_HOST + "/memberships/" + membershipData.membershipId);
    }
    toast.success("Successfully copied the link to the membership!");
  };

  const renderQrSettings = () => {
    return (
      <Modal
        open={qrSettings}
        onClose={() => setQrSettings(false)}
        centered
        destroyOnClose
        onCancel={() => setQrSettings(false)}
        title="Membership QR Code Settings"
        okButtonProps={{ hidden: true }}
      >
        <div>
          <div className="text-left">
            <div className="flex flex-col items-center justify-center w-full gap-3 mt-4">
              <div className="flex items-center justify-center w-full mt-3">
                <QRCodeViewer
                  url={
                    membershipData.shortSlug && membershipData.shortSlug.length > 3
                      ? "https://hyprtk.co/" + membershipData.shortSlug
                      : process.env.REACT_APP_HOST + "/memberships/" + membershipData.membershipId
                  }
                  title={membershipData.name}
                  type="Membership"
                />
              </div>
              <p className="text-sm text-white/60">Click on the QR code in order to download it</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const updateMembership = (key, value) => {
    setLoading(true);
    UpdateMembershipInfo(params.membershipId, { [key]: value })
      .then((res) => {
        toast.success("Membership information updated successfully!");
        closeActiveConfirm();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the membership, please try again.");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start flex-grow w-full h-full min-h-screen gap-3 px-4 pt-6 overflow-y-auto text-white bg-black overflow-x-clip">
      <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-white/40">
        <h1 className="text-xl font-medium">Membership Tier {!loading && " | " + membershipData.name}</h1>
      </div>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="animate-pulse">Retrieving data...</p>
        </div>
      ) : (
        <>
          <div className="grid w-full grid-cols-2 gap-4 pb-5 h-fit gap-x-10 max-w-content">
            <dl className="h-full divide-y divide-white/10">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Title</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>{membershipData.name}</p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setEditTitle(true)}
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Description</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>{membershipData.description}</p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setEditDescription(true)}
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Benefits</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>{membershipData.benefits && membershipData.benefits.length > 0 ? "View Benefits" : "No benefits added yet"}</p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setBenefitsDrawer(true)}
                  >
                    {membershipData.benefits && membershipData.benefits.length > 0 ? <Edit className="w-4 h-4" /> : <Plus className="w-4 h-4" />}
                  </button>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Active</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <Switch checked={membershipData.live} className="bg-slate-800" onClick={(e) => changeActiveVal(e)} />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Membership Type</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <Select
                    options={[
                      { label: "Individual and Family Membership", value: "Individual and Family" },
                      { value: "Corporate", label: "Corporate" },
                    ]}
                    placeholder="Select the membership type"
                    className="text-left w-full max-w-[320px]"
                    defaultValue={membershipData.membershipType}
                    onChange={(e) => updateMembership("membershipType", e)}
                  />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Membership Price</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  {formatCurrency(membershipData.stripePriceId.unit_amount)}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Military Discount</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>
                    {membershipData.militaryDiscount.eligible ? `Active - ${formatCurrency(membershipData.militaryDiscount.discount * 100)}` : "Not Enabled"}
                  </p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => openEditDiscount("militaryDiscount")}
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Student Discount</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <p>{membershipData.studentDiscount.eligible ? `Active - ${formatCurrency(membershipData.studentDiscount.discount * 100)}` : "Not Enabled"}</p>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => openEditDiscount("studentDiscount")}
                  >
                    <Edit className="w-4 h-4" />
                  </button>
                </dd>
              </div>
              <div className="flex flex-row items-center justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-white">Membership Link</dt>
                <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => copyLinkToMembership()}
                  >
                    <Copy className="w-4 h-4" /> Copy Link
                  </button>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setLinkSettings(true)}
                  >
                    <Link className="w-4 h-4" /> Link Settings
                  </button>
                  <button
                    className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                    onClick={() => setQrSettings(true)}
                  >
                    <QrCode className="w-4 h-4" /> QR Code
                  </button>
                </dd>
              </div>
            </dl>
            <div className="flex flex-col items-start justify-start w-full h-full">
              <p className="w-full pb-3 text-lg font-medium border-b border-white/10">Price History</p>
              <ul className="flex-grow w-full overflow-y-auto divide-y divide-white/5">
                {membershipData.stripePriceHistory.map((price) => (
                  <li key={uuidv4()} className="relative flex items-center py-4 space-x-4">
                    <div className="flex-auto min-w-0">
                      <div className="flex items-center gap-x-3">
                        <div
                          className={classNames(
                            price.stripePriceId.id === membershipData.stripePriceId.id
                              ? "text-green-400 bg-green-400/10 ring-green-600"
                              : "text-gray-500 bg-gray-100/10 ring-gray-500",
                            "flex-none rounded-full p-1"
                          )}
                        >
                          <div className="w-2 h-2 bg-current rounded-full" />
                        </div>
                        <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                          <p className="flex gap-x-2">
                            <span className="truncate">{formatCurrency(price.unitCost)}</span>
                          </p>
                        </h2>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                        <div className="flex flex-col items-start justify-start">
                          <div className="flex flex-row justify-start items-center gap-x-1.5">
                            <span className="text-gray-400">Active from</span>
                            <span className="text-gray-200 truncate">{dayjs(price.activeFrom).format("MM/DD/YYYY")}</span>
                            {price.activeTo && <span className="text-gray-400">to</span>}
                            {price.activeTo && <span className="text-gray-200">{dayjs(price.activeTo).format("MM/DD/YYYY")}</span>}
                          </div>
                          <div className="flex flex-row justify-start items-center gap-x-2.5">
                            <p className="whitespace-nowrap">Added By {price.addedBy.split(" | ")[0]}</p>
                            {price.removedBy && price.removedBy.length > 2 && (
                              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                            )}
                            {price.removedBy && price.removedBy.length > 2 && <p className="whitespace-nowrap">Removed By {price.removedBy.split(" | ")[0]}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        price.stripePriceId.id === membershipData.stripePriceId.id
                          ? "text-green-400 bg-green-500/10 ring-green-600/20"
                          : "text-gray-500 bg-gray-600/10 ring-gray-500/20",
                        "rounded-md flex-none py-1.5 px-4 text-xs ring-1 ring-inset"
                      )}
                    >
                      {price.stripePriceId.id === membershipData.stripePriceId.id ? "Current" : "Inactive"}
                    </div>
                    <ArrowRight
                      className="w-8 h-8 text-black cursor-pointer bg-gray-400/20 hover:bg-white transition-all rounded-full p-1.5"
                      onClick={() => openEditPrice(price)}
                    />
                  </li>
                ))}
              </ul>
              <div className="flex flex-row items-center justify-end w-full">
                <button
                  onClick={() => setOpenNewPrice(true)}
                  className="inline-flex items-center justify-center gap-2 px-4 py-2 text-xs text-gray-300 transition-all bg-transparent border rounded-md hover:bg-gray-950 hover:text-white border-white/10"
                >
                  Add a Price
                </button>
              </div>
            </div>
            {renderQrSettings()}
            {renderLinkSettings()}
            {renderEditPriceDrawer()}
            {renderBenefitsDrawer()}
            {renderNewPriceModal()}
            {renderEditTitleModal()}
            {renderEditDescriptionModal()}
            {renderEditLiveStatusModal()}
            {renderBenefitModal()}
            {renderEditDiscount()}
          </div>
        </>
      )}
    </div>
  );
};

export default OpenMembership;
