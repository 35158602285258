import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManagementCheck } from "../../../actions/auth";
import toast from "react-hot-toast";

const Integrations = ({ authState, data }) => {
  const [loading, setLoading] = useState(true);
  const [institution, setInstitution] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    ManagementCheck()
      .then((res) => {
        setInstitution(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Error loading institution data");
        navigate("/management/settings", { replace: true });
      });
  }, []);

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full h-full min-h-screen overflow-y-auto">
      <nav id="toggleMobileMenu" className="w-full bg-black border-b border-gray-200 shadow-sm">
        <div className="flex items-center justify-start gap-4 px-4 py-4 text-sm">
          <div
            onClick={() => navigate("/management/settings")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Institution Settings
          </div>
          <div
            onClick={() => navigate("/management/settings/socialPresence")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Social Presence
          </div>
          <div
            onClick={() => navigate("/management/settings/payments")}
            className="font-semibold transition-all duration-200 rounded-lg cursor-pointer text-white/60 hover:text-white focus:ring-0"
          >
            Payments
          </div>
          <div
            onClick={() => navigate("/management/settings/integrations")}
            className="font-semibold text-white transition-all duration-200 rounded-lg cursor-pointer hover:text-gray-400 focus:ring-0"
            aria-current="page"
          >
            Integrations
          </div>
        </div>
      </nav>
      <div className="flex flex-col items-center justify-start flex-grow w-full h-full gap-4 mx-auto mt-5 text-white max-w-content">
        <div className="flex flex-col items-start justify-center w-full">
          <p className="font-serif text-2xl font-bold">Integrations</p>
          <p className="text-sm font-medium">Manage the available integrations</p>
        </div>
        {!loading && (
          <div className="flex items-center justify-center w-full py-4">
            <div className="flex flex-col items-center justify-center w-full max-w-3xl gap-1 px-5 py-6 mt-5 border border-gray-900 rounded-lg bg-slate-950/30">
              <p className="text-2xl font-semibold font-display">Organization wide integrations coming soon!</p>
              <p className="text-lg tracking-wide font-display">
                Check out individual integrations by visiting your profile settings or by clicking{" "}
                <span
                  className="font-medium underline transition-all duration-200 cursor-pointer underline-offset-2 hover:text-blue-600"
                  onClick={() => navigate("/profile/integrations")}
                >
                  here
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Integrations;
