import dayjs from "dayjs";
import { Loader } from "lucide-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ExchangeToken } from "../actions/auth";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, selectAuth } from "../reducers/index";
import { store } from "../store/store";

const AuthView = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const authState = useSelector((selectAuth) => selectAuth.isAuth);
  const code = searchParams.get("code");
  const returnTo = searchParams.get("returnTo");
  useEffect(() => {
    if (code && returnTo) {
      ExchangeToken({ code: code })
        .then(async (res) => {
          let payload = {
            token: res.data.token,
            user: res.data.user,
          };
          dispatch(loginUser(payload));
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response && err.response.data && err.response.data.message ? err.response.data.message : "An error occurred");
        });
    }
  }, []);

  useEffect(() => {
    if (authState) {
      setTimeout(() => {
        try {
          let goTo = new URL(returnTo);
          window.location.href = goTo.href;
        } catch (error) {
          console.log(error);
          toast.error("An error occurred");
        }
      }, 700);
    }
  }, [authState]);

  return (
    <div className="flex items-center justify-center w-full h-full min-h-screen bg-slate-300">
      <div className="animate-pulse">
        <Loader size="36" className="animate-spin-slow" />
      </div>
      <div className="fixed bottom-0 left-auto right-auto mb-5">
        <div className="flex items-center justify-center gap-3 text-xs text-slate-600">
          <p>
            All Rights Reserved &copy;{" "}
            <a href="https://getcoracle.com" target="_blank" referrer="dofollow">
              Coracle
            </a>{" "}
            {dayjs().format("YYYY")}
          </p>
          <a href="https://getcoracle.com/legal/privacy-policy" target="_blank" referrer="dofollow">
            Privacy Policy
          </a>
          <a href="https://getcoracle.com/legal/terms-of-use" target="_blank" referrer="dofollow">
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthView;
