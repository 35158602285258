import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/index";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "coracleamh",
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk, serializableCheck: { ignoredActionPaths: ["register", "rehydrate"] } }),
});

export const persistor = persistStore(store);
