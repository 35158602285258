import { Button, Input, Modal, Select } from "antd";
import { Copy, Edit, Link, QrCode } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { EVENT_CATEGORIES, EVENT_VISIBILITY } from "../../../../lib/eventEnums";
import QRCodeViewer from "../../../../components/QRCodeViewer";
import { UpdateEvent, UpdateEventStatus } from "../../../../actions/mgmt";
import dayjs from "dayjs";

const HyprTK = require("hyprtk")(process.env.REACT_APP_HYPRTK_API_KEY);

const EventBasicInfo = ({ eventData, reloadData }) => {
  const [linkSettings, setLinkSettings] = useState(false);
  const [qrSettings, setQrSettings] = useState(false);
  const [slug, setSlug] = useState("");
  const [editCategory, setEditCategory] = useState(false);
  const [editVisibility, setEditVisibility] = useState(false);
  const [category, setCategory] = useState("");
  const [visibility, setVisibility] = useState("");
  const [title, setTitle] = useState("");
  const [titleSettings, setTitleSettings] = useState(false);

  const copyLinkToEvent = (short) => {
    if (!short) {
      if (eventData.slug && eventData.slug.length > 3) {
        navigator.clipboard.writeText(process.env.REACT_APP_HOST + "/events/" + eventData.slug);
      } else {
        navigator.clipboard.writeText(process.env.REACT_APP_HOST + "/events/" + eventData.eventId);
      }
    } else {
      navigator.clipboard.writeText(`https://hyprtk.co/` + eventData.slug);
    }
    toast.success("Successfully copied the link to the event!");
  };

  const renderLinkSettings = () => {
    return (
      <Modal
        title="Event short link settings"
        open={linkSettings}
        onCancel={() => closeLinkSettings()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeLinkSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitLinkSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the slug in the short link to the event
          </label>
          <Input
            prefix={process.env.REACT_APP_HOST + "/events/"}
            placeholder="Event URL Slug"
            defaultValue={eventData.slug}
            className="w-full"
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>
      </Modal>
    );
  };

  const renderTitleSettings = () => {
    return (
      <Modal
        title="Event title settings"
        open={titleSettings}
        onCancel={() => closeTitleSettings()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeTitleSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitTitleSettings()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the event title
          </label>
          <Input placeholder="Event Title" defaultValue={eventData.title} className="w-full" onChange={(e) => setTitle(e.target.value)} />
        </div>
      </Modal>
    );
  };

  const closeTitleSettings = () => {
    setTitleSettings(false);
    setTitle("");
    reloadData();
  };

  const submitTitleSettings = () => {
    if (title === "") {
      toast.error("Please enter a valid title for the event");
    } else if (title === eventData.title) {
      setTitleSettings(false);
      toast("No changes were made to the event title");
    } else if (title.length < 3) {
      toast.error("The title you entered is too short");
    } else {
      UpdateEvent(eventData.eventId, { title: title })
        .then((res) => {
          toast.success("Successfully updated the event title, the link to the event has been updated automatically");
          closeTitleSettings();
        })
        .catch((err) => {
          toast.error("An error occurred while updating the event title");
        });
    }
  };

  const closeLinkSettings = () => {
    setLinkSettings(false);
    setSlug("");
    reloadData();
  };

  const submitLinkSettings = () => {
    if (slug === "") {
      toast.error("Please enter a valid slug for the event link");
    } else if (slug === eventData.slug) {
      toast.error("The slug you entered is the same as the current one");
    } else if (slug.length < 3) {
      toast.error("The slug you entered is too short");
    } else {
      HyprTK.checkSlug(slug)
        .then((res) => {
          HyprTK.createLink({ slug: slug, url: `${process.env.REACT_APP_HOST}/events/${eventData.eventId}` })
            .then((res) => {
              UpdateEvent(eventData.eventId, { slug: slug })
                .then((res) => {
                  toast.success("Successfully updated the event link slug");
                  closeLinkSettings();
                })
                .catch((err) => {
                  toast.error("An error occurred while updating the event link slug");
                });
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the event slug, please try again.");
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the event slug, please try again.");
        });
    }
  };

  const renderQrSettings = () => {
    return (
      <Modal
        open={qrSettings}
        title="QR Code Settings"
        centered
        width={650}
        onCancel={() => setQrSettings(false)}
        destroyOnClose
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => setQrSettings(false)}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Close
            </button>
          </div>,
        ]}
      >
        <div className="flex flex-col items-center justify-center w-full gap-3 mt-4">
          <div className="flex items-center justify-center w-full mt-3">
            <QRCodeViewer
              url={process.env.REACT_APP_HOST + "/events/" + eventData.slug.length > 3 ? eventData.slug : eventData.eventId}
              title={eventData.title}
              type="Event"
            />
          </div>
          <p className="text-sm text-white/60">Click on the QR code in order to download it</p>
        </div>
      </Modal>
    );
  };

  const renderCategoryModal = () => {
    return (
      <Modal
        title="Event category settings"
        open={editCategory}
        onCancel={() => closeEditCategory()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeEditCategory()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitEditCategory()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the Event Category
          </label>
          <Select
            className="w-full"
            options={EVENT_CATEGORIES}
            defaultValue={EVENT_CATEGORIES.find((c) => c.value === eventData.category).label}
            onChange={(v) => setCategory(v)}
          />
        </div>
      </Modal>
    );
  };

  const renderVisibilityModal = () => {
    return (
      <Modal
        title="Event visibility settings"
        open={editVisibility}
        onCancel={() => closeEditVisibility()}
        footer={[
          <div className="flex flex-row items-center justify-end w-full gap-4 mt-5 sm:mt-6">
            <button
              onClick={() => closeEditVisibility()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-semibold text-gray-400 transition-all border border-transparent rounded-md hover:bg-gray-950 hover:text-white"
            >
              Cancel
            </button>
            <button
              onClick={() => submitEditVisibility()}
              className="inline-flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-400 align-middle transition-all bg-black border border-gray-800 rounded-md shadow-sm hover:bg-gray-950 hover:text-white"
            >
              Update
            </button>
          </div>,
        ]}
        centered
        width={650}
        destroyOnClose
      >
        <div className="flex flex-col items-start justify-start w-full gap-2 mt-4">
          <label htmlFor="price" className="block pl-1 text-sm text-white/90">
            Edit the Event Visibility
          </label>
          <Select
            className="w-full"
            options={EVENT_VISIBILITY}
            defaultValue={EVENT_VISIBILITY.find((c) => c.value === eventData.visibility).label}
            onChange={(v) => setVisibility(v)}
          />
        </div>
      </Modal>
    );
  };

  const closeEditCategory = () => {
    setEditCategory(false);
    setCategory("");
    reloadData();
  };

  const submitEditCategory = () => {
    if (category === "") {
      toast.error("Please select a valid category for the event");
    } else if (category === eventData.category) {
      setEditCategory(false);
      setCategory("");
    } else if (EVENT_CATEGORIES.find((c) => c.value === category)) {
      UpdateEvent(eventData.eventId, { category: category })
        .then((res) => {
          toast.success("Successfully updated the event category");
          closeEditCategory();
        })
        .catch((err) => {
          toast.error("An error occurred while updating the event category");
        });
    } else {
      toast.error("Invalid category type");
    }
  };

  const closeEditVisibility = () => {
    setEditVisibility(false);
    setVisibility("");
    reloadData();
  };

  const submitEditVisibility = () => {
    if (visibility === "") {
      toast.error("Please select a valid visibility for the event");
    } else if (visibility === eventData.visibility) {
      setEditVisibility(false);
      setVisibility("");
    } else if (EVENT_VISIBILITY.find((v) => v.value === visibility)) {
      UpdateEvent(eventData.eventId, { visibility: visibility })
        .then((res) => {
          toast.success("Successfully updated the event visibility");
          closeEditVisibility();
        })
        .catch((err) => {
          toast.error("An error occurred while updating the event visibility");
        });
    } else {
      toast.error("Invalid visibility type");
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getEventStatusClasses = (status) => {
    if (status === "draft" || status === "archived") {
      return "text-gray-500 bg-gray-600/10 ring-gray-500/20";
    } else if (status === "published" || status === "completed") {
      return "text-green-400 bg-green-500/10 ring-green-600/20";
    } else if (status === "cancelled" || status === "deleted") {
      return "text-red-400 bg-red-500/10 ring-red-600/20";
    }
  };

  const publishEvent = (status) => {
    let fromDraft = ["published", "deleted"];
    let fromPublished = ["archived", "completed"];
    if (eventData.status === status) {
      toast.error("The event is already in this status");
      return;
    } else if (eventData.status === "draft" && !fromDraft.includes(status)) {
      toast.error("The event is in draft status, it can only be published or deleted");
      return;
    } else if (eventData.status === "published" && !fromPublished.includes(status)) {
      toast.error("The event is published, it can only be archived or completed");
      return;
    } else if (eventData.status === "completed" && status !== "archived") {
      toast.error("The event is completed, it can only be archived");
      return;
    }
    if (status === "published") {
      if (eventData.images.length === 0) {
        toast.error("Please upload an image for the event before publishing.");
        return;
      }
      if (dayjs(eventData.startDate).isBefore(dayjs(), "day")) {
        toast.error("The event start date is in the past. Please update the event date before publishing.");
        return;
      }
      if (eventData.tickets.length > 0 && eventData.ticketData.filter((t) => t.live).length === 0) {
        toast.error("Please make at least one ticket live before publishing.");
        return;
      }
    }
    UpdateEventStatus(eventData.eventId, { status: status })
      .then((res) => {
        toast.success("Event status updated successfully");
        reloadData();
      })
      .catch((err) => {
        toast.error("An error occurred while updating the event status, please try again");
      });
  };

  return (
    <div className="flex flex-col w-full gap-3">
      <dl className="h-full divide-y divide-white/10">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Title</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <p>{eventData.title}</p>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setTitleSettings(true)}
              >
                <Edit className="w-4 h-4" />
              </button>
            )}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Category</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <p>{EVENT_CATEGORIES.find((c) => c.value === eventData.category).label}</p>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setEditCategory(true)}
              >
                <Edit className="w-4 h-4" />
              </button>
            )}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Visibility</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <p>{EVENT_VISIBILITY.find((v) => v.value === eventData.visibility).label}</p>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setEditVisibility(true)}
              >
                <Edit className="w-4 h-4" />
              </button>
            )}
          </dd>
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Status</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <div className={classNames(getEventStatusClasses(eventData.status), "rounded-md flex-none py-1.5 px-4 text-xs ring-1 ring-inset capitalize")}>
              {eventData.status}
            </div>
          </dd>
        </div>
        <div className="flex flex-row items-center justify-between px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-white">Event Link</dt>
          <dd className="flex flex-row items-center justify-end gap-3 text-sm leading-6 text-right text-gray-400 sm:col-span-2 sm:mt-0">
            <button
              className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
              onClick={() => copyLinkToEvent(false)}
            >
              <Copy className="w-4 h-4" /> Copy Link
            </button>
            <button
              className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
              onClick={() => copyLinkToEvent(true)}
            >
              <Copy className="w-4 h-4" /> Copy Short Link
            </button>
            {eventData.status === "draft" && (
              <button
                className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
                onClick={() => setLinkSettings(true)}
              >
                <Link className="w-4 h-4" /> Link Settings
              </button>
            )}
            <button
              className="py-2 px-2.5 inline-flex justify-center items-center gap-2 rounded-md text-gray-300 hover:bg-gray-950 transition-all text-xs border border-white/10 hover:text-white bg-transparent"
              onClick={() => setQrSettings(true)}
            >
              <QrCode className="w-4 h-4" /> QR Code
            </button>
          </dd>
        </div>
      </dl>
      <div className="flex items-center justify-end w-full gap-2">
        {eventData.status === "draft" && (
          <>
            <Button danger onClick={() => publishEvent("deleted")}>
              Delete Event
            </Button>
            <Button type="primary" onClick={() => publishEvent("published")}>
              Publish Event
            </Button>
          </>
        )}
        {eventData.status === "published" && (
          <>
            <Button danger onClick={() => publishEvent("cancelled")}>
              Cancel Event
            </Button>
            <Button onClick={() => publishEvent("completed")}>Manually mark as completed</Button>
          </>
        )}
        {eventData.status === "completed" && <Button onClick={() => publishEvent("archived")}>Archive event</Button>}
      </div>
      {renderLinkSettings()}
      {renderQrSettings()}
      {renderCategoryModal()}
      {renderVisibilityModal()}
      {renderTitleSettings()}
    </div>
  );
};

export default EventBasicInfo;
