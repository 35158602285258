const PageLoader = () => {
  return (
    <div className="w-full h-full min-h-screen flex justify-center items-center bg-black">
      <div className="coracleLoader font-heading">
        <span>AMH</span>
        <span>AMH</span>
      </div>
    </div>
  );
};

export default PageLoader;
