import { useState, useEffect, Fragment } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logoLight.svg";
import dayjs from "dayjs";
import { ArrowRight, Xmark, LogOut, LogIn, Menu, SimpleCart } from "iconoir-react";
import { v4 as uuidv4 } from "uuid";
import { GetUser, ManagementCheck } from "../actions/auth";
import { useCart } from "react-use-cart";
import { Dialog, Transition } from "@headlessui/react";
import NumberSpinner from "./NumberSpinner";
import { formatCurrency } from "./utilities";
import FallbackProfilePic from "../assets/profilePic.webp";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, selectAuth } from "../reducers/index";

const Navbar = ({ parentLoading }) => {
  const [loading, setLoading] = useState(false);
  const [management, setManagement] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [dummyLoading, setDummyLoading] = useState(false);
  const { isEmpty, totalUniqueItems, items, updateItemQuantity, removeItem, addItem } = useCart();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.isAuth && authState.user && authState.user.role < 6) {
        ManagementCheck()
          .then((res) => {
            setManagement(true);
            setTimeout(() => setLoading(true), 700);
          })
          .catch((err) => {
            setManagement(false);
            setTimeout(() => setLoading(false), 700);
          });
      }
      if (authState.isAuth) {
        GetUser()
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const closeMenu = () => {
    setResourcesOpen(false);
    setMenuOpen(false);
  };

  const renderCartTotal = () => {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      const el = items[i];
      total += el.price * el.quantity;
    }
    if (items.length > 0 && total === 0) {
      return "FREE";
    } else {
      return formatCurrency(total);
    }
  };

  const goToCheckout = () => {
    navigate("/checkout");
    setCartOpen(false);
  };

  const removeCartItem = (id) => {
    setDummyLoading(true);
    removeItem(id);
    setTimeout(() => {
      setDummyLoading(false);
    }, 300);
  };

  const goTo = (path) => {
    navigate(path);
    setCartOpen(false);
  };

  const triggerLogin = () => {
    let link = `${process.env.REACT_APP_AUTH_DOMAIN}/?clientId=${process.env.REACT_APP_INSTITUTION_ID}&returnTo=${encodeURIComponent(window.location.href)}`;
    window.location.href = link;
  };
  const renderCart = () => {
    return (
      <Transition.Root show={cartOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setCartOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none z-[100]">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto z-[100]">
                    <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-xl font-semibold leading-6 text-gray-900 font-display">
                            Shopping Cart {!isEmpty && `(${items.length})`}
                          </Dialog.Title>
                          <div className="flex items-center ml-3 h-7">
                            <button
                              type="button"
                              className="relative text-black bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-0"
                              onClick={() => setCartOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <Xmark className="w-7 h-7" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {isEmpty ? (
                        <div className="relative flex flex-col items-center justify-center flex-1 gap-3 px-4 mt-6 sm:px-6">
                          <p className="font-serif text-lg font-semibold tracking-wider">Your Cart is Empty.</p>
                          <button
                            className="flex flex-row items-center justify-between w-3/4 px-8 py-3 tracking-wider text-black uppercase transition-all bg-transparent border border-black hover:text-white hover:bg-black"
                            onClick={() => goTo("/tickets")}
                          >
                            Tickets <ArrowRight className="w-5 h-5" />
                          </button>
                          <button
                            className="flex flex-row items-center justify-between w-3/4 px-8 py-3 tracking-wider text-black uppercase transition-all bg-transparent border border-black hover:text-white hover:bg-black"
                            onClick={() => goTo("/memberships")}
                          >
                            Memberships <ArrowRight className="w-5 h-5" />
                          </button>
                          <button
                            className="flex flex-row items-center justify-between w-3/4 px-8 py-3 tracking-wider text-black uppercase transition-all bg-transparent border border-black hover:text-white hover:bg-black"
                            onClick={() => goTo("/donations")}
                          >
                            Donations <ArrowRight className="w-5 h-5" />
                          </button>
                          <button
                            className="flex flex-row items-center justify-between w-3/4 px-8 py-3 tracking-wider text-black uppercase transition-all bg-transparent border border-black hover:text-white hover:bg-black"
                            onClick={() => goTo("/shop")}
                          >
                            Shop <ArrowRight className="w-5 h-5" />
                          </button>
                        </div>
                      ) : (
                        <div className="relative flex flex-col items-center justify-between flex-1 px-4 mt-6 border-t sm:px-6 border-gray-900/40">
                          <div className="flex flex-col items-center justify-start flex-grow w-full gap-4 divide-y divide-gray-600/30">
                            {items.map((item) => {
                              return (
                                <div className="flex flex-row items-center justify-between w-full gap-2 px-6 py-4">
                                  <div className="flex flex-col items-start justify-center gap-1">
                                    <p className="font-medium">{item.name}</p>
                                    {item.description ? (
                                      <p className="text-xs text-gray-900/70">
                                        {item.description.length > 70 ? `${item.description.slice(0, 70)}...` : item.description}
                                      </p>
                                    ) : null}
                                    <p className="pr-6 text-sm font-semibold">{item.price > 0 ? formatCurrency(item.price) : "FREE"}</p>
                                  </div>
                                  <div className="flex flex-col items-end justify-center gap-1">
                                    <NumberSpinner min={0} max={10} itemData={item} />
                                    <button className="text-xs font-medium underline underline-offset-2" onClick={() => removeCartItem(item.id)}>
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex flex-col items-center justify-start w-full gap-3 pt-4 border-t border-gray-900/40">
                            <div className="flex flex-row items-center justify-between w-full font-serif text-lg font-semibold tracking-wider">
                              <p>Subtotal</p>
                              <p>{renderCartTotal()}</p>
                            </div>
                            <button
                              className="w-full py-3 text-white uppercase transition-all bg-black border border-black hover:text-black hover:bg-transparent"
                              onClick={() => goToCheckout()}
                            >
                              Checkout
                            </button>
                            <p className="text-sm font-semibold tracking-wide text-gray-900/50">Taxes and fees will be calculated at checkout</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const getCartItems = () => {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      const el = items[i];
      total += el.quantity;
    }
    return total;
  };

  return (
    <section className="flex flex-col items-center justify-start w-full h-full min-h-screen overflow-x-hidden bg-black">
      <nav className="fixed top-0 left-0 right-0 z-10 w-full h-16 text-white bg-black border-b lg:h-20 border-gray-200/10">
        <div className="flex items-center px-4 py-4 md:px-14">
          {/* MOBILE START */}
          <div className="flex flex-row items-center justify-between w-full xl:hidden">
            <Logo className="w-auto h-6" onClick={() => navigate("/")} />
            <button
              className="flex items-center justify-center w-8 h-8 transform border rounded-md border-gray-50 hover:border-gray-500 xl:hidden"
              onClick={() => setMenuOpen(true)}
            >
              <Menu className="w-5 h-5 text-white" />
            </button>
          </div>
          {/* MOBILE END */}
          <div className="items-center justify-center hidden gap-3 px-4 cursor-pointer xl:flex md:px-14" onClick={() => navigate("/")}>
            <Logo className="w-auto h-6" />
            <h1 className="text-lg font-display">Augusta Museum of History</h1>
          </div>
          <div className="items-center hidden ml-auto lg:flex font-display">
            <ul className="items-center hidden gap-4 xl:flex">
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/">
                  Home
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/about">
                  About
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/collections">
                  Collections
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/exhibits">
                  Exhibits
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/tours">
                  Tours
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/events">
                  Events
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/tickets">
                  Tickets
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/memberships">
                  Memberships
                </a>
              </li>
              <li key={uuidv4()}>
                <a className="inline-block text-base font-medium text-white" href="/shop">
                  Shop
                </a>
              </li>
              {management && (
                <li key={uuidv4()}>
                  <a className="inline-block text-base font-medium text-white" href="/management">
                    Admin
                  </a>
                </li>
              )}
            </ul>
            <div className="w-px h-12 mx-6 bg-gray-200/20"></div>
            {parentLoading ? (
              <p>LOADING</p>
            ) : (
              <>
                {authState.isAuth && (
                  <a className={`inline-flex ${authState.isAuth && "mr-5"} items-center`} href="/profile">
                    <div className="relative w-10 h-10 rounded-full">
                      <img
                        src={
                          authState.user && authState.user.profilePicture
                            ? authState.user.profilePicture
                            : "https://plus.unsplash.com/premium_photo-1670764405794-46e9c9f7a601?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = FallbackProfilePic;
                        }}
                        className="w-10 h-10 rounded-full"
                        alt=""
                      />
                    </div>
                  </a>
                )}
                {authState.isAuth ? (
                  <button
                    className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden font-semibold duration-150 border rounded-lg group border-white/20 hover:border-white"
                    onClick={() => handleLogout()}
                  >
                    <LogOut className="w-5 h-5 text-white" />
                  </button>
                ) : (
                  <button
                    className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden font-semibold duration-150 border rounded-lg group border-white/20 hover:border-white"
                    onClick={() => triggerLogin()}
                  >
                    <LogIn className="w-5 h-5 text-white rotate-180" />
                  </button>
                )}
              </>
            )}
            <button
              className="relative inline-flex items-center justify-center w-10 h-10 mx-1 font-semibold duration-150 border rounded-lg group border-white/20 hover:border-white"
              onClick={() => setCartOpen(true)}
            >
              <SimpleCart className="w-5 h-5 text-white" />
              {!isEmpty && (
                <span className="absolute top-0 right-0 inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-sans transform -translate-y-1/2 translate-x-1/2 bg-rose-600 text-white">
                  {getCartItems()}
                </span>
              )}
            </button>
          </div>
        </div>
      </nav>
      {/* MOBILE SLIDEOUT */}
      <div className={`${menuOpen ? "visible opacity-100" : "hidden opacity-0"} fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50 ease-in-out duration-150`}>
        <div className="fixed inset-0 cursor-pointer navbar-backdrop backdrop-blur-sm backdrop-filter bg-black/80 bg-opacity-80" onClick={() => closeMenu()} />
        <nav className="relative h-full pt-8 pb-8 overflow-y-auto text-white bg-black nav-shadow">
          <div className="flex flex-col h-full px-6">
            <div className="flex flex-row items-center justify-between mb-10">
              <Logo className="w-auto h-8" />
              <div className="flex items-center justify-center w-8 h-8 border rounded-md border-gray-200/20" onClick={() => closeMenu()}>
                <Xmark className="w-auto h-5 cursor-pointer" onClick={() => closeMenu()} />
              </div>
            </div>
            <ul className="w-full pb-16 mb-auto">
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/")}>
                  Home
                </p>
              </li>
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/about")}>
                  About
                </p>
              </li>
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/collections")}>
                  Collections
                </p>
              </li>
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/exhibits")}>
                  Exhibits
                </p>
              </li>
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/tours")}>
                  Tours
                </p>
              </li>
              {/* <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/events")}>
                  Events
                </p>
              </li> */}
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/tickets")}>
                  Tickets
                </p>
              </li>
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/memberships")}>
                  Memberships
                </p>
              </li>
              <li className="mb-6" key={uuidv4()}>
                <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/shop")}>
                  Shop
                </p>
              </li>
              {management && (
                <li className="mb-6" key={uuidv4()}>
                  <p className="inline-block text-base font-medium text-white" onClick={() => navigate("/admin")}>
                    Admin
                  </p>
                </li>
              )}
            </ul>
            <div className="w-full">
              <button
                className="relative inline-flex items-center justify-center w-full h-12 mb-4 font-semibold border-b border-white group"
                onClick={() => navigate("/profile")}
              >
                <span className="text-white">Profile</span>
              </button>
              <button
                className="relative inline-flex items-center justify-center w-full h-12 mb-4 font-semibold bg-white border border-black rounded-lg group"
                onClick={() => handleLogout()}
              >
                <span className="text-black">Logout</span>
              </button>
              <p className="text-xs text-center">All rights reserved © Copyright 2021 - {dayjs().format("YYYY")} | Coracle</p>
            </div>
          </div>
        </nav>
      </div>
      {/* MOBILE SLIDEOUT END */}
      <div className="flex-grow w-full h-full mt-16 lg:mt-20">
        <Outlet context={[setCartOpen]} />
      </div>
      {renderCart()}
    </section>
  );
};

// const mapStateToProps = (state) => ({
//   authState: {
//     isAuth: state.isAuth,
//     email: state.email,
//     user: state.user,
//     name: state.name,
//     role: state.role,
//     profilePicture: state.profilePicture,
//   },
// });

// const mapDispatchToProps = (dispatch) => ({
//   loginUser: (data) => dispatch({ payload: data, type: "LOGIN" }),
//   logoutUser: (data) => dispatch({ type: "LOGOUT" }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

export default Navbar;
