import { useState } from "react";
import toast from "react-hot-toast";
import { useCart } from "react-use-cart";

const NumberSpinner = ({ min, max, itemData }) => {
  const { addItem, inCart, updateItemQuantity, getItem } = useCart();
  const [value, setValue] = useState(itemData.quantity ? itemData.quantity : 0);

  const increment = () => {
    if (inCart(itemData)) {
      updateItemQuantity(itemData.id, value + 1);
    } else {
      addItem(itemData);
    }
  };

  const decrement = () => {
    updateItemQuantity(itemData.id, getItem(itemData.id).quantity - 1);
  };

  return (
    <div className="w-[120px] h-[40px] flex flex-row justify-center items-center gap-1 border border-slate-950">
      <button className="inline-block w-[30px] h-full bg-transparent" onClick={() => decrement()}>
        &minus;
      </button>
      <span className="block h-full w-[40px] rounded-md text-center leading-10 text-xs text-black">{getItem(itemData.id).quantity}</span>
      <button className="inline-block w-[30px] h-full bg-transparent" onClick={() => increment()}>
        &#43;
      </button>
    </div>
  );
};

export default NumberSpinner;
