import { useNavigate } from "react-router-dom";

const PageNotFound = ({ authState, authDispatch }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-screen gap-3 text-white">
      <h1 className="text-3xl font-semibold">Seems like you got lost</h1>
      <p>This page doesn't exist or it was removed.</p>
      <div className="flex flex-row items-center justify-center gap-5">
        <button
          className="px-5 py-2 text-white transition-all duration-150 ease-in-out bg-black border border-black hover:text-black hover:bg-white"
          onClick={() => navigate("/")}
        >
          Home
        </button>
        <button
          className="px-5 py-2 text-black transition-all duration-150 ease-in-out bg-white border border-white hover:border-black hover:text-white hover:bg-black"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
