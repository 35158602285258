import React, { useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MarkdownEditor = ({ onChange, defaultValue }) => {
  const [editorHtml, setEditorHtml] = useState(defaultValue && defaultValue.length > 0 ? defaultValue : "");

  const handleChange = useCallback(
    (html) => {
      setEditorHtml(html);
      onChange(html);
    },
    [setEditorHtml, onChange]
  );

  if (!onChange) {
    throw new Error("onChange prop is required for MarkdownEditor component.");
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "align"];
  return (
    <div className="flex flex-col w-full p-4 space-y-2rounded-lg">
      <ReactQuill
        theme="snow"
        onChange={(c) => handleChange(c)}
        value={editorHtml}
        modules={modules}
        formats={formats}
        placeholder="Write something awesome..."
      />
    </div>
  );
};

export default MarkdownEditor;
