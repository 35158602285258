const ManagementHome = ({ authState, authDispatch, data }) => {
  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full h-full min-h-screen overflow-y-auto text-white bg-gray-950">
      <p className="text-xl font-semibold">
        Management Home - UNDER CONSTRUCTION
      </p>
    </div>
  );
};

export default ManagementHome;
